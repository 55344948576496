import { User } from 'src/app/models/user.js';
import { Component, OnInit } from '@angular/core';
import sidebarMenu from '../../../assets/data/sidebarMenu/sidebarMenu.json';
import { NbMenuItem } from '@nebular/theme';
import sidebarMenuUserAdminContent from '../../../assets/data/sidebarMenu/sidebarMenuUserAdminContent.json';
import sidebarMenuUserTeacher from '../../../assets/data/sidebarMenu/sidebarMenuUserTeacher.json';
import sidebarMenuUserStudent from '../../../assets/data/sidebarMenu/sidebarMenuUserStudent.json';
import sidebarMenuUserTeacherAssistent from '../../../assets/data/sidebarMenu/sidebarMenuUserTeacherAssistent.json';
import sidebarMenuUserAdministrator from '../../../assets/data/sidebarMenu/sidebarMenuUserAdministrator.json';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menu: NbMenuItem[];
  role;

  constructor() {
    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    this.role = user.userRole ? user.userRole : 'user';
    switch (this.role) {
      case 'administrator':
        this.menu = sidebarMenuUserAdministrator;
        break;
      case 'admin_content':
        this.menu = sidebarMenuUserAdminContent;
        break;
      case 'teacher':
        this.menu = sidebarMenuUserTeacher;
        break;
      case 'teacher_assistent':
        this.menu = sidebarMenuUserTeacherAssistent;
        break;
      case 'student':
        this.menu = sidebarMenuUserStudent;
        break;
      default:
        this.menu = sidebarMenu;
        break;
    }
  }

  ngOnInit() {
  }

}
