import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterUserAssign'
})
export class FilterUserAssignPipe implements PipeTransform {

    transform(list: any, arg: any): any {
        if (arg === '' || arg.length < 3) { return list; }
        const result = [];
        for (const value of list) {
            if (value.userEmail.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
                result.push(value);
            } else if (value.userName.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
                result.push(value);
            }
        }
        return result;
    }
}
