import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';

const RecordRTC = require('recordrtc/RecordRTC.min');

@Component({
  selector: 'app-webcam-video-recorder',
  templateUrl: './webcam-video-recorder.component.html',
  styleUrls: ['./webcam-video-recorder.component.scss']
})
export class WebcamVideoRecorderComponent implements OnInit {
  recordRTC: any;
  isStreaming = false;
  @Input() cameraCode;
  @Input() delay;
  @Output() videoRecorder = new EventEmitter<any>();

  @ViewChild('video', { static: false }) videoPlayer: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  startRecording() {
    this.isStreaming = true;
    const mediaConstraints = {
      video: {
        deviceId: {
          exact: this.cameraCode
        }
      },
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(stream: MediaStream) {
    const options = {
      disableLogs: false,
      mimeType: 'video/webm;codecs=opus',
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      bitsPerSecond: 128000,
    };
    this.recordRTC = RecordRTC(stream, options);
    this.recordRTC.startRecording();
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.srcObject = stream;
    this.toggleControls();
    this.recordRTC.stream = stream;
    setTimeout(() => {
      this.stopRecording();
    }, this.delay);
  }

  errorCallback(err) {
    console.error(err);
  }

  stopRecording() {
    this.isStreaming = true;
    const recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
  }

  processVideo(audioVideoWebMURL) {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    this.toggleControls();
    video.srcObject = null;
    video.src = video.srcObject = null;
    video.src = audioVideoWebMURL;
    const recordRTC = this.recordRTC;
    const recordedBlob = recordRTC.getBlob();
    if (this.recordRTC.stream != null) {
      this.recordRTC.stream.getTracks().map(function (val) {
        val.stop();
      });
    }
    if (recordedBlob.size) {
      this.videoRecorder.emit(recordedBlob);
    }
  }

  toggleControls() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.muted = !video.muted;
    video.controls = !video.controls;
    video.autoplay = !video.autoplay;
  }

}
