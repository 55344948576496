import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exam-post-viewer',
  templateUrl: './exam-post-viewer.component.html',
  styleUrls: ['./exam-post-viewer.component.scss']
})
export class ExamPostViewerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
