import { Component, OnInit } from '@angular/core';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-exam-import',
  templateUrl: './exam-import.component.html',
  styleUrls: ['./exam-import.component.scss']
})
export class ExamImportComponent implements OnInit {
  file: File;
  loading;


  constructor(
    private toastrService: NbToastrService,
  ) { }

  ngOnInit() {
  }

  uploadFile(event) {
    const file = event[0] as File;
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.file = event[0];
    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show('', 'archivo no soportado', { position, status });
    }

  }

  verifyFile() {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.file;
    reader.onload = (event) => {
      const data = reader.result;
      workBook = xlsx.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = xlsx.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);
    }
    reader.readAsBinaryString(file);
  }

  deleteAttachment() {
    this.file = undefined;
  }

}
