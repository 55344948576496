import { Component, OnInit, Inject } from '@angular/core';
import {
  NbSidebarService,
  NbThemeService, NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition, NbMenuService, NB_WINDOW,
} from '@nebular/theme';
import { LayoutService } from '../utils/layout.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userAvatar: any;
  userEmail;
  userPicture: any;
  loading: boolean;
  urlAlt = 'Myappsoftware';
  urlLog = 'assets/images/mLogo.png';

  userMenu = [
    { title: 'Mi Perfil', icon: 'person-outline', },
    { title: 'Salir', icon: 'log-out-outline' }
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private themeService: NbThemeService,
    private authService: AuthService,
    private toastrService: NbToastrService,
    private router: Router,
    private companyService: CompanyService,
    private userService: UserService,
    private nbMenuService: NbMenuService,
    @Inject(NB_WINDOW) private window
  ) { }

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  async ngOnInit() {
    await this.startValidation();
    await this.setBackground();
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'userMenu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        // this.window.alert(`${title} was clicked!`);
        switch (title) {
          case 'Salir':
            this.logOut();
            break;
          case 'Mi Perfil':
            this.navigateUserProfile();
            break;
          default:
            break;
        }
      });
  }

  setBackground() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (user.company.companyCode !== undefined) {
      this.companyService.oneCompanyBackend(user.company.companyCode).then(
        (result) => {
          this.urlLog = result.body.companyLogo === undefined ? 'assets/images/mLogo.png' : result.body.companyLogo;
          this.urlLog = result.body.companyLogo === null ? 'assets/images/mLogo.png' : result.body.companyLogo;
        }).catch(
          (err) => {
            this.urlLog = 'assets/images/mLogo.png';
          }
        ).finally(
          () => {
          }
        );
    }
  }

  startValidation() {
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    } else {
      this.logOut();
    }
    if (user) {
      this.userAvatar = user.name;
      this.userPicture = user.picture;
      this.userEmail = user.email;
      this.currentTheme = user.theme ? user.theme : 'default';
      this.changeTheme(this.currentTheme);
      if (user !== undefined) {
        this.userService.oneUserExamBackend(user.userId)
          .then(
            (result) => {
              const userExam = result.body;
              localStorage.setItem('userExam', JSON.stringify(userExam));
              this.loading = false;
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
      }
      this.authService.userProfileChanged.subscribe(change => {
        if (localStorage.getItem('user') !== null) {
          user = JSON.parse(localStorage.getItem('user'));
        }
        if (user) {
          this.userAvatar = user.name;
          this.userPicture = user.picture;
        }
      });
    }
  }

  navigateHome() {
    this.router.navigate(['']);
  }

  navigateUserProfile() {
    this.router.navigate(['userProfile']);
  }

  logOut() {
    this.authService.logout();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'left');
    this.layoutService.changeLayoutSize();

    return false;
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

}
