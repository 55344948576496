import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exam-assign',
  templateUrl: './exam-assign.component.html',
  styleUrls: ['./exam-assign.component.scss']
})
export class ExamAssignComponent implements OnInit {
  examList;
  loading;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  examListReceiver($event) {
    this.examList = $event;
  }

  assignExam(event) {
    const examCode = event.data.examCode;
    this.router.navigate(['/examAssignation/' + examCode]);
  }

}
