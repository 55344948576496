import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import roleListJson from '../../../../assets/data/userRole/userRole.json';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-report-users',
  templateUrl: './report-users.component.html',
  styleUrls: ['./report-users.component.scss']
})
export class ReportUsersComponent implements OnInit {
  loading: boolean;
  dataSourse = new Array<any>();
  reportName = 'reporte de usuarios';
  counterStorage = 0;
  counterAdminContent = 0;
  counterTeacher = 0;
  counterTeacherAssistent = 0;
  counterStudent = 0;

  constructor(
    private userService: UserService,
    private toastrService: NbToastrService,
  ) { }

  async ngOnInit() {
    const users = await this.getAllUsers();
    await this.createDataSource(users);
  }

  getAllUsers() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    return this.userService.allUserCognito().then(
      (result) => {
        const userCognito = result.Users;
        const userList = new Array<any>();
        userCognito.forEach(user => {
          const userId = user.Username;
          const email = user.Attributes.find((x) => x.Name === 'email');
          const name = user.Attributes.find((x) => x.Name === 'name');
          const role = user.Attributes.find((x) => x.Name === 'custom:role');
          const group = user.Attributes.find((x) => x.Name === 'custom:group');
          const company = user.Attributes.find((x) => x.Name === 'custom:company');
          const faceid = user.Attributes.find((x) => x.Name === 'custom:faceId');
          const status = user.Enabled;
          const statusVerification = user.UserStatus === 'CONFIRMED' ? true : false;
          const statusShow = status ? 'Activo' : 'Inactivo';
          const faceIdShow = faceid !== undefined ? 'Si' : 'No';
          const statusVerificationShow = statusVerification ? 'Si' :
            user.UserStatus === 'EXTERNAL_PROVIDER' ? 'Si / Google' : 'No';
          const roleShow = roleListJson.find((x) => x.value === role.Value);
          let groupShow;
          if (group === undefined) { groupShow = 'Sin Grupo'; } else { groupShow = group.Value; }
          if (role.Value !== 'administrator' && company.Value === companyCode) {
            switch (role.Value) {
              case 'student':
                this.counterStudent++;
                break;
              case 'admin_content':
                this.counterAdminContent++;
              case 'teacher':
                this.counterTeacher++;
                break;
              case 'teacher_assistent':
                this.counterTeacherAssistent++;
                break;
            }
            userList.push({
              userId,
              userEmail: email.Value,
              userName: name.Value,
              userStatus: status,
              userfaceIdShow: faceIdShow,
              userStatusShow: statusShow,
              userGroupShow: groupShow,
              userRoleShow: roleShow.type,
              statusVerificationShow,
            });
          }
        });
        return userList;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  createDataSource(users) {
    users.forEach(user => {
      this.dataSourse.push({
        Usuario: user.userName,
        Correo: user.userEmail,
        Rol: user.userRoleShow,
        Estado: user.userStatusShow,
        Confirmado: user.statusVerificationShow,
        FaceId: user.userfaceIdShow,
        Grupo: user.userGroupShow,
      });
    });
  }

}
