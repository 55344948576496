import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import roleListJson from '../../../../assets/data/userRole/userRole.json';
import { NbToastrService, NbDialogService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';

@Component({
  selector: 'app-user-maintenance-fields',
  templateUrl: './user-maintenance-fields.component.html',
  styleUrls: ['./user-maintenance-fields.component.scss']
})
export class UserMaintenanceFieldsComponent implements OnInit {
  loading = false;
  user = {} as User;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      const userCode = params.params.userCode;
      if (userCode !== undefined) {
        this.getUser(userCode);
      }
    });
  }

  getUser(userCode) {
    this.loading = true;
    this.userService.oneUserCognito(userCode).then(
      (result: any) => {
        const userCognito = result;
        const userId = userCognito.Username;
        const email = userCognito.UserAttributes.find((x) => x.Name === 'email');
        const name = userCognito.UserAttributes.find((x) => x.Name === 'name');
        const picture = userCognito.UserAttributes.find((x) => x.Name === 'picture');
        const role = userCognito.UserAttributes.find((x) => x.Name === 'custom:role');
        const group = userCognito.UserAttributes.find((x) => x.Name === 'custom:group');
        const company = userCognito.UserAttributes.find((x) => x.Name === 'custom:company');
        const theme = userCognito.UserAttributes.find((x) => x.Name === 'custom:theme');
        const status = userCognito.Enabled;
        // if (role.Value === 'administrator') { roleListJson.push({ type: 'Administrador', value: 'administrator' }); }
        // const roleShow = roleListJson.find((x) => x.value === role.Value);
        let groupShow;
        if (group === undefined) { groupShow = 'Sin Grupo'; } else { groupShow = group.Value; }
        this.user = {
          userId,
          name: name.Value,
          email: email.Value,
          group: groupShow,
          userRole: role.Value,
          company: company.Value,
          password: undefined,
          picture: picture.Value,
          theme: theme.Value,
          userRekognitionImage: undefined,
          userStatus: status,
          username: userId,
        };
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show('', err, { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );

  }

}
