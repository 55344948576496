import { SimpleDateComponent } from "../../simple-date/simple-date.component";

export const examTableSettings = {
  hideSubHeader: false,
    mode: 'external',
    actions: {
      edit: true,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='fa fa-trash-o' title='Eliminar'></i>"
    },
    columns: {
      examName: {
        title: "Nombre de examen",
        type: "text",
        width: "35%"
      },
      examQuestionCounterShow: {
        title: "Preguntas",
        type: "text",
        class: "text-justify"
      },
      examQuestionCounterOneShow: {
        title: "Opción multiple con una respuesta",
        type: "text"
      },
      examQuestionCounterOneBShow: {
        title: "Opción Falso y Verdadero",
        type: "text"
      },
      examQuestionCounterMultipleShow: {
        title: "Opción múltiple",
        type: "text"
      },
      examQuestionCounterDevelopShow: {
        title: "Desarrollo de temas",
        type: "text"
      },
      examQuestionCounterCasesShow: {
        title: "Casos",
        type: "text"
      },
      examQuestionTimeShow: {
        title: "Duración en minutos",
        type: "text",
        width: "10%"
      },
      examCreationDateShow: {
        title: "Fecha de creación",
        type: "custom",
        renderComponent: SimpleDateComponent,
        width: "10%"
      },
      examStatusShow: {
        title: "Estado",
        type: "text"
      }
    },
    pager: {
      perPage: 25
    },
    attr: {
      class: "table table-bordered"
    },
    noDataMessage: "No hay exámenes."
};
