import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, EventEmitter } from '@angular/core';
import { Exam } from 'src/app/models/exam';
import { ExamQuestion } from 'src/app/models/examQuestion';
import { v4 as uuidv4 } from 'uuid';
import { ExamQuestionAnswer } from 'src/app/models/examQuestionAnswer';
import examQuestionAnswerTableSettings from '../../../../assets/config/examTable/examQuestionAnswerTableSettings.json';
import { NbDialogRef, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { ExamService } from 'src/app/services/exam.service';
import { User } from 'src/app/models/user';
import { ExamQuestionMedia } from 'src/app/models/examQuestionMedia';
import { Output } from '@angular/core';


@Component({
  selector: 'app-exam-maintenance-field-question',
  templateUrl: './exam-maintenance-field-question.component.html',
  styleUrls: ['./exam-maintenance-field-question.component.scss']
})
export class ExamMaintenanceFieldQuestionComponent implements OnInit, OnChanges {

  @Input() exam = {} as Exam;
  @Input() questionCode;
  @Output() closeExamQuestion = new EventEmitter<any>();
  @Output() changeActiveTab = new EventEmitter<number>();

  question = {} as ExamQuestion;
  questionIndex;

  answer = {} as ExamQuestionAnswer;
  answerIndex;

  loading = false;
  statusAlert = 'danger';
  messageAlert = '';
  settingsExamQuestionAnswer = examQuestionAnswerTableSettings;
  examQuestionAnswerList = new LocalDataSource();

  file: File;
  fileAnswer: File;

  @ViewChild('imagePreview', { static: false }) imagePreview: ElementRef;
  @ViewChild('imagePreviewAnswer', { static: false }) imagePreviewAnswer: ElementRef;

  configTiny = {
    height: 350,
    min_height: 100,
    max_height: 500,
    plugins: "table",
    branding: false,
    menubar: 'edit format table',
    menu: {
      edit: {
        title: "Editar",
        items: "undo redo | selectall"
      },
      format: {
        title: "Formato",
        items:
          "bold italic underline strikethrough superscript subscript | formats | removeformat"
      },
      table: {
        title: "Tabla",
        items: "inserttable tableprops deletetable | cell row column"
      }
    },
    toolbar1:
      "undo redo | fontselect fontsizeselect bold italic underline | alignleft aligncenter alignright | table",
  };

  constructor(
    private examService: ExamService,
    private toastrService: NbToastrService,
    // private dialogRef: NbDialogRef<ExamMaintenanceFieldQuestionComponent>,
  ) { }

  ngOnInit() {
    if (this.questionCode !== undefined) {
      const question = this.exam.examQuestion.find((x) => x.examQuestionCode === this.questionCode);
      if (question !== undefined) {
        const index = this.exam.examQuestion.indexOf(question);
        this.question = question;
        this.questionIndex = index;
        this.examQuestionAnswerList = new LocalDataSource(this.question.examQuestionAnswer);
        console.log(this.examQuestionAnswerList);
      }
    }
  }

  ngOnChanges() {
    if (this.questionCode !== undefined) {
      const question = this.exam.examQuestion.find((x) => x.examQuestionCode === this.questionCode);
      if (question !== undefined) {
        const index = this.exam.examQuestion.indexOf(question);
        this.question = question;
        this.questionIndex = index;
        this.examQuestionAnswerList = new LocalDataSource(this.question.examQuestionAnswer);
      }
    }
  }

  cancelExamQuestion() {
    if (this.questionIndex !== undefined) {
      this.closeExamQuestion.emit(this.questionIndex);
    } else {
      this.closeExamQuestion.emit(-1);
    }
    this.changeActiveTab.emit(1);
  }

  addExamQuestionAnswer() {
    this.answer = {} as ExamQuestionAnswer;
    this.answer.examQuestionAnswerCode = uuidv4();
    this.answer.examQuestionAnswerResponseIsCorrect = true;
  }

  closeExamQuestionAnswer() {
    this.answer = {} as ExamQuestionAnswer;
    this.messageAlert = '';
  }

  validateExamQuestionAnswer(): boolean {
    this.messageAlert = '';
    if (this.answer.examQuestionAnswerResponse === undefined) {
      this.messageAlert = 'La respuesta no debe estar vacía';
      return false;
    }
    if (this.answer.examQuestionAnswerResponse === '') {
      this.messageAlert = 'La respuesta no debe estar vacia';
      return false;
    }
    if (this.answer.examQuestionAnswerResponse.length <= 0) {
      this.messageAlert = 'La respuesta no debe estar vaciá';
      return false;
    }
    if (this.question.examQuestionType === 'oneAnswer' || this.question.examQuestionType === 'oneAnswerB') {
      let countCorrectAnswer = 0;
      this.question.examQuestionAnswer.forEach(question => {
        if (question.examQuestionAnswerResponseIsCorrect === true) {
          const answer = this.question.examQuestionAnswer.find((x) => x.examQuestionAnswerCode === this.answer.examQuestionAnswerCode);
          if (answer === undefined) {
            countCorrectAnswer++;
          }
        }
      });
      if (this.answer.examQuestionAnswerResponseIsCorrect === true) {
        if (countCorrectAnswer >= 1) {
          this.messageAlert = 'Solo puede haber una respuesta correcta para esta categoría';
          return false;
        }
      }
    }
    if (this.question.examQuestionAnswer !== undefined) {
      this.question.examQuestionAnswer.forEach(answer => {
        if (String(answer.examQuestionAnswerResponse).trim().toLowerCase() === String(this.answer.examQuestionAnswerResponse).trim().toLowerCase() &&
          answer.examQuestionAnswerCode !== this.answer.examQuestionAnswerCode) {
          this.messageAlert = 'Respuesta duplicada';
        }
      });
      if (this.messageAlert !== '') {
        return false;
      }

    }
    this.messageAlert = '';
    return true;
  }

  async saveExamQuestionAnswer() {
    let user: User;
    if (localStorage.getItem('user') !== undefined) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    if (this.question.examQuestionAnswer === undefined) {
      this.question.examQuestionAnswer = new Array<ExamQuestionAnswer>();
    }
    if (this.question.examQuestionAnswerCounter === undefined) {
      this.question.examQuestionAnswerCounter = 0;
    }
    if (await this.validateExamQuestionAnswer() === true) {
      this.answer.examQuestionAnswerIsCorrectResponseShow =
        this.answer.examQuestionAnswerResponseIsCorrect ? 'Correcta' : 'Incorrecta';
      const answer = this.question.examQuestionAnswer.find((x) => x.examQuestionAnswerCode === this.answer.examQuestionAnswerCode);
      const prefix =
        'company/' +
        user.company.companyCode +
        '/exam/' +
        this.exam.examCode +
        '/question/' +
        this.question.examQuestionCode +
        '/answer/' +
        this.answer.examQuestionAnswerCode;
      if (answer !== undefined) {
        this.answer.examQuestionAnswerModifyDate = new Date();
        this.answer.examQuestionAnswerModifyUser = user.userId;
        if (this.fileAnswer !== undefined) {
          this.answer.examQuestionAnswerMedia = {
            examQuestionAnswerMediaCode: uuidv4(),
            examQuestionAnswerMediaType: this.fileAnswer.type,
            examQuestionAnswerMediaUrl: await this.uploadToS3(this.fileAnswer, prefix),
            examQuestionAnswerMediaFile: this.fileAnswer,
            examQuestionAnswerMediaKey: prefix + '/' + this.fileAnswer.name,
          };
        }
        const answerIndex = this.question.examQuestionAnswer.indexOf(answer);
        this.question.examQuestionAnswer[answerIndex] = this.answer;
      } else {

        this.answer.examQuestionAnswerModifyDate = new Date();
        this.answer.examQuestionAnswerModifyUser = user.userId;
        this.answer.examQuestionAnswerCreationDate = new Date();
        this.answer.examQuestionAnswerCreationUser = user.userId;
        if (this.fileAnswer !== undefined) {
          this.answer.examQuestionAnswerMedia = {
            examQuestionAnswerMediaCode: uuidv4(),
            examQuestionAnswerMediaType: this.fileAnswer.type,
            examQuestionAnswerMediaUrl: await this.uploadToS3(this.fileAnswer, prefix),
            examQuestionAnswerMediaFile: this.fileAnswer,
            examQuestionAnswerMediaKey: prefix + '/' + this.fileAnswer.name,
          };
        }
        this.question.examQuestionAnswer.push(this.answer);
        this.question.examQuestionAnswerCounter = this.question.examQuestionAnswerCounter + 1;
      }
      this.answer = {} as ExamQuestionAnswer;
      this.fileAnswer = undefined;
    }
    this.examQuestionAnswerList = new LocalDataSource(this.question.examQuestionAnswer);
  }

  deleteExamQuestionAnswer(event) {
    const answerCode = event.data.examQuestionAnswerCode;
    const answer = this.question.examQuestionAnswer.find((x) => x.examQuestionAnswerCode === answerCode);
    const answerIndex = this.question.examQuestionAnswer.indexOf(answer);
    this.question.examQuestionAnswer.splice(answerIndex, 1);
    this.question.examQuestionAnswerCounter = this.question.examQuestionAnswerCounter - 1;
    this.examQuestionAnswerList = new LocalDataSource(this.question.examQuestionAnswer);
  }

  editExamQuestionAnswer(event) {
    const answerCode = event.data.examQuestionAnswerCode;
    const answer = this.question.examQuestionAnswer.find((x) => x.examQuestionAnswerCode === answerCode);
    const answerIndex = this.question.examQuestionAnswer.indexOf(answer);
    this.answer = this.question.examQuestionAnswer[answerIndex];
    this.examQuestionAnswerList = new LocalDataSource(this.question.examQuestionAnswer);
  }

  validateExamQuestion(): boolean {
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    let message;
    if (this.question.examQuestion === undefined) {
      message = 'La pregunta no puede ser vacía';
    }
    if (this.question.examQuestion === '') {
      message = 'La pregunta no puede ser vacía';
    }
    if (this.question.examQuestionType === undefined) {
      message = 'Debe seleccionar una categoría de pregunta';
    }
    if (this.question.examQuestionType === 'oneAnswer' || this.question.examQuestionType === 'oneAnswerB') {
      let countCorrectAnswer = 0;
      if (this.question.examQuestionAnswer !== undefined) {
        this.question.examQuestionAnswer.forEach(question => {
          if (question.examQuestionAnswerResponseIsCorrect === true) {
            const answer = this.question.examQuestionAnswer.find((x) => x.examQuestionAnswerCode === this.answer.examQuestionAnswerCode);
            if (answer === undefined) {
              countCorrectAnswer++;
            }
          }
        });
      }
      if (this.question.examQuestionAnswerCounter < 2) {
        message = 'Para esta categoría debe tener por lo menos 2 respuestas';
      }
      if (countCorrectAnswer <= 0) {
        message = 'Para esta categoría debe tener una respuesta correcta';
      }
      if (countCorrectAnswer > 1) {
        message = 'Para esta categoría no puedes tener mas de una respuesta correcta';
      }
    }
    if (this.question.examQuestionType === 'multipleAnswer') {
      let countCorrectAnswer = 0;
      if (this.question.examQuestionAnswer !== undefined) {
        this.question.examQuestionAnswer.forEach(question => {
          if (question.examQuestionAnswerResponseIsCorrect === true) {
            const answer = this.question.examQuestionAnswer.find((x) => x.examQuestionAnswerCode === this.answer.examQuestionAnswerCode);
            if (answer === undefined) {
              countCorrectAnswer++;
            }
          }
        });
      }
      if (this.question.examQuestionAnswerCounter < 2) {
        message = 'Para esta categoría debe tener por lo menos 2 respuestas';
      }
      if (countCorrectAnswer < 2) {
        message = 'Para esta categoría debe tener dos respuestas correctas';
      }
    }
    if (message !== undefined) { this.toastrService.show('', message, { position, status }); return false; }
    return true;
  }

  updateExam() {
    console.log(this.exam);
    this.examService.updateExamBackend(this.exam)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Actualizado', { position, status });
          // this.dialogRef.close(this.exam);
          // console.log(this.exam);
          // console.log(this.question);
          // console.log(this.questionIndex);
          if (this.questionIndex !== undefined) {
              this.closeExamQuestion.emit(this.questionIndex);
          } else {
            this.closeExamQuestion.emit(-1);
          }
        })
      .catch(
        (err: any) => {
          console.log(err);
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        })
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  async saveExamQuestion() {
    this.loading = true;
    let user: User;
    if (localStorage.getItem('user') !== undefined) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    if (this.exam.examQuestion === undefined) {
      this.exam.examQuestion = new Array<ExamQuestion>();
    }
    if (await this.validateExamQuestion() === true) {
      if (this.question.examQuestionCode === undefined) {
        this.question.examQuestionCode = uuidv4();
        this.question.examQuestionCreationDate = new Date();
        this.question.examQuestionModifyDate = new Date();
        this.question.examQuestionCreationUser = user.userId;
        this.question.examQuestionModifyUser = user.userId;
        this.exam.examQuestion.push(this.question);
      } else {
        this.question.examQuestionModifyDate = new Date();
        this.question.examQuestionModifyUser = user.userId;
        this.exam.examQuestion[this.questionIndex] = this.question;
      }
      if (this.file !== undefined) {
        const user = JSON.parse(localStorage.getItem('user'));
        const prefix =
          'company/' +
          user.company.companyCode +
          '/exam/' +
          this.exam.examCode +
          '/question/' +
          this.question.examQuestionCode
        '';
        if (this.question.examQuestionMedia !== undefined) {
          await this.examService.deleteObject(this.question.examQuestionMedia.examQuestionMediaKey);
        }
        this.question.examQuestionMedia = {
          examQuestionMediaCode: uuidv4(),
          examQuestionMediaType: this.file.type,
          examQuestionMediaUrl: await this.uploadToS3(this.file, prefix),
          examQuestionMediaFile: this.file,
          examQuestionMediaKey: prefix + '/' + this.file.name,
        };
      }


      await this.updateExam();
    } else {
      this.loading = false;
    }
    this.changeActiveTab.emit(1);
  }

  uploadToS3(file: File, prefix) {
    this.loading = true;

    return this.examService.uploadFileS3(file, prefix).then(
      (result) => {
        return result.Location;
      }
    ).catch(
      (err: any) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show('', err, { position, status });
      }).finally(
        () => {
          this.loading = false;
        }
      );
  }

  uploadFile(event) {
    const file = event[0] as File;
    if (
      file.type === 'image/png' ||
      file.type === 'image/gif' ||
      file.type === 'image/x-png' ||
      file.type === 'image/jpeg'
    ) {
      this.file = event[0];
      this.verifyFile();
    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show('', 'Archivo no soportado', { position, status });
    }

  }

  uploadFileAnswer(event) {
    const file = event[0] as File;
    if (
      file.type === 'image/png' ||
      file.type === 'image/gif' ||
      file.type === 'image/x-png' ||
      file.type === 'image/jpeg'
    ) {
      this.fileAnswer = event[0];
      this.verifyFileAnswer();
    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show('', 'Archivo no soportado', { position, status });
    }

  }

  verifyFile() {
    const reader = new FileReader();
    const file = this.file;
    reader.onload = (event) => {
      const img = reader.result;
      this.imagePreview.nativeElement.src = img;
    }
    reader.readAsDataURL(file);
  }

  verifyFileAnswer() {
    const reader = new FileReader();
    const file = this.fileAnswer;
    reader.onload = (event) => {
      const img = reader.result;
      this.imagePreviewAnswer.nativeElement.src = img;
    }
    reader.readAsDataURL(file);
  }

  deleteAttachment() {
    this.file = undefined;
  }

  deleteAttachmentAnswer() {
    this.fileAnswer = undefined;
  }

}
