import { Component, OnInit, Input } from '@angular/core';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { NbToastrService, NbDialogRef, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';

@Component({
  selector: 'app-company-groups',
  templateUrl: './company-groups.component.html',
  styleUrls: ['./company-groups.component.scss']
})
export class CompanyGroupsComponent implements OnInit {
  @Input() company = {} as Company;
  @Input() group = '';
  @Input() edit;
  @Input() index;
  enableEdit;

  loading: boolean;

  constructor(
    private companyService: CompanyService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<CompanyGroupsComponent>
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(this.company);
  }

  save() {
    this.getCombosValues();
    if ((this.index !== undefined) && (this.edit === true)) {
      this.company.companyGroups.splice(this.index, 1);
    }
    this.updateCompany();

  }

  getCombosValues() {
    if (this.company.companyGroups === undefined) {
      this.company.companyGroups = new Array<string>();
    }
    this.company.companyGroups.push(this.group);
  }

  updateCompany() {
    this.loading = true;
    this.companyService.updateCompanyBackend(this.company)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.dialogRef.close(this.company);
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }

}
