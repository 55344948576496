import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import { KinesisVideo, KinesisVideoSignalingChannels } from 'aws-sdk';
import { VideoMaster } from 'src/app/models/videoMaster';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { Subject, Observable } from 'rxjs';

const kinesisVideoClient = new KinesisVideo({
  region: environment.region,
  accessKeyId: environment.accessKeyId,
  secretAccessKey: environment.secretAccessKey,
  correctClockSkew: true,
});

@Component({
  selector: 'app-exam-test-signal-channel',
  templateUrl: './exam-test-signal-channel.component.html',
  styleUrls: ['./exam-test-signal-channel.component.scss']
})
export class ExamTestSignalChannelComponent implements OnInit {
  master = {
    signalingClient: null,
    peerConnectionByClientId: {},
    dataChannelByClientId: {},
    localStream: null,
    remoteStreams: [],
    peerConnectionStatsInterval: null,
    localView: null,
    remoteView: null,
    channelARN: ""
  } as VideoMaster;

  @ViewChild('videoMaster', { static: false }) videoMaster: ElementRef;
  @ViewChild('videoViewer', { static: false }) videoViewer: ElementRef;

  signalingClient;
  peerConnection;

  constructor() { }

  ngOnInit() {
  }

  async startMaster() {
    const localView = this.videoMaster.nativeElement;
    localView.muted = true;
    localView.volume = 0;
    this.master["localView"] = localView;

    const user: User = JSON.parse(localStorage.getItem('user'));
    const channelName = 'mexam-test-channel-' + user.userId;
    const useTrickleICE = false;

    try {
      await kinesisVideoClient.createSignalingChannel({
        ChannelName: channelName,
        ChannelType: "SINGLE_MASTER",
        SingleMasterConfiguration: {
          MessageTtlSeconds: 10
        },
        Tags: [
          {
            Key: "stage",
            Value: environment.bucketName.replace('-content', ''),
          }
        ]
      }).promise();
    }
    catch{
      console.log("[CREATE_SIGNALING_CHANNEL]: Channel already exists");
    }
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    this.master.channelARN = channelARN;
    console.log('[SIGNALING_CHANNEL] Channel ARN: ', channelARN);
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: Role.MASTER,
        },
      })
      .promise();
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      return endpoints;
    }, {});
    console.log('[MASTER] Endpoints: ', endpointsByProtocol);
    this.master.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol["WSS"],
      role: Role.MASTER,
      region: environment.region,
      credentials: {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });
    const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
      region: environment.region,
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      endpoint: endpointsByProtocol["HTTPS"],
      correctClockSkew: true,
    });
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();

    const iceServers = [];
    getIceServerConfigResponse.IceServerList.forEach(iceServer =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      }),
    );
    console.log('[MASTER] ICE servers: ', iceServers);
    const configuration = {
      iceServers
    };

    const constraints = {
      video: true,
      audio: true,
    };
    try {
      this.master.localStream = await navigator.mediaDevices.getUserMedia(constraints);
      localView.srcObject = this.master.localStream;
    } catch (e) {
      console.error('[MASTER] Could not find webcam', e);
    }
    this.master.signalingClient.on('open', async () => {
      console.log('[MASTER] Connected to signaling service');
    });
    this.master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
      console.log('[MASTER] Received SDP offer from client: ' + remoteClientId);

      const peerConnection = new RTCPeerConnection(configuration);
      this.master.peerConnectionByClientId = new Array();
      this.master.peerConnectionByClientId[remoteClientId] = peerConnection;

      const peerConnectionStatsInterval = setInterval(() => peerConnection.getStats().then(), 1000);

      peerConnection.addEventListener('icecandidate', ({ candidate }) => {
        if (candidate) {
          console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId);

          if (useTrickleICE) {
            console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
            this.master.signalingClient.sendIceCandidate(candidate, remoteClientId);
          }
        } else {
          console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);

          if (!useTrickleICE) {
            console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
            this.master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
          }
        }
      });


      this.master.localStream.getTracks().forEach(track => peerConnection.addTrack(track, this.master.localStream));
      await peerConnection.setRemoteDescription(offer);

      console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);
      await peerConnection.setLocalDescription(
        await peerConnection.createAnswer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        }),
      );

      if (useTrickleICE) {
        console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
        this.master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
      }
      console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
    });


    this.master.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
      console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId);

      const peerConnection = this.master.peerConnectionByClientId[remoteClientId];
      peerConnection.addIceCandidate(candidate);
    });

    this.master.signalingClient.on('close', () => {
      console.log('[MASTER] Disconnected from signaling channel');
    });

    this.master.signalingClient.on('error', () => {
      console.error('[MASTER] Signaling client error');
    });

    console.log('[MASTER] Starting master connection');
    this.master.signalingClient.open();

    return endpointsByProtocol;
  }

  async stopMaster() {
    console.log('[MASTER] Stopping master connection');
    if (this.master.signalingClient) {
      this.master.signalingClient.close();
      this.master.signalingClient = null;
    }
    console.log(this.master);
    Object.keys(this.master.peerConnectionByClientId).forEach(clientId => {
      this.master.peerConnectionByClientId[clientId].close();
    });
    this.master.peerConnectionByClientId = [];

    if (this.master.localStream) {
      this.master.localStream.getTracks().forEach(track => track.stop());
      this.master.localStream = null;
    }

    this.master.remoteStreams.forEach(remoteStream => remoteStream.getTracks().forEach(track => track.stop()));
    this.master.remoteStreams = [];

    if (this.master.peerConnectionStatsInterval) {
      clearInterval(this.master.peerConnectionStatsInterval);
      this.master.peerConnectionStatsInterval = null;
    }

    if (this.master.localView) {
      this.master.localView.srcObject = null;
    }

    if (this.master.remoteView) {
      this.master.remoteView.srcObject = null;
    }

    if (this.master.dataChannelByClientId) {
      this.master.dataChannelByClientId = {};
    }

    // Create KVS client
    const kinesisVideoClient = new KinesisVideo({
      region: environment.region,
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      correctClockSkew: true,
    });

    try { //try to create the channell for the user
      await kinesisVideoClient.deleteSignalingChannel({
        ChannelARN: this.master.channelARN,
      }).promise();
    }
    catch{
      console.log("[DELETE_SIGNALING_CHANNEL]: Error on delete operation");
    }


  }

  async startViewer(): Promise<KinesisVideo.ResourceEndpointListItem> {
    const user: User = JSON.parse(localStorage.getItem('user'));
    const channelName = 'mexam-test-channel-' + user.userId;
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;

    var clientId = user.userId;

    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: Role.VIEWER,
        },
      })
      .promise();

    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      console.log(endpoints)
      return endpoints;
    }, {});


    const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
      region: environment.region,
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      endpoint: endpointsByProtocol["HTTPS"],
      correctClockSkew: true,
    });

    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    var iceServers = [
    ];
    getIceServerConfigResponse.IceServerList.forEach(iceServer =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      }),
    );

    iceServers.push({ urls: `stun:stun.kinesisvideo.${environment.region}.amazonaws.com:443` })

    console.log(iceServers);

    this.peerConnection = new RTCPeerConnection({ iceServers });

    this.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol["WSS"],
      clientId,
      role: Role.VIEWER,
      region: environment.region,
      credentials: {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const remoteView = this.videoViewer.nativeElement;

    this.signalingClient.on('open', async () => {
      console.log("open");

      const offer = await this.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });
      await this.peerConnection.setLocalDescription(offer);
      this.signalingClient.sendSdpOffer(this.peerConnection.localDescription);

    });

    this.signalingClient.on('sdpAnswer', async answer => {
      await this.peerConnection.setRemoteDescription(answer);
    });

    this.signalingClient.on('iceCandidate', candidate => {
      this.peerConnection.addIceCandidate(candidate);
    });

    this.signalingClient.on('close', () => {
      console.log("Signaling client close");
    });

    this.signalingClient.on('error', error => {
      console.log("Signaling client error");
    });

    this.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
      if (candidate) {
        this.signalingClient.sendIceCandidate(candidate);
      } else {
      }
    });

    this.peerConnection.addEventListener('track', event => {
      console.log("track event");
      if (remoteView.srcObject) {
        return;
      }
      remoteView.srcObject = event.streams[0];
    });

    this.signalingClient.open();

    return endpointsByProtocol;
  }

  async stopViewer() {
    console.log('[VIEWER] Stopping viewer connection');
    if (this.signalingClient) {
      this.signalingClient.close();
      this.signalingClient = null;
    }

    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }

    if (this.videoViewer.nativeElement) {
      this.videoViewer.nativeElement.srcObject = null;
    }
  }

}
