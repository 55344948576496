import { AuthService } from './../../../services/auth.service';
import { NbDialogRef } from '@nebular/theme';
import { Component, OnInit } from '@angular/core';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';


@Component({
  selector: 'app-auth-resen-valid',
  templateUrl: './auth-resen-valid.component.html',
  styleUrls: ['./auth-resen-valid.component.scss']
})
export class AuthResenValidComponent implements OnInit {
  username: string;
  userStatus: string;

  constructor(
    private dialogRef: NbDialogRef<AuthResenValidComponent>,
    private authService: AuthService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit() {
  }

  onResendValid() {
    const email = this.username;
    this.authService.resendCode(email).then(
      (send) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show('', 'El nuevo correo de validación a sido enviado a ' + email, { position, status });
        this.dialogRef.close();
      }
    ).catch(
      (err) => {
        let message;
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'warning';
        if (err.message === 'User is already confirmed.') {message = 'El usuario ya esta validado no se le puede enviar el código.'; }
        // tslint:disable-next-line:max-line-length
        if (err.message === 'Username/client id combination not found.') {message = 'Correo incorrecto.'; }
        // tslint:disable-next-line:max-line-length
        if (err.message === 'Attempt limit exceeded, please try after some time.') {message = 'Se ha excedido el límite de intentos, intente mas tarde'; }

        if (message !== undefined) {
          this.toastrService.show(
            '',
            message,
            { position, status });
          return false;
        } else {
          return true;
        }
      });
   }

  close() {
    this.dialogRef.close();
  }

}
