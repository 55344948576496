import { UserAutoConfirmComponent } from './pages/auth/user-auto-confirm/user-auto-confirm.component';
import { KeysPipe } from './core/tools/report-viewer/keys.pipe';
import { CompanyGroupsTableComponent } from './pages/company/company-fields/company-groups-table/company-groups-table.component';
import { CompanyGroupsComponent } from './pages/company/company-fields/company-groups/company-groups.component';
import { CompanyImageComponent } from './pages/company/company-fields/company-image/company-image.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { UserConfirmationComponent } from './pages/auth/user-confirmation/user-confirmation.component';
import { UserForgotPasswordViewComponent } from './pages/auth/user-forgot-password-view/user-forgot-password-view.component';
import { UserResetPasswordComponent } from './pages/auth/user-reset-password/user-reset-password.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { LoadingComponent } from './core/loading/loading.component';
import { UserMyProfileComponent } from './pages/auth/user-my-profile/user-my-profile.component';
import { UserMyProfileImageComponent } from './pages/auth/user-my-profile-image/user-my-profile-image.component';
import { ProgressBarComponent } from './core/tools/progress-bar/progress-bar.component';
import { HttpClientModule } from '@angular/common/http';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountdownModule, CountdownGlobalConfig } from 'ngx-countdown';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbButtonModule,
  NbMenuModule,
  NbToastrModule,
  NbSelectModule,
  NbStepperModule,
  NbListModule,
  NbActionsModule,
  NbDialogModule,
  NbProgressBarModule,
  NbTabsetModule,
  NbTooltipModule,
  NbSearchModule,
  NbCheckboxModule,
  NbRadioModule,
  NbUserModule,
  NbToggleModule,
  NbAlertModule,
  NbDatepickerModule,
  NbBadgeModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbWindowModule,
  NbTagModule,
  NbTimepickerModule,
} from '@nebular/theme';
import { NbAuthModule } from '@nebular/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { CompanyComponent } from './pages/company/company.component';
import { CompanyFieldsComponent } from './pages/company/company-fields/company-fields.component';
import { CompanyToolsComponent } from './pages/company/company-tools/company-tools.component';
import { CompanyTableComponent } from './pages/company/company-table/company-table.component';
import { ConfirmWindowComponent } from './core/tools/confirm-window/confirm-window.component';
import { FilterUserAssignPipe } from './pipes/filterUserAssign.pipe';
import { FilterGroupAssignPipe } from './pipes/filterGroupAssignPipe.pipe';
import { SortByPipe } from './pipes/sortByPipe.pipe';
import { ReportViewerComponent } from './core/tools/report-viewer/report-viewer.component';
import { ExamPreViewerComponent } from './pages/exam/exam-pre-viewer/exam-pre-viewer.component';
import { WebcamModule } from 'ngx-webcam';
import { WebcamComponent } from './core/tools/webcam/webcam.component';
import { ExamComponent } from './pages/exam/exam.component';
import { ExamPostViewerComponent } from './pages/exam/exam-post-viewer/exam-post-viewer.component';
import { ExamVerifyComponent } from './pages/exam/exam-verify/exam-verify.component';
import { MyExamComponent } from './pages/exam/my-exam/my-exam.component';
import { ExamAssignComponent } from './pages/exam/exam-assign/exam-assign.component';
import { WebcamVideoRecorderComponent } from './core/tools/webcam-video-recorder/webcam-video-recorder.component';
import { ExamVerificationComponent } from './pages/exam/exam-verify/exam-verification/exam-verification.component';
import { DateTimePickerComponent } from './core/tools/date-time-picker/date-time-picker.component';
import { DatePickerPopoverComponent } from './core/tools/date-time-picker/date-time-popover.component';
import { ExamMaintenanceComponent } from './pages/exam/exam-maintenance/exam-maintenance.component';
import { ExamMaintenanceTableComponent } from './pages/exam/exam-maintenance-table/exam-maintenance-table.component';
import { ExamMaintenanceFieldsComponent } from './pages/exam/exam-maintenance-fields/exam-maintenance-fields.component';
import { AuthResenValidComponent } from './pages/auth/auth-resen-valid/auth-resen-valid.component';
import { ExamMaintenanceToolsComponent } from './pages/exam/exam-maintenance-tools/exam-maintenance-tools.component';
import {
  ExamMaintenanceFieldQuestionComponent
} from './pages/exam/exam-maintenance-field-question/exam-maintenance-field-question.component';
import {
  ExamMaintenanceFieldInfoComponent
} from './pages/exam/exam-maintenance-field-info/exam-maintenance-field-info.component';
import {
  ExamMaintenanceFieldQuestionTableComponent
} from './pages/exam/exam-maintenance-field-question-table/exam-maintenance-field-question-table.component';
import {
  ExamMaintenanceFieldConfigurationComponent
} from './pages/exam/exam-maintenance-field-configuration/exam-maintenance-field-configuration.component';
import { ExamViewerComponent } from './pages/exam/exam-viewer/exam-viewer.component';
import { ExamAssignationComponent } from './pages/exam/exam-assignation/exam-assignation.component';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { UserFaceIdComponent } from './pages/user/user-face-id/user-face-id.component';
import { UserMaintenanceComponent } from './pages/user/user-maintenance/user-maintenance.component';
import { UserMaintenanceTableComponent } from './pages/user/user-maintenance-table/user-maintenance-table.component';
import { UserMaintenanceToolsComponent } from './pages/user/user-maintenance-tools/user-maintenance-tools.component';
import { UserMaintenanceFieldsComponent } from './pages/user/user-maintenance-fields/user-maintenance-fields.component';
import { UserMaintenanceFieldsInfoComponent } from './pages/user/user-maintenance-fields-info/user-maintenance-fields-info.component';
import { ReportUsersComponent } from './pages/reports/report-users/report-users.component';
import { ExamImportComponent } from './pages/exam/exam-import/exam-import.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { ExamMaintenanceFieldQuestionImportComponent } from './pages/exam/exam-maintenance-field-question-import/exam-maintenance-field-question-import.component';
import { ExamMaintenanceFieldQuestionTableImportComponent } from './pages/exam/exam-maintenance-field-question-table-import/exam-maintenance-field-question-table-import.component';
import { ReportUserResultsComponent } from './pages/reports/report-user-results/report-user-results.component';
import { ReportUserResultsViewComponent } from './pages/reports/report-user-results/report-user-results-view/report-user-results-view.component';
import { ExamTestSignalChannelComponent } from './pages/exam/exam-test-signal-channel/exam-test-signal-channel.component';
import { ReportExamChannelListComponent } from './pages/reports/report-exam-channel-list/report-exam-channel-list.component';
import { ReportExamChannelViewerComponent } from './pages/reports/report-exam-channel-list/report-exam-channel-viewer/report-exam-channel-viewer.component';
import { ExamViewerImageComponent } from './pages/exam/exam-viewer-image/exam-viewer-image.component';
import { ExamUnassignComponent } from './pages/exam/exam-unassign/exam-unassign.component';
import { ExamCheckComponent } from './pages/exam/exam-check/exam-check.component';
import { ExamCheckValidationComponent } from './pages/exam/exam-check-validation/exam-check-validation.component';
import { ExamCheckValidationAnswerComponent } from './pages/exam/exam-check-validation-answer/exam-check-validation-answer.component';
import { ReportCheckExamsComponent } from './pages/reports/report-check-exams/report-check-exams.component';
import { TreeviewModule } from 'ngx-treeview';
import { I18n } from './i18n';
import { ExamCloneComponent } from './pages/exam/exam-clone/exam-clone.component';
import { WebcamSelectorComponent } from './core/tools/webcam-selector/webcam-selector.component';
import { UserChangePasswordComponent } from './pages/auth/user-change-password/user-change-password.component';
import { ExamFindsComponent } from './pages/exam/exam-finds/exam-finds.component';
import { NgxTinymceModule } from 'ngx-tinymce';
import { ExamSpreadSheetComponent } from './pages/exam/exam-spread-sheet/exam-spread-sheet.component';
import { MeetComponent } from './pages/meet/meet.component';
import { CustomFilterComponent } from './shared/custom-filter/custom-filter.component';
import { SimpleDateComponent } from './shared/simple-date/simple-date.component';
import { MasterFilterComponent } from './shared/master-filter/master-filter.component';
import { SimpleTimeComponent } from './shared/simple-time/simple-time.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserConfirmationComponent,
    UserForgotPasswordViewComponent,
    UserResetPasswordComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoadingComponent,
    UserMyProfileComponent,
    UserMyProfileImageComponent,
    ProgressBarComponent,
    ExamPreViewerComponent,
    CompanyComponent,
    CompanyFieldsComponent,
    CompanyToolsComponent,
    CompanyTableComponent,
    CompanyGroupsComponent,
    CompanyGroupsTableComponent,
    CompanyImageComponent,
    ConfirmWindowComponent,
    FilterUserAssignPipe,
    FilterGroupAssignPipe,
    SortByPipe,
    ReportViewerComponent,
    KeysPipe,
    WebcamComponent,
    ExamComponent,
    ExamPostViewerComponent,
    ExamVerifyComponent,
    MyExamComponent,
    UserAutoConfirmComponent,
    ExamAssignComponent,
    WebcamVideoRecorderComponent,
    ExamVerificationComponent,
    WebcamVideoRecorderComponent,
    DateTimePickerComponent,
    DatePickerPopoverComponent,
    ExamMaintenanceComponent,
    ExamMaintenanceTableComponent,
    ExamMaintenanceFieldsComponent,
    AuthResenValidComponent,
    ExamMaintenanceToolsComponent,
    ExamMaintenanceFieldQuestionComponent,
    ExamMaintenanceFieldInfoComponent,
    ExamMaintenanceFieldQuestionTableComponent,
    ExamMaintenanceFieldConfigurationComponent,
    ExamViewerComponent,
    ExamAssignationComponent,
    UserFaceIdComponent,
    UserMaintenanceComponent,
    UserMaintenanceTableComponent,
    UserMaintenanceToolsComponent,
    UserMaintenanceFieldsComponent,
    UserMaintenanceFieldsInfoComponent,
    ReportUsersComponent,
    ExamImportComponent,
    DragDropDirective,
    ExamMaintenanceFieldQuestionImportComponent,
    ExamMaintenanceFieldQuestionTableImportComponent,
    ReportUserResultsComponent,
    ReportUserResultsViewComponent,
    ExamTestSignalChannelComponent,
    ReportExamChannelListComponent,
    ReportExamChannelViewerComponent,
    ExamViewerImageComponent,
    ExamUnassignComponent,
    ExamCheckComponent,
    ExamCheckValidationComponent,
    ExamCheckValidationAnswerComponent,
    ReportCheckExamsComponent,
    ExamCloneComponent,
    WebcamSelectorComponent,
    UserChangePasswordComponent,
    ExamFindsComponent,
    ExamSpreadSheetComponent,
    MeetComponent,
    CustomFilterComponent,
    SimpleDateComponent,
    MasterFilterComponent,
    SimpleTimeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbCardModule,
    NbIconModule,
    NbAuthModule,
    NbInputModule,
    FormsModule,
    RouterModule,
    NbButtonModule,
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    NbSelectModule,
    NbStepperModule,
    NbListModule,
    NbActionsModule,
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbProgressBarModule,
    ReactiveFormsModule,
    NbTabsetModule,
    NbTooltipModule,
    NbTagModule,
    Ng2SmartTableModule,
    NbSearchModule,
    NbCheckboxModule,
    NbRadioModule,
    NbUserModule,
    NbTabsetModule,
    NbToggleModule,
    CountdownModule,
    WebcamModule,
    NbAlertModule,
    NbBadgeModule,
    NbPopoverModule,
    NgxMaterialTimepickerModule,
    NbContextMenuModule,
    NbDateFnsDateModule,
    TreeviewModule.forRoot(),
    NgxTinymceModule.forRoot({
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/5.3.2/'
    })
  ],
  providers: [AuthService, AuthGuard, I18n, { provide: Window, useValue: window}],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmWindowComponent,
    UserConfirmationComponent,
    UserForgotPasswordViewComponent,
    UserResetPasswordComponent,
    AuthResenValidComponent,
    UserMyProfileImageComponent,
    CompanyImageComponent,
    CompanyGroupsComponent,
    ExamAssignComponent,
    ExamVerificationComponent,
    DatePickerPopoverComponent,
    ExamMaintenanceFieldQuestionComponent,
    ReportUserResultsViewComponent,
    ReportExamChannelViewerComponent,
    ExamViewerImageComponent,
    ExamCheckValidationAnswerComponent,
    UserChangePasswordComponent,
    MeetComponent,
    CustomFilterComponent,
    SimpleDateComponent
  ],
})
export class AppModule { }
