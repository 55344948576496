import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})
export class UserMaintenanceComponent implements OnInit, OnChanges {
  userList;
  loading;
  refreshListUser;

  constructor(
    private userService: UserService,
    private router: Router,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
  }
  ngOnChanges() {
  }

  userListReceiver(event) {
    this.userList = event;
  }

  editUser(event) {
    const userCode = event.data.userId;
    this.router.navigate(['/userMaintenanceFields/' + userCode]);
  }

  async deleteUser(event) {
    this.dialogService.open(ConfirmWindowComponent,
      {
        context: {
          message: '¿Está seguro de eliminar el usuario?'
        },
        hasBackdrop: false,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose.subscribe(async (result) => {
        if (result === true) {
          const userCode = event.data.userId;
          const userStatus = event.data.userStatus;
          if (userStatus === false) {
            const userLogged = JSON.parse(localStorage.getItem('user'));
            await this.updateUser({
              userCode: userCode,
              deleteStatus: true,
              deleteUser: userLogged,
              deleteDate: new Date(),
            });
            await this.userService.deleteUserCognito(userCode);
            this.refreshListUser = true;
          } else {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              'Usuario debe esta inactivo para eliminarlo',
              { position, status });
          }
        }
      });

  }

  updateUser(user) {
    this.loading = true;
    this.userService.updateUserBackend(user).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show(
          '',
          'Eliminado',
          { position, status });

      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

}
