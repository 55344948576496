import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { StreamingService } from 'src/app/services/streaming.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbWindowService, NbWindowState } from '@nebular/theme';
import { ExamService } from 'src/app/services/exam.service';
import { UserService } from 'src/app/services/user.service';
import { Exam } from 'src/app/models/exam';
import { ExamQuestion } from 'src/app/models/examQuestion';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';
import { environment } from 'src/environments/environment';
import { UserExam } from 'src/app/models/userExam';
import { UserAssign } from 'src/app/models/userAssign';
import { VideoMaster } from 'src/app/models/videoMaster';
const RecordRTC = require('recordrtc/RecordRTC.min');
const uuid = require('uuid');
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import { KinesisVideo, KinesisVideoSignalingChannels } from 'aws-sdk';
import { User } from 'src/app/models/user';
import { Subject, Observable } from 'rxjs';
import { ExamViewerImageComponent } from '../exam-viewer-image/exam-viewer-image.component';
import Spreadsheet, { Options } from "x-data-spreadsheet";
import { MeetComponent } from '../../meet/meet.component';
import { $WebSocket, WebSocketSendMode } from 'angular2-websocket/angular2-websocket'
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const kinesisVideoClient = new KinesisVideo({
  region: environment.region,
  accessKeyId: environment.accessKeyId,
  secretAccessKey: environment.secretAccessKey,
  correctClockSkew: true,
});

@Component({
  selector: 'app-exam-viewer',
  templateUrl: './exam-viewer.component.html',
  styleUrls: ['./exam-viewer.component.scss']
})
export class ExamViewerComponent implements OnInit {
  viewer;
  enableEdit = false;
  streamCode;
  blobArray = new Array();
  stream: MediaStream;
  recordRTC: any;
  reader = new FileReader();
  startTimeStamp;
  stopStream = false;
  isStreaming = false;
  orderStream = 0;
  exam = {} as Exam;
  userExam = {} as Exam;
  userExamResults = {} as Exam;
  questionIndex:number = 0;
  outOfExam = 0;
  timeDataViewOnly = 0;
  config = {
    demand: true,
    format: 'HH: mm: ss'
  };

  master = {
    signalingClient: null,
    peerConnectionByClientId: {},
    dataChannelByClientId: {},
    localStream: null,
    remoteStreams: [],
    peerConnectionStatsInterval: null,
    localView: null,
    remoteView: null,
    channelARN: ""
  } as VideoMaster;
  configTiny = {
    height: 250,
    min_height: 100,
    max_height: 500,
    plugins: "table",
    branding: false,
    menubar: 'edit format table',
    menu: {
      edit: {
        title: "Editar",
        items: "undo redo | cut copy paste pastetext | selectall"
      },
      format: {
        title: "Formato",
        items:
          "bold italic underline strikethrough superscript subscript | formats | removeformat"
      },
      table: {
        title: "Tabla",
        items: "inserttable tableprops deletetable | cell row column"
      }
    },
    toolbar1:
      "undo redo copy paste | fontselect fontsizeselect bold italic underline | alignleft aligncenter alignright | table",
  };

  @ViewChild('cd', { static: false }) countdown;
  @ViewChild('videoMaster', { static: false }) videoMaster: ElementRef;

  loading: boolean;

  //private window: Window;

  elem;
  url;
  intervalWS;
  constructor(
    private streamingService: StreamingService,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private examService: ExamService,
    private router: Router,
    private userService: UserService,
    private dialogService: NbDialogService,
    private windowService: NbWindowService,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(async (params: any) => {
      const examCode = params.params.examCode;
      this.streamCode = params.params.streamCode;
      this.url = this.router.url.split('/')[1];
      if (this.url === 'examViewerTeacher') { //examViewerTeacher
        this.viewer = 'view-only';
        await this.getExam(examCode);
      } else {
        if (examCode !== undefined) {

          const user: any = JSON.parse(localStorage.getItem('user'));
          const userBackend: UserExam = await this.myExamsBackend(user);
          const userAssign: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
          if (userAssign !== undefined) {
            if (userAssign.userExamEnd === undefined) {
              if (userAssign.userExam === undefined) {
                await this.getExam(examCode);
              } else {
                await this.getExam(examCode, userAssign);
              }

            } else {
              this.router.navigate(['/']);
            }
          }
        }
      }
    });
    this.elem = document.documentElement;
    this.openFullscreen();
  }

  // @HostListener('visibilitychange', ['$event'])
  // onVisibilitychange(ev) {
  //   if (this.url !== 'examViewerTeacher') {
  //     if (document.visibilityState === 'hidden') {
  //       this.addDistrac();
  //     }
  //   }
  // }

  @HostListener('mouseleave', ['$event'])
  onMouseleave(ev) {
    if (this.url !== 'examViewerTeacher') {
      if (ev.relatedTarget === null) {
        this.addDistrac();
      } else {
        if (ev.relatedTarget.tagName === 'NB-LAYOUT-COLUMN') {
          this.addDistrac();
        } else if (ev.relatedTarget.tagName === 'DIV') {
          // console.log('Confirmación');
        } else if (ev.relatedTarget.tagName === 'NB-CARD-HEADER') {
          // console.log('Confirmación');
        } else if (ev.relatedTarget.tagName === 'NB-CARD-BODY') {
          // console.log('Confirmación');
        } else if (ev.relatedTarget.tagName === 'NB-DIALOG-CONTAINER') {
          // console.log('Confirmación');
        }
        // console.log(ev);
      }
      // this.addDistrac();
    }
  }
  //dev_sergio
  @HostListener('document:contextmenu', ['$event'])
  onContextmenu(ev) {
    return false;
  }


  @HostListener('document:keyup', ['$event'])
  onKeyupControl(ev) {
    if (ev.keyCode === 44) {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        'El uso de la tecla no está permitido',
        { position, status });
      this.addDistrac();
    }
  }


  @HostListener('document:keydown', ['$event'])
  onKeydownControl(ev) {
    if (ev.altKey || ev.ctrlKey || ev.shiftKey) {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        'El uso de la tecla no está permitido',
        { position, status });
    }
    if (ev.keyCode === 27) {
      return false;
    }
    if (ev.key === 'Escape') {
      ev.preventDefault();
      return false;
    }
    if (ev.key === 'F11') {
      ev.preventDefault();
      return false;
    }
    if (ev.key === 'F5') {
      ev.preventDefault();
      return false;
    }
    ev.preventDefault();
    return false;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullscreenchange(ev) {
    if (window.document.fullscreen === false) {
      this.loading = true;
      this.closeFullscreen()
      if (this.isStreaming === true) {

        this.stopMaster();
        this.stopRecording(false);
      }
      this.router.navigate(['/']);
    }
  }

  addDistrac() {
    this.outOfExam = this.outOfExam + 1;
    const user: any = JSON.parse(localStorage.getItem('user'));
    if (parseInt(this.exam.examCounterNotify) <= this.outOfExam) {
      if (this.exam.examTakeOut === undefined || this.exam.examTakeOut === false) {
        this.examService.notifyExamBackend(
          {
            examName: this.exam.examName,
            studentName: user.name,
            teacherEmail: this.exam.examNotifyEmail,
            outOfExam: this.outOfExam
          });
      } else {
        this.examService.notifyExamBackend(
          {
            examName: this.exam.examName,
            studentName: user.name,
            teacherEmail: this.exam.examNotifyEmail,
            outOfExam: this.outOfExam
          });
        this.loading = true;
        this.closeFullscreen()
        this.stopMaster();
        this.stopRecording(false);
        this.router.navigate(['/']);

      }
    }
  }

  openFullscreen() {
    if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  webSocketEvents(streamCode, examCode) {
    const user: any = JSON.parse(localStorage.getItem('user'));
    var ws = new $WebSocket(
      environment.apiUrlWebSocket +
      '?streamCode=' + this.streamCode +
      '&examCode=' + this.exam.examCode +
      '&userCode=' + user.userId
    );
    ws.onOpen(() => {
      this.intervalWS = setInterval(() => {
        ws.send({}, WebSocketSendMode.Promise).then(
          (T) => {
            // console.log("is send");
          },
          (T) => {
            // console.log("not send");
          }
        );
      }, 300000);
    });
    ws.onMessage(
      (msg: MessageEvent) => {
        // console.log("onMessage ", msg.data);
        if (msg.data === this.streamCode) {
          this.openMeet();
        }
      },
      { autoApply: false }
    );
  }

  async getExam(examCode, reEnter: UserAssign = undefined) {
    this.loading = true;
    this.examService.oneExamBackend(examCode)
      .then(
        async (result) => {
          const lzw = require("node-lzw");
          result.body = JSON.parse(lzw.decode(result.body));
          const examBackend = result.body as Exam;
          if (examBackend !== undefined) {
            this.exam = examBackend;
            console.log('view al obtener examen')
            console.log(this.viewer)
           
            const questionsList = await this.getQuestionsType(examBackend);

            const randomQuestions = await this.getRandomQuestionType(examBackend, questionsList);
            this.userExam.examQuestion = new Array<ExamQuestion>();

            const userExam = await this.createExamRandom(examBackend, questionsList, randomQuestions);

            const userExamWithFlags = await this.setFlags(userExam);

            this.userExam.examQuestion = await this.shuffle(userExamWithFlags);

            localStorage.setItem('examData', JSON.stringify(this.userExam.examQuestion));
            const userExamWithoutAnswers = await this.deleteAnswers(userExam);
            this.userExamResults.examQuestion = userExamWithoutAnswers;
            if (reEnter !== undefined) {
              this.outOfExam = reEnter.userOutOfExam + 1;
              this.userExam.examQuestion = reEnter.userExam;
              localStorage.setItem('examData', JSON.stringify(this.userExam.examQuestion));

              this.userExamResults.examQuestion = reEnter.userExamResults;
            }
            
            this.timeDataViewOnly = this.exam.examTime*60;
            console.log( this.timeDataViewOnly)
            if (this.viewer === undefined || this.viewer == '') {
              await this.setCounterTime();
              console.log('Inicia el contador');

              console.log(this.countdown);
              this.countdown.begin();
              console.log('ya esta iniciado el contador');
            }
            this.activateSpreadSheets();
            this.webSocketEvents(this.streamCode, examCode)
          }

        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
  }

  async activateSpreadSheets() {
    await this.delay(1);
    this.userExam.examQuestion.forEach(question => {
      if (question.examQuestionType === 'themeCasesAnswer' &&
        question.examQuestionTypeControl !== undefined) {
        question.examQuestionXls = new Spreadsheet(
          "#question-" + question.examQuestionCode
        );
        question.examQuestionXls.loadData({});
        question.examQuestionXls.change(data => {
          question.examQuestionAnswerXls = question.examQuestionXls;
        });
      }
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async requestMeet() {
    const user: any = JSON.parse(localStorage.getItem('user'));
    this.examService.notifyMeetExamBackend({
      examName: this.exam.examName,
      studentName: user.name,
      teacherEmail: this.exam.examNotifyEmail,
      streamCode: this.streamCode,
    });
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'success';
    this.toastrService.show(
      '',
      'Se ha solicidado ayuda al catedrático',
      { position, status });
    const userBackend: UserExam = await this.myExamsBackend(user);
    const userAssign: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
    const userAssignIndex = userBackend.userAssign.indexOf(userAssign);
    userAssign.userExamNeedHelp = true;
    userBackend.userAssign[userAssignIndex] = userAssign;
    await this.updateUser(userBackend);
  }

  async openMeet() {
    const user: any = JSON.parse(localStorage.getItem('user'));
    this.windowService.open(MeetComponent,
      {
        title: `Catedratico`,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        hasBackdrop: false,
        windowClass: 'meetWindow',
        context: {
          examCode: this.exam.examCode,
          user: user,
          streamCode: this.streamCode,
        }
      });
    const userBackend: UserExam = await this.myExamsBackend(user);
    const userAssign: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
    const userAssignIndex = userBackend.userAssign.indexOf(userAssign);
    userAssign.userExamNeedHelp = false;
    userBackend.userAssign[userAssignIndex] = userAssign;
    await this.updateUser(userBackend);
  }

  async setCounterTime() {

    if (this.viewer === undefined) {
      console.log('llega a SetCounterTime')
      const user: any = JSON.parse(localStorage.getItem('user'));
      const userBackend: UserExam = await this.myExamsBackend(user);
      const userAssign: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
      const endTime = userAssign.userExamStartDateTime
      const startDate = new Date(userAssign.userExamStartDateTime);
      console.log('startDate:')
      console.log(startDate)
      const endDate = new Date(startDate);
      console.log('endDate:')
      console.log(endDate)
      endDate.setMinutes(endDate.getMinutes() + parseInt(this.exam.examTime, 0));
      console.log('parse exam time')
      console.log(parseInt(this.exam.examTime, 0))

      const result2 = startDate.setMinutes(startDate.getMinutes() + parseInt(this.exam.examTime, 0));
      console.log('result2')
      console.log(result2)
      this.countdown.config.stopTime = endDate;
      this.countdown.restart();
    } else {
      console.log('entra aca')
      console.log(this.exam.examTime)
      const sec = this.exam.examTime *60
      const result = new Date(sec * 1000).toISOString().slice(11, 19);
      console.log(result)
      this.countdown.config.stopTime = result;
      this.countdown.restart();
      
    }
  }

  getQuestionsType(exam: Exam) {
    let oneAnswerList = new Array<ExamQuestion>();
    let oneAnswerBList = new Array<ExamQuestion>();
    let multipleAnswerList = new Array<ExamQuestion>();
    let developAnswerList = new Array<ExamQuestion>();
    let casesAnswerList = new Array<ExamQuestion>();
    exam.examQuestion.forEach(element => {
      switch (element.examQuestionType) {
        case 'multipleAnswer':
          multipleAnswerList.push(element);
          break;
        case 'oneAnswer':
          oneAnswerList.push(element);
          break;
        case 'oneAnswerB':
          oneAnswerBList.push(element);
          break;
        case 'themeDevelopAnswer':
          developAnswerList.push(element);
          break;
        case 'themeCasesAnswer':
          casesAnswerList.push(element);
          break;
      }
    });

    return { oneAnswerList, oneAnswerBList, multipleAnswerList, developAnswerList, casesAnswerList };
  }

  async getRandomQuestionType(exam: Exam, questionsList) {
    const oneUsed = new Array<number>();
    const oneBUsed = new Array<number>();
    const multipleUsed = new Array<number>();
    const developUsed = new Array<number>();
    const casesUsed = new Array<number>();

    // One
    if (exam.examCounterRandomOneAnswer !== undefined) {
      if (exam.examCounterRandomOneAnswer >= 0) {
        for (let index = 0; index < exam.examCounterRandomOneAnswer; index++) {
          const random = await this.getRandomNumber(questionsList.oneAnswerList.length, oneUsed);
          oneUsed.push(random);
        }
      }
    }
    // One B
    if (exam.examCounterRandomOneAnswerB !== undefined) {
      if (exam.examCounterRandomOneAnswerB >= 0) {
        for (let index = 0; index < exam.examCounterRandomOneAnswerB; index++) {
          const random = await this.getRandomNumber(questionsList.oneAnswerBList.length, oneBUsed);
          oneBUsed.push(random);
        }
      }
    }
    // Multiple
    if (exam.examCounterRandomMultipleAnswers !== undefined) {
      if (exam.examCounterRandomMultipleAnswers >= 0) {
        for (let index = 0; index < exam.examCounterRandomMultipleAnswers; index++) {
          const random = await this.getRandomNumber(questionsList.multipleAnswerList.length, multipleUsed);
          multipleUsed.push(random);
        }
      }
    }
    // Develop
    if (exam.examCounterRandomDevelopAnswers !== undefined) {
      if (exam.examCounterRandomDevelopAnswers >= 0) {
        for (let index = 0; index < exam.examCounterRandomDevelopAnswers; index++) {
          const random = await this.getRandomNumber(questionsList.developAnswerList.length, developUsed);
          developUsed.push(random);
        }
      }
    }
    // Cases
    if (exam.examCounterRandomCasesAnswers !== undefined) {
      if (exam.examCounterRandomCasesAnswers >= 0) {
        for (let index = 0; index < exam.examCounterRandomCasesAnswers; index++) {
          const random = await this.getRandomNumber(questionsList.casesAnswerList.length, casesUsed);
          casesUsed.push(random);
        }
      }
    }
    return { oneUsed, oneBUsed, multipleUsed, developUsed, casesUsed };
  }

  getRandomNumber(max, exclude: Array<number>) {
    let notExcluded = false;
    let randomNumber;
    while (notExcluded === false) {
      randomNumber = Math.floor((Math.random() * max) + 1);
      if (exclude) {
        const find = exclude.find(x => x === randomNumber);
        if (!find) {
          notExcluded = true;
        }
      } else {
        notExcluded = true;
      }
    }
    return randomNumber;
  }

  async createExamRandom(exam: Exam, questionsList, randomQuestions) {
    const userExam = new Array<ExamQuestion>();
    // One
    if (questionsList.oneAnswerList !== undefined) { // ACA 2
      if (questionsList.oneAnswerList.length >= 0) {
        questionsList.oneAnswerList.forEach((question: ExamQuestion, index) => {
          let indexToFind = index;
          if (index === 0) { indexToFind = questionsList.oneAnswerList.length; }
          const randomNumber = randomQuestions.oneUsed.find((x) => x === indexToFind);
          if (randomNumber !== undefined) {
            question.examQuestionAnswer = this.shuffle(question.examQuestionAnswer);
            userExam.push(question);
          }
        });
      }
    }
    // One B
    if (questionsList.oneAnswerBList !== undefined) {
      if (questionsList.oneAnswerBList.length >= 0) {
        questionsList.oneAnswerBList.forEach((question: ExamQuestion, index) => {
          let indexToFind = index;
          if (index === 0) { indexToFind = questionsList.oneAnswerBList.length; }
          const randomNumber = randomQuestions.oneBUsed.find((x) => x === indexToFind);
          if (randomNumber !== undefined) {
            question.examQuestionAnswer = this.shuffle(question.examQuestionAnswer);
            userExam.push(question);
          }
        });
      }
    }
    // Multiple
    if (questionsList.multipleAnswerList !== undefined) {
      if (questionsList.multipleAnswerList.length >= 0) {
        questionsList.multipleAnswerList.forEach((question: ExamQuestion, index) => {
          let indexToFind = index;
          if (index === 0) { indexToFind = questionsList.multipleAnswerList.length; }
          const randomNumber = randomQuestions.multipleUsed.find((x) => x === indexToFind);
          if (randomNumber !== undefined) {
            question.examQuestionAnswer = this.shuffle(question.examQuestionAnswer);
            userExam.push(question);
          }
        });
      }
    }
    // Develop
    if (questionsList.developAnswerList !== undefined) {
      if (questionsList.developAnswerList.length >= 0) {
        questionsList.developAnswerList.forEach((question: ExamQuestion, index) => {
          let indexToFind = index;
          if (index === 0) { indexToFind = questionsList.developAnswerList.length; }
          const randomNumber = randomQuestions.developUsed.find((x) => x === indexToFind);
          if (randomNumber !== undefined) {
            userExam.push(question);
          }
        });
      }
    }
    // Cases
    if (questionsList.casesAnswerList !== undefined) {
      if (questionsList.casesAnswerList.length >= 0) {
        questionsList.casesAnswerList.forEach((question: ExamQuestion, index) => {
          let indexToFind = index;
          if (index === 0) { indexToFind = questionsList.casesAnswerList.length; }
          const randomNumber = randomQuestions.casesUsed.find((x) => x === indexToFind);
          if (randomNumber !== undefined) {
            userExam.push(question);
          }
        });
      }
    }
    return userExam;
  }

  changeQuestion(index) {
    const answerList = new Array<any>();
    this.userExamResults.examQuestion[index].examQuestionAnswer.forEach(answer => {
      if (answer.examQuestionAnswerResponseIsCorrect === true) {
        answerList.push(answer.examQuestionAnswerCode);
      }

    });
    this.userExamResults.examQuestion[index].examQuestionAnswerOne = JSON.stringify(answerList);
  }

  shuffle(array) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  setFlags(userExam: ExamQuestion[]) {
    userExam.forEach((question) => {
      question.examQuestionFlag = false;

      if (question.examQuestionType === 'themeCasesAnswer' || question.examQuestionType === 'themeDevelopAnswer' || question.examQuestionType === 'oneAnswer') {
        question.examQuestionAnswerOne = '';
      }

    });
    return userExam;
  }

  deleteAnswers(userExam: ExamQuestion[]) {
    userExam.forEach((question) => {
      if (question.examQuestionType !== 'themeDevelopAnswer' && question.examQuestionType !== 'themeCasesAnswer') {
        question.examQuestionAnswer.forEach((answer) => {
          answer.examQuestionAnswerResponseIsCorrect = false;
        });
      }
    });
    return userExam;
  }

  returnButton() {
    this.closeFullscreen();
   /*  clearInterval(this.intervalWS); */
    /* this.countdown.stop(); */
    this.router.navigate(['/exam/']);
    
  }

  endButton() {
    this.dialogService.open(ConfirmWindowComponent,
      {
        context: {
          message: '¿Está seguro de terminar el examen?'
        },
        hasBackdrop: false,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose.subscribe((result) => {
        if (result === true) {
          this.loading = true;
          clearInterval(this.intervalWS);
          console.log(this.countdown.stop())
          this.countdown.stop();
        }
      });
  }

  async previousQuestion() {
    console.log('this.questionIndex')
    console.log(this.questionIndex)
    if(this.questionIndex != undefined){
      this.questionIndex = this.questionIndex - 1;
    }
    if (this.viewer === undefined) {
      const user: any = JSON.parse(localStorage.getItem('user'));
      const userBackend: UserExam = await this.myExamsBackend(user);
      const userAssign: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
      const userAssignIndex = userBackend.userAssign.indexOf(userAssign);
      userAssign.userExamResults = this.userExamResults.examQuestion;
      userAssign.userExam = JSON.parse(localStorage.getItem('examData'));
      userAssign.userOutOfExam = this.outOfExam;
      userBackend.userAssign[userAssignIndex] = userAssign;
      await this.updateUser(userBackend);
      // await this.activateSpreadSheets(this.questionIndex);
    }
  }

  async nextQuestion() {
    this.questionIndex = this.questionIndex + 1;
    if (this.viewer === undefined) {
      const user: any = JSON.parse(localStorage.getItem('user'));
      const userBackend: UserExam = await this.myExamsBackend(user);
      const userAssign: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
      const userAssignIndex = userBackend.userAssign.indexOf(userAssign);
      userAssign.userExamResults = this.userExamResults.examQuestion;
      userAssign.userExam = JSON.parse(localStorage.getItem('examData'));
      userAssign.userOutOfExam = this.outOfExam;
      userBackend.userAssign[userAssignIndex] = userAssign;
      await this.updateUser(userBackend);
      // await this.activateSpreadSheets(this.questionIndex);
    }
  }

  async selectQuestion(index) {
    this.questionIndex = index;
    if (this.viewer === undefined) {
      const user: any = JSON.parse(localStorage.getItem('user'));
      const userBackend: UserExam = await this.myExamsBackend(user);
      const userAssign: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
      const userAssignIndex = userBackend.userAssign.indexOf(userAssign);
      userAssign.userExamResults = this.userExamResults.examQuestion;
      userAssign.userExam = JSON.parse(localStorage.getItem('examData'));
      userAssign.userOutOfExam = this.outOfExam;
      userBackend.userAssign[userAssignIndex] = userAssign;
      await this.updateUser(userBackend);
      // await this.activateSpreadSheets(this.questionIndex);
    }
  }

  viewImage(userExam) {
    this.dialogService.open(ExamViewerImageComponent,
      {
        context: {
          media: userExam
        },
        hasBackdrop: true,
        closeOnEsc: true,
        closeOnBackdropClick: true
      });
  }

  timesUp(event) {
    console.log('event times up')
    console.log(event)
    if (event.action === 'start') {
      console.log('start event times up')
      this.startRecording();
      this.startMaster();
    }
    if (event.action === 'done') {
      console.log('done event times up')
      this.loading = true;
      if (this.isStreaming === true) {
        this.stopMaster();
        this.stopRecording(true);
      }
    }
    if (event.action === 'stop') {
      console.log('stop event times up')
      this.loading = true;
      clearInterval(this.intervalWS);
      this.stopMaster();
      this.stopRecording(true);

    }
  }

  successCallback(stream: MediaStream) {

    const options = {
      disableLogs: true,
      mimeType: 'video/webm;codecs=opus',
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      bitsPerSecond: 128000,
    };
    this.stream = stream;
    this.recordRTC = RecordRTC(stream, options);
    this.startTimeStamp = Date.now();
    this.recordRTC.startRecording();

    const recordingInterval = setInterval(() => {
      if (!this.isStreaming) { clearInterval(recordingInterval); }

      this.recordRTC.stopRecording(() => {
        const recordRTC = this.recordRTC;
        const recordedBlob = recordRTC.getBlob();
        if (this.isStreaming) {
          this.recordRTC.clearRecordedData();
          this.recordRTC.startRecording();
        }
        if (recordedBlob.size) {
          this.captureMedia(recordedBlob, recordingInterval);
        }
      });
    }, environment.timeUploadVideo);
  }

  async captureMedia(event, recordingInterval = undefined) {
    if (event.size > 0) {
      this.reader.onloadend = async () => {
        const fragmentCode = uuid.v4();
        this.orderStream = this.orderStream + 1;
        this.blobArray.push({
          fragmentCode,
          fragmentOrder: this.orderStream,
          fragmentName: fragmentCode,
          fragmentKey: '',
          fragmentPrefixKey: '',
          fragmentStartTimestamp: this.startTimeStamp,
          blob: this.reader.result,
          fragmentProcess: false
        });
        const userData: any = JSON.parse(localStorage.getItem('user'));
        const send = {
          streamCode: this.streamCode,
          examCode: this.exam.examCode,
          userCode: userData.userId,
          faceId: userData.faceId,
          company: userData.company.companyCode,
          pipelineId: environment.pipelineId,
          bucketName: environment.bucketName,
          mRekognitionQueueUrl: environment.mRekognitionQueueUrl,
          mRekognitionReturnQueueUrl: environment.mRekognitionReturnQueueUrl,
          region: environment.region,
          blob: this.reader.result,
          blobId: fragmentCode,
          fragmentOrder: this.orderStream,
          timeStampStart: this.startTimeStamp,
          timeStampUpload: Date.now(),
          lastFrame: this.stopStream,
          // blobArray: this.blobArray
        };
        if (this.isStreaming === true) {
          const user: any = JSON.parse(localStorage.getItem('user'));
          const userBackend: UserExam = await this.myExamsBackend(user);
          const userAssign: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
          const userAssignExtra: UserAssign = userBackend.userAssign.find((x) => x.userStreamCode === this.streamCode);
          const userAssignIndex = userBackend.userAssign.indexOf(userAssign);
          userAssignExtra.userExamResults = this.userExamResults.examQuestion;
          userAssignExtra.userExam = JSON.parse(localStorage.getItem('examData'));
          userAssign.userOutOfExam = this.outOfExam;
          // await this.updateUserAssign(userAssignExtra);
          // userAssign.userExam = undefined;
          // userAssign.userExamResults = undefined;
          userBackend.userAssign[userAssignIndex] = userAssign;
          await this.updateUser(userBackend);
          this.streamingService.uploadAndInsertStreamBackend(send).then(
            async (result) => {

              if (this.stopStream === true) {
                userAssign.userExamEnd = new Date();
                // await this.updateUserAssign(userAssignExtra);
                await this.updateUser(userBackend);
                clearInterval(recordingInterval);
                localStorage.removeItem('examData');
                this.router.navigate(['/examResultPostViewer']);
                this.closeFullscreen();
                // this.loading = false;
              }
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
            });
        }
        this.startTimeStamp = Date.now();
      };
      this.reader.readAsDataURL(event);
    }
  }

  myExamsBackend(user) {
    this.loading = true;
    return this.userService.oneUserExamBackend(user.userId).then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  updateUser(user) {
    this.loading = true;
    this.userService.updateUserBackend(user).then(
      (result) => {
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  // updateUserAssign(userAssign) {
  //   this.loading = true;
  //   this.userService.updateUserAssignBackend(userAssign).then(
  //     (result) => {
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show(
  //         '',
  //         err.message,
  //         { position, status });
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  errorCallback() {
    console.error('error');
  }

  processVideo(audioVideoWebMURL) {

    const recordRTC = this.recordRTC;
    const recordedBlob = recordRTC.getBlob();
    if (this.recordRTC.stream != null) {
      this.recordRTC.stream.getTracks().map(function (val) {
        val.stop();
      });
    }
    if (recordedBlob.size) {
      this.captureMedia(recordedBlob);
    }
  }

  startRecording() {
    this.isStreaming = true;
    const mediaConstraints = {
      video: true,
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  stopRecording(stop = true) {
    this.isStreaming = true;
    this.stopStream = stop;
    const recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    const stream = this.stream;
    stream.getAudioTracks().forEach(track => track.stop());
    stream.getVideoTracks().forEach(track => track.stop());
  }

  download() {
    this.recordRTC.save('video.webm');
  }

  async startMaster() {
    const localView = this.videoMaster.nativeElement;
    localView.muted = true;
    localView.volume = 0;
    this.master["localView"] = localView;

    const user: User = JSON.parse(localStorage.getItem('user'));
    const channelName = 'mexam-exam-channel-' + user.userId;
    const useTrickleICE = false;

    try {
      await kinesisVideoClient.createSignalingChannel({
        ChannelName: channelName,
        ChannelType: "SINGLE_MASTER",
        SingleMasterConfiguration: {
          MessageTtlSeconds: 10
        },
        Tags: [
          {
            Key: "stage",
            Value: environment.bucketName.replace('-content', ''),
          }
        ]
      }).promise();
    }
    catch {
    }
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    this.master.channelARN = channelARN;
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: Role.MASTER,
        },
      })
      .promise();
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      return endpoints;
    }, {});
    this.master.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol["WSS"],
      role: Role.MASTER,
      region: environment.region,
      credentials: {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });
    const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
      region: environment.region,
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      endpoint: endpointsByProtocol["HTTPS"],
      correctClockSkew: true,
    });
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();

    const iceServers = [];
    getIceServerConfigResponse.IceServerList.forEach(iceServer =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      }),
    );
    const configuration = {
      iceServers
    };

    const constraints = {
      video: true,
      audio: true,
    };
    try {
      this.master.localStream = await navigator.mediaDevices.getUserMedia(constraints);
      localView.srcObject = this.master.localStream;
    } catch (e) {
    }
    this.master.signalingClient.on('open', async () => {
    });
    this.master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {

      const peerConnection = new RTCPeerConnection(configuration);
      this.master.peerConnectionByClientId = new Array();
      this.master.peerConnectionByClientId[remoteClientId] = peerConnection;

      const peerConnectionStatsInterval = setInterval(() => peerConnection.getStats().then(), 1000);

      peerConnection.addEventListener('icecandidate', ({ candidate }) => {
        if (candidate) {

          if (useTrickleICE) {
            this.master.signalingClient.sendIceCandidate(candidate, remoteClientId);
          }
        } else {

          if (!useTrickleICE) {
            this.master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
          }
        }
      });


      this.master.localStream.getTracks().forEach(track => peerConnection.addTrack(track, this.master.localStream));
      await peerConnection.setRemoteDescription(offer);

      await peerConnection.setLocalDescription(
        await peerConnection.createAnswer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        }),
      );

      if (useTrickleICE) {
        this.master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
      }
    });


    this.master.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {

      const peerConnection = this.master.peerConnectionByClientId[remoteClientId];
      peerConnection.addIceCandidate(candidate);
    });

    this.master.signalingClient.on('close', () => {
    });

    this.master.signalingClient.on('error', () => {
    });

    this.master.signalingClient.open();

    return endpointsByProtocol;
  }

  async stopMaster() {
    if (this.master.signalingClient) {
      this.master.signalingClient.close();
      this.master.signalingClient = null;
    }
    Object.keys(this.master.peerConnectionByClientId).forEach(clientId => {
      this.master.peerConnectionByClientId[clientId].close();
    });
    this.master.peerConnectionByClientId = [];

    if (this.master.localStream) {
      this.master.localStream.getTracks().forEach(track => track.stop());
      this.master.localStream = null;
    }

    this.master.remoteStreams.forEach(remoteStream => remoteStream.getTracks().forEach(track => track.stop()));
    this.master.remoteStreams = [];

    if (this.master.peerConnectionStatsInterval) {
      clearInterval(this.master.peerConnectionStatsInterval);
      this.master.peerConnectionStatsInterval = null;
    }

    if (this.master.localView) {
      this.master.localView.srcObject = null;
    }

    if (this.master.remoteView) {
      this.master.remoteView.srcObject = null;
    }

    if (this.master.dataChannelByClientId) {
      this.master.dataChannelByClientId = {};
    }

    // Create KVS client
    const kinesisVideoClient = new KinesisVideo({
      region: environment.region,
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      correctClockSkew: true,
    });

    try { //try to create the channell for the user
      await kinesisVideoClient.deleteSignalingChannel({
        ChannelARN: this.master.channelARN,
      }).promise();
    }
    catch {
    }


  }

}
