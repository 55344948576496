import { Component, Input} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-simple-time',
  template: `
    {{transformDate}}
  `,
  styles: [

  ]
})
export class SimpleTimeComponent {

  @Input() value: string;

  get transformDate(){
    return moment(this.value, 'D/M/YYYY HH:mm:ss').format('D/M/YYYY HH:mm');
  }

}
