import { Component, OnInit } from '@angular/core';
import { StreamingService } from 'src/app/services/streaming.service';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { ExamService } from 'src/app/services/exam.service';
import { NbDialogService } from '@nebular/theme';
import { ExamVerificationComponent } from './exam-verification/exam-verification.component';
// import examResultsTableSettings from '../../../../assets/config/examResultsTableSettings.json';

@Component({
  selector: 'app-exam-verify',
  templateUrl: './exam-verify.component.html',
  styleUrls: ['./exam-verify.component.scss']
})
export class ExamVerifyComponent implements OnInit {
  // settings = examResultsTableSettings;
  streamList;
  userResultList;
  loading;

  constructor(
    private examService: ExamService,
    private userExamService: UserService,
    private authService: AuthService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.getAllStream();
  }

  getAllStream() {
    this.loading = true;
    this.userExamService.allUserExamBackend().then(
      (userResult) => {
        const userResultList = userResult.body;
        const resultsList = new Array<any>();
        userResultList.forEach((userExam, index) => {
          if (userExam.userExams !== undefined) {
            userExam.userExams.forEach(exam => {
              if (exam.examUserResults !== undefined) {
                exam.examUserResults.forEach(result => {
                  if (result.examStreamCode !== undefined) {
                    resultsList.push({
                      examCode: exam.examCode,
                      examName: exam.examName,
                      examUserCode: userExam.userCode,
                      examStreamCode: result.examStreamCode,
                      examUserResults: result.examUserAnswers,
                      examResults: result.examAnswers
                    });
                  }
                });
              }
            });
          }
        });
        this.authService.getUsers().then(
          (userCognitoResult: any) => {
            const userCognito = userCognitoResult.Users;
            resultsList.forEach((userExam, index) => {
              userCognito.forEach(user => {
                const userSub = user.Attributes.find((x) => x.Name === 'sub');
                const username = user.Attributes.find((x) => x.Name === 'email');
                const email = user.Attributes.find((x) => x.Name === 'email');
                const name = user.Attributes.find((x) => x.Name === 'name');
                const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
                if (userExam.examUserCode === userSub.Value) {
                  userExam.userName = name.Value;
                  userExam.userEmail = email.Value;
                  userExam.userRole = userRole.Value;
                  userExam.firstPassword = undefined;
                }
              });
            });
            this.userResultList = resultsList;
            this.loading = false;
          }).catch();
      }).catch();
  }

  goToResult(event) {
    this.dialogService.open(ExamVerificationComponent,
      {
        context: {
          streamCode: event.examStreamCode,
          results: event
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose.subscribe((result) => {
      });
  }

}
