import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import Spreadsheet from "x-data-spreadsheet";

@Component({
  selector: 'app-exam-spread-sheet',
  templateUrl: './exam-spread-sheet.component.html',
  styleUrls: ['./exam-spread-sheet.component.scss']
})
export class ExamSpreadSheetComponent implements OnInit {

  list = [
    { id: "spreadsheet1", xls: undefined },
    { id: "spreadsheet2", xls: undefined }
  ]

  async ngOnInit() {
    // let s = new Spreadsheet("#x-spreadsheet-demo");
    // s.loadData({}); // load data
    // s.change(data => {
    //   // save data to db
    //   console.log(s);
    // });

    // data validation
    // s.validate();
    await this.startSpreadSheet();
  }

  async startSpreadSheet() {
    await this.delay(1)
    this.list.forEach(element => {
      element.xls = new Spreadsheet("#" + element.id);
      element.xls.loadData({});
      element.xls.change(data => {
        // console.log(element.xls);
      });
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
