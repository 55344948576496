import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Exam } from 'src/app/models/exam';
import { Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { ExamService } from 'src/app/services/exam.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-exam-maintenance-tools',
  templateUrl: './exam-maintenance-tools.component.html',
  styleUrls: ['./exam-maintenance-tools.component.scss']
})
export class ExamMaintenanceToolsComponent implements OnInit, OnChanges {
  loading = false;
  examList: Exam[];
  @Input() refreshListExam;
  @Input() examAdd;
  @Input() examTittle;
  @Output() examListResult = new EventEmitter<any[]>();

  constructor(
    private router: Router,
    private toastrService: NbToastrService,
    private examService: ExamService,
  ) { }

  ngOnInit() {
    this.listAllExams();
  }

  ngOnChanges() {
    if (this.refreshListExam === true) {
      this.listAllExams();
    }
  }

  addNewExam() {
    this.router.navigate(['/examMaintenanceFields']);
  }

  listAllExams() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    console.log('companyCode: ', companyCode);
    this.examService.allExamCompanyBackend(companyCode).then(
      (result) => {
        console.log('result: ', result);

        this.examList = result.body;
        console.log('examList: ', this.examList);
        this.examListResult.emit(this.examList);
        this.loading = false;
      }).catch(
        (err) => {
          console.log(err)
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }
}
