import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import roleListJson from '../../../../assets/data/userRole/userRole.json';

@Component({
  selector: 'app-user-maintenance-tools',
  templateUrl: './user-maintenance-tools.component.html',
  styleUrls: ['./user-maintenance-tools.component.scss']
})
export class UserMaintenanceToolsComponent implements OnInit, OnChanges {
  loading = false;
  userList: User[];
  @Input() refreshListUser;
  @Output() userListResult = new EventEmitter<any[]>();

  constructor(
    private router: Router,
    private toastrService: NbToastrService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.listAllUsers();
  }

  ngOnChanges() {
    if (this.refreshListUser === true) {
      this.listAllUsers();
    }
  }

  addNewUser() {
    this.router.navigate(['/userMaintenanceFields']);
  }

  listAllUsers() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    this.userService.allUserCognito().then(
      (result) => {
        const userCognito = result.Users;
        const userList = new Array<any>();
        userCognito.forEach(user => {
          const userId = user.Username;
          const email = user.Attributes.find((x) => x.Name === 'email');
          const name = user.Attributes.find((x) => x.Name === 'name');
          const role = user.Attributes.find((x) => x.Name === 'custom:role');
          const group = user.Attributes.find((x) => x.Name === 'custom:group');
          const company = user.Attributes.find((x) => x.Name === 'custom:company');
          const status = user.Enabled;
          const statusVerification = user.UserStatus === 'CONFIRMED' ? true : false;
          const statusShow = status ? 'Activo' : 'Inactivo';
          const statusVerificationShow = statusVerification ? 'Si' :
            user.UserStatus === 'EXTERNAL_PROVIDER' ? 'Si / Google' : 'No';
          const roleShow = roleListJson.find((x) => x.value === role.Value);
          let groupShow;
          if (group === undefined) { groupShow = 'Sin Grupo'; } else { groupShow = group.Value; }
          if (role.Value !== 'administrator' && company.Value === companyCode) {
            userList.push({
              userId,
              userEmail: email.Value,
              userName: name.Value,
              userStatus: status,
              userStatusShow: statusShow,
              userGroupShow: groupShow,
              userRoleShow: roleShow.type,
              statusVerificationShow,
            });
          }
        });
        this.userList = userList;
        this.userListResult.emit(this.userList);
        this.loading = false;
      }).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }
}
