import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterContentInit } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import moment from 'moment';

@Component({
  selector: 'app-master-filter',
  templateUrl: './master-filter.component.html',
  styleUrls: ['./master-filter.component.scss']
})
export class MasterFilterComponent implements OnInit, AfterContentInit {

  @Output() filterdata = new EventEmitter<any>();
  @Output() cleandata = new EventEmitter<any>();
  @Input() filterFields;

  selectedOption: number = null;

  selectedItem: number = null;
  itemslist: string[] = []


  inputtypes = {
    'text': 0,
    'number': 1,
    'date': 2,
    'hour': 3,
    'second': 4,
    'datetime': 5,
    'options': 6
  }

  availableFilters;

  constructor(private toastrService: NbToastrService) { }

  ngAfterContentInit(): void {
    this.availableFilters = [...this.filterFields];
  }

  inputTypeNumber: number = 0;

  ngOnInit(): void {
    this.setInputType();
  }

  textValue: string = '';

  filterTags = []



  // Establecer el tipo de input que se utilizará
  setInputType(): void{
    this.inputTypeNumber = this.selectedOption == null
      ? 0
      : this.inputtypes[this.availableFilters[this.selectedOption].type]

    this.textValue = '';

    if(this.inputTypeNumber == 6) {
      this.itemslist = this.availableFilters[this.selectedOption].possibleValues
    }

  }

  clearFilters() {
    this.filterTags = []
    this.availableFilters = [...this.filterFields];
    this.selectedOption = null;
    this.inputTypeNumber = 0;
    this.textValue = '';
    this.cleandata.emit();
  }

  applicateFilter() {
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';

    if (this.textValue.toString() != '' || this.availableFilters[this.selectedOption].type === 'options'){
      let newTag = {
        fieldlabel: this.availableFilters[this.selectedOption].fieldlabel,
        value: '',
        fieldname: this.availableFilters[this.selectedOption].fieldname
      }

      // Formateo para fechas
      if (this.availableFilters[this.selectedOption].type === 'date') newTag.value = moment(this.textValue).format('D/M/YYYY')

      // Formateo para tiempo
      if (this.availableFilters[this.selectedOption].type === 'hour') newTag.value = moment(this.textValue).format('HH:mm')

      // Formateo para tiempo y fechas
      if (this.availableFilters[this.selectedOption].type === 'datetime') newTag.value = moment(this.textValue).format('D/M/YYYY HH:mm')

      // Opciones predeterminadas
      if (this.availableFilters[this.selectedOption].type === 'options') newTag.value = this.availableFilters[this.selectedOption].possibleValues[this.selectedItem]

      // Formateo para texto
      if (this.availableFilters[this.selectedOption].type === 'text') newTag.value = this.textValue

      // Formateo para número
      if (this.availableFilters[this.selectedOption].type === 'number'){
        if (parseInt(this.textValue.toString()) < 0 ){
          this.toastrService.show(
            'Por favor ingrese un valor mínimo de 0',
            'Valor invalido',
            { position, status });
        } else newTag.value = this.textValue.toString();
      }

      this.filterTags.push(newTag);
      this.availableFilters.splice(this.selectedOption, 1);
      this.selectedOption = null;
      this.inputTypeNumber = 0;
      this.textValue = '';
      this.filterdata.emit(this.filterTags);
    } else {
      this.toastrService.show(
        'Por favor ingrese un valor',
        'Sin valor',
        { position, status });
    }
  }

};
