import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { ExamService } from 'src/app/services/exam.service';
import { Exam } from 'src/app/models/exam';


@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit {
  loading = false;
  examList: Exam[];

  constructor(
    private router: Router,
    private toastrService: NbToastrService,
    private examService: ExamService,
  ) { }

  ngOnInit() {
  }

  goToExam(event) {
    const exam = event.data as Exam;
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    if (exam.examQuestion === undefined) {
      this.toastrService.show(
        '',
        'Examen no contiene preguntas',
        { position, status });
      return false;
    }
    if (exam.examQuestion.length === 0) {
      this.toastrService.show(
        '',
        'Examen no contiene preguntas',
        { position, status });
      return false;
    }
    this.router.navigate(['/examViewerTeacher/' + event.data.examCode]);

  }

  examListReceiver($event) {
   
    this.examList = $event;
    console.log(this.examList)
  }
}
