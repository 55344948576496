import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { StreamingService } from 'src/app/services/streaming.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import reportsUserCheckFindTableSettings from '../../../../assets/config/reportsTable/reportsUserCheckFindTableSettings.json';
import examFilterSettings from '../../../../assets/config/filterTable/exam/examFindsTableFilters.json';

@Component({
  selector: 'app-exam-finds',
  templateUrl: './exam-finds.component.html',
  styleUrls: ['./exam-finds.component.scss']
})
export class ExamFindsComponent implements OnInit, OnChanges {
  loading;
  anomaliesList;
  @Input() userCode;
  @Input() streamCode;

  source: LocalDataSource;

  examFSettings = examFilterSettings;

  settings = reportsUserCheckFindTableSettings;
  @ViewChild('video', { static: false }) videoPlayer: ElementRef;

  constructor(
    private streamingService: StreamingService,
    private userService: UserService,
    private toastrService: NbToastrService,
  ) {
    this.source = new LocalDataSource(this.anomaliesList);
   }

  async ngOnInit() {
    const stream = await this.getStream(this.streamCode);
    const user = await this.getUser(this.userCode);
    this.videoPlayer.nativeElement.src = environment.cloudFrontUrl + stream.videoKey;
    const streamFile = await this.getStreamFile(stream);
    this.anomaliesList = await this.transformData(streamFile);
    this.source = new LocalDataSource(this.anomaliesList);
  }

  ngOnChanges() {
  }

  transformData(streamFile) {
    const show = new Array();
    try {
      streamFile.faceIdAnomalies.forEach(faceIdAnomalie => {
        let type;
        switch (faceIdAnomalie.facesFound.code) {
          case 'faceNotEvenInCollection':
            type = 'Rostro fuera de colección'
            break;
          case 'moreThanOnePerson':
            type = 'Más de un rostro'
            break;
          case 'noneFace':
            type = 'No se detectó rostro'
            break;
          case 'faceInCollectionButDoesntMatchUserFaceId':
            type = 'Rostro no coincide'
            break;
        }
        show.push({
          second: this.secondsToString(faceIdAnomalie.facesFound.ts),
          type,
          image: faceIdAnomalie.objectKey
        })
      });
    } catch (error) {

    }
    // streamFile.noFaceIntervals.forEach(intervals => {

    //   show.push({
    //     second: this.secondsToString(faceIdAnomalie.facesFound.ts),
    //     type,
    //     image: faceIdAnomalie.objectKey
    //   })
    // });
    return show;
  }

  secondsToString(seconds) {
    let hour: any = Math.floor(seconds / 3600);
    hour = (hour < 10) ? '0' + hour : hour;
    let minute: any = Math.floor((seconds / 60) % 60);
    minute = (minute < 10) ? '0' + minute : minute;
    let second: any = seconds % 60;
    second = (second < 10) ? '0' + second : second;
    return hour + ':' + minute + ':' + second;
  }

  getUser(userCode) {
    return this.userService.oneUserExamBackend(userCode).then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show('', err, { position, status });
      }
    );
  }

  getStreamFile(stream) {
    return this.streamingService.getStreamFindS3(stream.anomaliesKey).then(
      (result: any) => {
        const json = JSON.parse(result);
        return json;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show('', 'Archivo de hallazgos no encontrados.', { position, status });
      }
    );
  }

  getStream(streamCode) {
    return this.streamingService.oneStreamBackend(streamCode).then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show('', err, { position, status });
      }
    );
  }

  cleanData() {
    this.source.setFilter([]);
  }

  filterData(event) {
    let filters = []
    event.forEach(element => {
      filters.push({
        field: element.fieldname,
        search: element.value
      });
    });
    this.source.setFilter(filters)

  }

}
