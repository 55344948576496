import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

// import { DatePickerPopoverComponent } from './date-time-popover.component';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

  // componentDatePicker = DatePickerPopoverComponent;

  constructor() { }

  ngOnInit() {
  }

}
