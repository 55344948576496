import { User } from './../models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Company } from '../models/company';
import * as S3 from 'aws-sdk/clients/s3';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  companyProgress = new Subject<any>();
  companyTable = 'mexam-company';
  companyFolder = 'company';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  updateCompanyBackend(company: Company): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.put(environment.apiUrl + this.companyTable + '/', company, { headers }).toPromise();
  }

  searchCompanyBackend(searchValue: any): Promise<any> {
    const filter = searchValue.toLowerCase();
    return this.http.get(environment.apiUrl + this.companyTable + '/search/' + filter).toPromise();
  }

  oneCompanyBackend(companyCode: string): Promise<any> {
    const filter = companyCode;
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.apiUrl + this.companyTable + '/one/' + filter + queryParam, { headers }).toPromise();
  }

  allCompanyBackend(): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.apiUrl + this.companyTable + queryParam, { headers }).toPromise();
  }

  uploadFileS3(file, company: Company, folder): Promise<any> {
    this.companyProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company' + '/' + company.companyCode + '/' + folder + '/' + file.name,
      Body: file,
      ACL: 'private',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.companyProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }

  deleteObject(file, company: Company, folder) {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company' + '/' + company + '/' + this.companyFolder + '/' + folder + '/' + file.name,
    };
    return new Promise((resolve, reject) => {
      bucket.deleteObject(params).send((err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      });
    });
  }

  uploadStudentFileS3(file, company: Company, folder, userId: User): Promise<any> {
    this.companyProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company' + '/' + company.companyCode + '/' + folder + '/' + userId + '/' + file.name,
      Body: file,
      ACL: 'private',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.companyProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }

  deleteStudentImage(file, company: Company, folder, userId) {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company' + '/' + company.companyCode + '/' + folder + '/' + userId + '/' + file.name,
    };
    return new Promise((resolve, reject) => {
      bucket.deleteObject(params).send((err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      });
    });
  }
}
