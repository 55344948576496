import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-meet',
  templateUrl: './meet.component.html',
  styleUrls: ['./meet.component.scss']
})
export class MeetComponent implements OnInit {

  @Input() examCode;
  @Input() user;
  @Input() streamCode;
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }

  async ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.mMeetUrl +
      'meet/' + this.streamCode +
      '/' + this.user.name
    );
  }

}
