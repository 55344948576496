import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { ExamService } from 'src/app/services/exam.service';
import examAssignedTableSettings from '../../../../assets/config/examTable/examAssignedTableSettings.json';
import { UserExam } from 'src/app/models/userExam';
import { Exam } from 'src/app/models/exam';
import { UserAssign } from 'src/app/models/userAssign';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-my-exam',
  templateUrl: './my-exam.component.html',
  styleUrls: ['./my-exam.component.scss']
})
export class MyExamComponent implements OnInit {
  settings = examAssignedTableSettings;

  loading = false;
  examList;
  examListLocal;
  hasFaceId = true;

  constructor(
    private router: Router,
    private toastrService: NbToastrService,
    private userService: UserService,
    private examService: ExamService,
    private companyService: CompanyService,
  ) { }

  async ngOnInit() {
    await this.reloadMyExams();
  }

  async reloadMyExams() {
    let user;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user')) as User;
      const company = await this.getCompany(user.company.companyCode);
      if (company.companyFaceId !== false) {
        if (user.faceId === undefined) {
          //quita el redireccionamiento de faceId y enlista los examenes
         // this.router.navigate(['/userFaceId']);
         await this.listAllExams();
        }
      }
    }
    //esto quita la identificacion de usuario inicial (foto de identidad)
    /* if (this.hasFaceId === true) {
      await this.listAllExams();
    } */
    await this.listAllExams();
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        const company = result.body;
        return company;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async goToExam(event) {
    const userAssign: UserAssign = event.data.exam;
    const exam: Exam = event.data.examAllData;
    const currentDateTime = new Date();
    const startDate = new Date(userAssign.userExamStartDateTime);
    const validationDate = new Date(startDate);
    const endDate = new Date(startDate);
    validationDate.setMinutes(validationDate.getMinutes() - parseInt(exam.examPreValidationMinutes, 0));
    endDate.setMinutes(endDate.getMinutes() + parseInt(exam.examTime, 0));
    if (
      currentDateTime >= validationDate &&
      currentDateTime < endDate
    ) {
      const user: User = JSON.parse(localStorage.getItem('user'));
      const myExams: UserExam = await this.myExamsBackend(user);
      const assignment = myExams.userAssign.find((x) => x.userStreamCode === event.data.exam.userStreamCode);
      const assignmentIndex = myExams.userAssign.indexOf(assignment);
      if (myExams.userAssign[assignmentIndex].userAttemptIntoExam === undefined) {
        myExams.userAssign[assignmentIndex].userAttemptIntoExam = 0;
      }
      if (myExams.userAssign[assignmentIndex].userAttemptIntoExam <= exam.examCounterReEntry) {
        myExams.userAssign[assignmentIndex].userAttemptIntoExam++;
        await this.updateUser(myExams);
        this.router.navigate(['/examPreviousValidations/' + exam.examCode + '/' + userAssign.userStreamCode]);
      } else {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        const message = 'Examen no permite ingreso a sobrepasado el limite de reingresos.';
        this.toastrService.show(
          '',
          message,
          { position, status });
      }

    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'warning';
      const message = 'Examen no permite ingreso revise su horario.';
      this.toastrService.show(
        '',
        message,
        { position, status });
    }
  }

  getHours(userExamStartTime: string) {
    return userExamStartTime.split(':')[0];
  }

  getMinutes(userExamStartTime) {
    return userExamStartTime.split(':')[1].split(' ')[0];
  }

  getValidationHourMinute(examPreValidationMinutes, hour, minute) {
    let minutesPreVal = minute - examPreValidationMinutes;
    let hoursPreVal = hour;
    if (minutesPreVal < 0) {
      hoursPreVal = hour - 1;
      minutesPreVal = 60 + minutesPreVal;
    }
    return { hoursPreVal: parseInt(hoursPreVal, 0), minutesPreVal };
  }

  getEndHourMinute(endTime, hour, minute) {
    let endHour = parseInt(hour, 0);
    let endMinute = parseInt(minute, 0) + parseInt(endTime, 0);
    let hourExtra = 0;
    if (endMinute > 60) {
      hourExtra = Math.trunc((endMinute / 60));
      if (hourExtra > 0) {
        endHour = endHour + hourExtra;
        endMinute = endMinute - (hourExtra * 60);
      }
    }
    return { endHour, endMinute };
  }

  getCurrentHourMinutes() {
    const currentTime = new Date();
    let hour = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    if (hour > 12) {
      hour = hour - 12;
    }
    return { hour, minutes };
  }

  async listAllExams() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (user !== undefined && user !== null) {
      const myExams: UserExam = await this.myExamsBackend(user);
      const allExams: Exam[] = await this.allCompanyExams(user.company.companyCode);
      const examList = new Array<any>();
      if (myExams !== undefined) {
        myExams.userAssign.forEach((exam) => {
          const examAllData = allExams.find((x) => x.examCode === exam.userExamCode);
          if (examAllData !== undefined) {
            if (exam.userExamEnd === undefined) {
              const startDate = new Date(exam.userExamStartDateTime);
              const endDate = new Date(startDate);
              endDate.setMinutes(endDate.getMinutes() + parseInt(examAllData.examTime, 0));
              examList.push({
                examAllData,
                exam,
                examNameShow: examAllData.examName,
                examDateShow: new Date(exam.userExamStartDateTime).toLocaleDateString('es-GT'),
                examTimeShow: this.getTimeOnly(new Date(exam.userExamStartDateTime)),
                examDateEndShow: new Date(endDate).toLocaleDateString('es-GT'),
                examTimeEndShow: this.getTimeOnly(new Date(endDate)),
              });
            }
          }
        });
        this.examList = await this.showOnlyAllow(examList);
      }
    }
  }

  showOnlyAllow(examList) {
    const list = new Array<any>();
    examList.forEach(examL => {
      const userAssign: UserAssign = examL.exam;
      const exam: Exam = examL.examAllData;
      const currentDateTime = new Date();
      const startDate = new Date(userAssign.userExamStartDateTime);
      const validationDate = new Date(startDate);
      const endDate = new Date(startDate);
      validationDate.setMinutes(validationDate.getMinutes() - parseInt(exam.examPreValidationMinutes, 0));
      endDate.setMinutes(endDate.getMinutes() + parseInt(exam.examTime, 0));
      if (
        currentDateTime >= validationDate &&
        currentDateTime < endDate
      ) {
        list.push(examL);
      }

    });
    return list;
  }

  getTimeOnly(d) {
    const x = document.getElementById('demo');
    const h = this.addZero(d.getHours());
    const m = this.addZero(d.getMinutes());
    const s = this.addZero(d.getSeconds());
    return h + ':' + m;
  }

  addZero(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  myExamsBackend(user) {
    this.loading = true;
    return this.userService.oneUserExamBackend(user.userId).then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  allCompanyExams(companyCode) {
    this.loading = true;
    return this.examService.allExamCompanyBackend(companyCode).then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  updateUser(user) {
    this.loading = true;
    this.userService.updateUserBackend(user).then(
      (result) => {
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }



}
