import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-exam-viewer-image',
  templateUrl: './exam-viewer-image.component.html',
  styleUrls: ['./exam-viewer-image.component.scss']
})
export class ExamViewerImageComponent implements OnInit {
  @Input() media;

  constructor() { }

  ngOnInit() {
  }

}
