import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import examAssignTableSettings from '../../../../assets/config/examTable/examAssignTableSettings.json';
import examViewTableSettings from '../../../../assets/config/examTable/examViewTableSettings.json';
import examCloneTableSettings from '../../../../assets/config/examTable/examCloneTableSettings.json';

import examFilterSettings from '../../../../assets/config/filterTable/exam/examTableFilters.json';
import examAssignFilterSettings from '../../../../assets/config/filterTable/exam/examAssignTableFilters.json';
import examViewFilterSettings from '../../../../assets/config/filterTable/exam/examViewTableFilters.json';
import examCloneFilterSettings from '../../../../assets/config/filterTable/exam/examCloneTableFilters.json';

import { ExamQuestion } from 'src/app/models/examQuestion';
import { examTableSettings  } from "../../../shared/TableSettings/examTable/tableSettings";
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-exam-maintenance-table',
  templateUrl: './exam-maintenance-table.component.html',
  styleUrls: ['./exam-maintenance-table.component.scss']
})
export class ExamMaintenanceTableComponent implements OnInit, OnChanges {
  @Input() examList: any[];
  @Input() examAssign;
  @Input() examView;
  @Input() examClone;
  @Output() examEdit = new EventEmitter<any>();
  @Output() examDelete = new EventEmitter<any>();
  @Output() examAssignation = new EventEmitter<any>();


  source: LocalDataSource;

  loading;

  examFSettings = examFilterSettings;

  settings = examTableSettings;

  constructor() {
    this.source = new LocalDataSource(this.examList);
  }

  ngOnInit() {
    if (this.examAssign === true) {
      this.settings = examAssignTableSettings;
      this.examFSettings = examAssignFilterSettings
    }
    if (this.examView === true) {
      this.settings = examViewTableSettings;
      this.examFSettings = examViewFilterSettings;
    }
    if (this.examClone === true) {
      this.settings = examCloneTableSettings;
      this.examFSettings = examCloneFilterSettings;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.examList) {
      this.source = new LocalDataSource(this.examList);
    }
    if (this.examList !== undefined) {
      const examList = this.examList;
      this.examList = new Array<any>();
    
      examList.forEach((exam, indexExam, objectExam) => {
        /* console.log(exam) */
        exam.examStatusShow = exam.examStatus ? 'Activo' : 'Inactivo';
        let counter = 0;
        let counterExamDevelop = 0;
        let counterExamMultiple = 0;
        let counterExamOneB = 0;
        let counterExamOne = 0;
        let counterExamCases = 0;
        var countExam = exam.examQuestion
        /* console.log(countExam) */
        if (exam.examQuestion !== undefined) {
          exam.examQuestion.forEach((question: ExamQuestion) => {
            counter++;
            /* console.log(question) */
            if (question.examQuestionType === 'oneAnswer') { counterExamOne++; }
            if (question.examQuestionType === 'oneAnswerB') { counterExamOneB++; }
            if (question.examQuestionType === 'multipleAnswer') { counterExamMultiple++; }
            if (question.examQuestionType === 'themeDevelopAnswer') { counterExamDevelop++; }
            if (question.examQuestionType === 'themeCasesAnswer') { counterExamCases++; }
          });
        }
        exam.examQuestionTimeValidationShow = exam.examPreValidationMinutes + '';
        exam.examQuestionTimeShow = exam.examTime + '';
        exam.examQuestionCounterShow = counter;
        exam.examQuestionCounterOneShow = counterExamOne;
        exam.examQuestionCounterOneBShow = counterExamOneB;
        exam.examQuestionCounterMultipleShow = counterExamMultiple;
        exam.examQuestionCounterDevelopShow = counterExamDevelop;
        exam.examQuestionCounterCasesShow = counterExamCases;
        exam.examCreationDateShow = new Date(exam.examCreationDate).toLocaleString('es-GT');
        if (
          exam.examCounterRandomMultipleAnswers === undefined ||
          exam.examCounterRandomMultipleAnswers === 0 ||
          exam.examCounterRandomMultipleAnswers === ''
        ) {
          exam.examCounterRandomMultipleAnswers = 0;
        }

        if (
          exam.examCounterRandomOneAnswer === undefined ||
          exam.examCounterRandomOneAnswer === 0 ||
          exam.examCounterRandomOneAnswer === ''
        ) {
          exam.examCounterRandomOneAnswer = 0;
        }
        if (
          exam.examCounterRandomDevelopAnswers === undefined ||
          exam.examCounterRandomDevelopAnswers === 0 ||
          exam.examCounterRandomDevelopAnswers === ''
        ) {
          exam.examCounterRandomDevelopAnswers = 0;
        }
        if (
          exam.examCounterRandomCasesAnswers === undefined ||
          exam.examCounterRandomCasesAnswers === 0 ||
          exam.examCounterRandomCasesAnswers === ''
        ) {
          exam.examCounterRandomCasesAnswers = 0;
        }

        if (
          exam.examCounterRandomOneAnswerB === undefined ||
          exam.examCounterRandomOneAnswerB === 0 ||
          exam.examCounterRandomOneAnswerB === ''
        ) {
          exam.examCounterRandomOneAnswerB = 0;
        }

        exam.examQuestionCounterUsedShow = parseInt(exam.examCounterRandomOneAnswer) +
          parseInt(exam.examCounterRandomMultipleAnswers) +
          parseInt(exam.examCounterRandomDevelopAnswers) + parseInt( exam.examCounterRandomCasesAnswers)+ parseInt( exam.examCounterRandomOneAnswerB);
        exam.examQuestionCounterOneUsedShow = exam.examCounterRandomOneAnswer;
        exam.examQuestionCounterBUsedShow = exam.examCounterRandomOneAnswerB;
        exam.examQuestionCounterMultipleUsedShow = exam.examCounterRandomMultipleAnswers;
        exam.examQuestionCounterDevelopUsedShow = exam.examCounterRandomDevelopAnswers;
        exam.examQuestionCounterCasesUsedShow = exam.examCounterRandomCasesAnswers;

        this.examAssign = this.examAssign === undefined ? false : this.examAssign;
        this.examView = this.examView === undefined ? false : this.examView;
        if (this.examAssign === true) {
          if (
            exam.examCounterRandomOneAnswer !== 0 ||
            exam.examCounterRandomMultipleAnswers !== 0 ||
            exam.examCounterRandomDevelopAnswers !== 0 ||
            exam.examCounterRandomCasesAnswers !== 0
          ) {
            if (exam.examStatus === true) {
              this.examList.push(exam);
            }
          }
        } else if (this.examView === true) {
          if (
            exam.examCounterRandomOneAnswer !== 0 ||
            exam.examCounterRandomMultipleAnswers !== 0 ||
            exam.examCounterRandomDevelopAnswers !== 0 ||
            exam.examCounterRandomCasesAnswers !== 0
          ) {
            if (exam.examStatus === true) {
              this.examList.push(exam);
             
            }
          }
        } else {
          this.examList.push(exam);
        
        }
      });
      this.examList = this.examList.sort(this.getSortOrder("examCreationDate"));
    }
  }

  consoleData() {
    console.log(this.examList);
  }

  getSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      }
      return 0;
    }
  }

  editExam(event) {
    this.examEdit.emit({ event, readOnly: 'false' });
  }

  deleteExam(event) {
    this.examDelete.emit(event);
  }

  assignExam(event) {
    this.examAssignation.emit(event);
  }

  cleanData() {
    this.source.setFilter([]);
  }

  filterData(event) {
    let filters = []
    event.forEach(element => {
      filters.push({
        field: element.fieldname,
        search: element.value
      });
    });
    this.source.setFilter(filters)

  }


}
