import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import { KinesisVideo, KinesisVideoSignalingChannels } from 'aws-sdk';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { NbDialogRef } from '@nebular/theme';
const uuid = require('uuid');

const kinesisVideoClient = new KinesisVideo({
  region: environment.region,
  accessKeyId: environment.accessKeyId,
  secretAccessKey: environment.secretAccessKey,
  correctClockSkew: true,
});

@Component({
  selector: 'app-report-exam-channel-viewer',
  templateUrl: './report-exam-channel-viewer.component.html',
  styleUrls: ['./report-exam-channel-viewer.component.scss']
})
export class ReportExamChannelViewerComponent implements OnInit {
  loading;
  @Input() channel = {} as {
    channelName
  };

  @ViewChild('videoViewer', { static: false }) videoViewer: ElementRef;

  signalingClient;
  peerConnection;

  constructor(
    private dialogRef: NbDialogRef<ReportExamChannelViewerComponent>,
  ) { }

  ngOnInit() {
    this.startViewer();
  }

  async startViewer(): Promise<KinesisVideo.ResourceEndpointListItem> {
    this.loading = true;
    const user: User = JSON.parse(localStorage.getItem('user'));
    const channelName = this.channel.channelName;
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;

    var clientId = uuid.v4();

    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: Role.VIEWER,
        },
      })
      .promise();

    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      return endpoints;
    }, {});


    const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
      region: environment.region,
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      endpoint: endpointsByProtocol["HTTPS"],
      correctClockSkew: true,
    });

    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    var iceServers = [
    ];
    getIceServerConfigResponse.IceServerList.forEach(iceServer =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      }),
    );

    iceServers.push({ urls: `stun:stun.kinesisvideo.${environment.region}.amazonaws.com:443` })

    this.peerConnection = new RTCPeerConnection({ iceServers });

    this.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol["WSS"],
      clientId,
      role: Role.VIEWER,
      region: environment.region,
      credentials: {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const remoteView = this.videoViewer.nativeElement;

    this.signalingClient.on('open', async () => {

      const offer = await this.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });
      await this.peerConnection.setLocalDescription(offer);
      this.signalingClient.sendSdpOffer(this.peerConnection.localDescription);

    });

    this.signalingClient.on('sdpAnswer', async answer => {
      await this.peerConnection.setRemoteDescription(answer);
    });

    this.signalingClient.on('iceCandidate', candidate => {
      this.peerConnection.addIceCandidate(candidate);
    });

    this.signalingClient.on('close', () => {
    });

    this.signalingClient.on('error', error => {
    });

    this.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
      if (candidate) {
        this.signalingClient.sendIceCandidate(candidate);
      } else {
      }
    });

    this.peerConnection.addEventListener('track', event => {
      if (remoteView.srcObject) {
        return;
      }
      this.loading = false;
      remoteView.srcObject = event.streams[0];
    });

    this.signalingClient.open();

    return endpointsByProtocol;
  }

  async stopViewer() {
    if (this.signalingClient) {
      this.signalingClient.close();
      this.signalingClient = null;
    }

    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }

    if (this.videoViewer.nativeElement) {
      this.videoViewer.nativeElement.srcObject = null;
    }
    this.dialogRef.close();
  }

}
