import { Component, OnInit } from '@angular/core';
import examCheckTableSettings from '../../../../assets/config/examTable/examCheckTableSettings.json';
import { Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import { ExamService } from 'src/app/services/exam.service';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company.service';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-exam-check',
  templateUrl: './exam-check.component.html',
  styleUrls: ['./exam-check.component.scss']
})
export class ExamCheckComponent implements OnInit {
  loading: boolean;
  userExamList: any;
  // items: TreeviewItem[];
  // values: [];
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 1000
  // });

  settings = examCheckTableSettings;
  checkList = new LocalDataSource();
  examNameArr = new Array();
  examName;
  assignDateArr = new Array();
  examDate;

  constructor(
    private router: Router,
    private toastrService: NbToastrService,
    private userService: UserService,
    private examService: ExamService,
    private dialogService: NbDialogService,
    private companyService: CompanyService,
  ) { }

  async ngOnInit() {
    await this.reloadEndExams();
  }

  changeName(event) {
    console.log(event);
    const arr = this.userExamList.filter(x => x.examNameShow == event);
    this.checkList = new LocalDataSource(arr);
    this.examDate = ''
  }

  changeDate(event) {
    //const arr = this.userExamList.filter(x => x.examDateAssignShow == event);
    const arr = this.userExamList.filter(function(element){
      let date = element.examDateAssignShow.split(',')
      if(date[0]== event){
        return element;
      }
    });
    this.checkList = new LocalDataSource(arr);
    this.examName =''
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        const company = result.body;
        return company;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async reloadEndExams() {
    const userCognito = await this.getUserCognito();
    const userExamBackend = await this.getUserExamsBackend();
    const examBackend = await this.getExams();
    const userCompleteData = await this.combineUser(userCognito, userExamBackend, examBackend);

    console.log(userCompleteData);
    // const userLogged: User = JSON.parse(localStorage.getItem('user'));
    // const company = await this.getCompany(userLogged.company.companyCode);
    // const groupsList = JSON.parse(company.companyApiFields);
    // const treeMode = await this.treeMode(userCompleteData, groupsList);
    // this.items = treeMode;
    this.userExamList = userCompleteData;
    this.userExamList.forEach(assign => {
      const examFind = this.examNameArr.find(x => x.examNameShow == assign.examNameShow);
      if (examFind == undefined) {
        this.examNameArr.push({ examNameShow: assign.examNameShow });
      }
      const dateFind = this.assignDateArr.find(x => x.examDateAssignShow == assign.examDateAssignShow);
      if (dateFind == undefined) {
         let date_only = assign.examDateAssignShow.split(',');
        
          let dateFind2 = this.assignDateArr.find(x => x.examDateAssignShow == date_only[0]);
          if(dateFind2 == undefined){
            this.assignDateArr.push({ examDateAssignShow: date_only[0] });
          }
        
        //this.assignDateArr.push({ examDateAssignShow: assign.examDateAssignShow });
        
      }
     
    });
    //console.log(this.assignDateArr);
    // this.checkList = new LocalDataSource(this.userExamList);
  }

  // treeMode(users, params): TreeviewItem[] {
  //   let items = new Array<TreeviewItem>();
  //   const orderedApiFields = this.getOrderedFields(params);
  //   const orderedUsers = this.getOrderedUsers(users, orderedApiFields);
  //   orderedUsers.forEach(item => {
  //     if (item.value !== undefined) {
  //       items.push(new TreeviewItem(item));
  //     }
  //   });
  //   return items;
  // }

  // getOrderedUsers(users, orderedApiFields) {
  //   let orderedUsers = {}
  //   let keysAndSubkeys = [];
  //   let formedJson = [];

  //   users.forEach(completeUser => {
  //     if (completeUser.clientGroups !== undefined) {
  //       const user = JSON.parse(completeUser.clientGroups);
  //       let prevKeys = [];
  //       let keysAndSubkeysIndexes = [];
  //       let prevJson = {};

  //       for (let k = 0; k <= orderedApiFields.length; k++) {
  //         let currentApiField = "";
  //         let prevObj = {};
  //         if (k < orderedApiFields.length) {
  //           currentApiField = orderedApiFields[k].value;
  //           if (k == 0) {
  //             if (orderedUsers[user[currentApiField]] == undefined) {
  //               orderedUsers[user[currentApiField]] = {};
  //             }
  //             prevKeys.push(user[currentApiField]);

  //             if (keysAndSubkeys[0] == undefined) {
  //               keysAndSubkeys[0] = [user[currentApiField]]
  //               keysAndSubkeysIndexes.push(0);
  //               formedJson.push(
  //                 {
  //                   text: user[currentApiField],
  //                   value: user[currentApiField],
  //                   collapsed: true,
  //                   children: []
  //                 }
  //               )
  //             }
  //             else if (keysAndSubkeys[0].indexOf(user[currentApiField]) == -1) {
  //               keysAndSubkeys[0].push(user[currentApiField])
  //               keysAndSubkeysIndexes.push(keysAndSubkeys[0].length - 1);
  //               formedJson.push(
  //                 {
  //                   text: user[currentApiField],
  //                   value: user[currentApiField],
  //                   collapsed: true,
  //                   children: []
  //                 }
  //               )

  //             }
  //             else {
  //               let currentIndex = keysAndSubkeys[0].indexOf(user[currentApiField]);
  //               keysAndSubkeysIndexes.push(currentIndex);
  //             }

  //             prevJson = formedJson;
  //           }
  //           else {
  //             for (let j = 0; j < prevKeys.length; j++) {
  //               if (j == 0) {
  //                 prevObj = orderedUsers[prevKeys[j]];
  //               }
  //               else {
  //                 prevObj = prevObj[prevKeys[j]]
  //               }
  //             }
  //             if (prevObj[user[currentApiField]] == undefined) {
  //               prevObj[user[currentApiField]] = {}
  //               if (keysAndSubkeys[k] == undefined) {
  //                 keysAndSubkeys[k] = [user[currentApiField]]
  //                 keysAndSubkeysIndexes.push(0);
  //               }
  //               else {
  //                 let index = keysAndSubkeys[k].indexOf(user[currentApiField]);
  //                 if (index == -1) {
  //                   keysAndSubkeys[k].push(user[currentApiField])
  //                   keysAndSubkeysIndexes.push(keysAndSubkeys.length - 1);
  //                 }
  //                 else {

  //                   keysAndSubkeysIndexes.push(index);
  //                 }
  //               }
  //               let tempJsonChildren = {
  //                 text: user[currentApiField],
  //                 value: user[currentApiField],
  //                 children: []
  //               }
  //               if (prevJson[keysAndSubkeysIndexes[k - 1]].children == undefined) {
  //                 prevJson[keysAndSubkeysIndexes[k - 1]].children = [tempJsonChildren];
  //               }
  //               else {
  //                 prevJson[keysAndSubkeysIndexes[k - 1]].children.push(tempJsonChildren);
  //               }
  //               keysAndSubkeys[k] = Object.keys(prevObj)
  //               keysAndSubkeysIndexes[k] = (keysAndSubkeys[k].indexOf(user[currentApiField]));
  //               prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
  //             }
  //             else {
  //               if (keysAndSubkeys[k].indexOf(user[currentApiField]) == -1) {
  //                 keysAndSubkeys[k].push(user[currentApiField])
  //                 keysAndSubkeysIndexes.push(keysAndSubkeys.length - 1);
  //                 prevJson[keysAndSubkeysIndexes[k - 1]].children.push(
  //                   {
  //                     text: user[currentApiField],
  //                     value: user[currentApiField],
  //                     children: []
  //                   }
  //                 )
  //                 prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
  //               }
  //               else {
  //                 let currentIndex = keysAndSubkeys[k].indexOf(user[currentApiField]);
  //                 keysAndSubkeysIndexes.push(currentIndex);
  //                 prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
  //               }
  //             }
  //             prevKeys.push(user[currentApiField]);
  //           }
  //         }
  //         else {
  //           if (orderedUsers[user[currentApiField]] == undefined) {
  //             if (k == (orderedApiFields.length)) {
  //               if (prevJson[keysAndSubkeysIndexes[k - 1]].children == undefined) {
  //                 prevJson[keysAndSubkeysIndexes[k - 1]].children = [{ text: completeUser.userName, value: completeUser.userCode, checked: true, }]
  //               }
  //               else {
  //                 prevJson[keysAndSubkeysIndexes[k - 1]].children.push({ text: completeUser.userName, value: completeUser.userCode, checked: true, })
  //               }
  //             }
  //           }
  //           else {
  //             if (k == (orderedApiFields.length - 1)) {
  //               prevObj[user[currentApiField]].users.push(user);
  //               prevJson[keysAndSubkeysIndexes[k - 1]].children[keysAndSubkeysIndexes[k]].push(
  //                 { text: completeUser.userName, value: completeUser.userCode, checked: true, }
  //               );
  //             }
  //           }
  //         }
  //       }
  //     }
  //   });
  //   return formedJson;
  // }

  // getOrderedFields(companyApiFields) {
  //   const companyApiFieldsWithParent = [];
  //   let biggestDaddy = undefined;
  //   const orderedApiFields = [];

  //   companyApiFields.forEach((companyApiField, index) => {
  //     if (companyApiField.parent === undefined) {
  //       biggestDaddy = companyApiField;
  //       orderedApiFields.push(companyApiField);
  //     }
  //     else {
  //       companyApiFieldsWithParent.push(companyApiField);
  //     }
  //   });

  //   let lookingForParentAndChild = true;
  //   let currentDaddy = biggestDaddy;
  //   while (lookingForParentAndChild === true) {
  //     let daddysFirstChild = this.getFirstChild(currentDaddy, companyApiFieldsWithParent);
  //     orderedApiFields.push(daddysFirstChild.firstChild);
  //     companyApiFieldsWithParent.splice(daddysFirstChild.index, 1);
  //     currentDaddy = daddysFirstChild.firstChild;

  //     if (companyApiFieldsWithParent.length === 0) {
  //       lookingForParentAndChild = false;
  //     }
  //   }

  //   return orderedApiFields;
  // }

  // getFirstChild(daddy, childs) {
  //   let firstChild = undefined;
  //   let index = 0;
  //   for (let i = 0; i < childs.length; i++) {
  //     let parent = childs[i].parent;
  //     if (parent === daddy.value) {
  //       firstChild = childs[i];
  //       index = i;
  //       break;
  //     }
  //   }

  //   return { firstChild, index }
  // }

  getExams() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    return this.examService.allExamCompanyBackend(userLogged.company.companyCode).then(
      (result) => {
        console.log(result.data)
        const exams = result.body
        return exams;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getTimeOnly(d) {
    const x = document.getElementById('demo');
    const h = this.addZero(d.getHours());
    const m = this.addZero(d.getMinutes());
    const s = this.addZero(d.getSeconds());
    return h + ':' + m;
  }

  addZero(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  async combineUser(userCognito, userExamBackend, examBackend) {
    return await new Promise(async (resolve, reject) => {
      const userCompleteData = new Array<any>();
      const userLogged: User = JSON.parse(localStorage.getItem('user'));
      await userCognito.forEach(async (cognitoUser, index, array) => {
        const name = cognitoUser.Attributes.find((x) => x.Name === 'name');
        const email = cognitoUser.Attributes.find((x) => x.Name === 'email');
        const company = cognitoUser.Attributes.find((x) => x.Name === 'custom:company');
        let clientGroups = cognitoUser.Attributes.find((x) => x.Name === 'custom:clientGroups');
        if (clientGroups === undefined) {
          clientGroups = { Value: '' };
        }
        const userExam = userExamBackend.find((x) => x.userCode === cognitoUser.Username);
        if (company.Value === userLogged.company.companyCode) {
          if (userExam !== undefined) {
            if (userExam.userAssign !== undefined) {
              // const userAssignation = await this.getUserAssignAll(cognitoUser.Username);
              await userExam.userAssign.forEach(async (userAssign) => {
                const exam = examBackend.find((x) => x.examCode === userAssign.userExamCode);
                // const assign = await this.getAssign(userAssign.userStreamCode);
                const assign = userAssign;
                let correctAnswer = 0;
                let incorrectAnswer = 0;
                let noResponseAnswer = 0;
                let themeAnswer = 0;
                if (
                  (assign.userExam !== undefined && assign.userExam !== null) && exam !== undefined &&
                  (assign.userExamResults !== undefined && assign.userExamResults !== null)
                ) {
                  await assign.userExamResults.forEach(async (questionResolve) => {
                    questionResolve.isCorrect = false;
                    const question = assign.userExam.find((x) => x.examQuestionCode === questionResolve.examQuestionCode);
                    if (questionResolve.examQuestionType === 'oneAnswer') {
                      let correctAnswerOne;
                      question.examQuestionAnswer.forEach(answer => {
                        if (answer.examQuestionAnswerResponseIsCorrect === true) {
                          correctAnswerOne = answer.examQuestionAnswerResponse;
                        }
                      });
                      let userCorrectAnswerOne = questionResolve.examQuestionAnswerOne
                      if (userCorrectAnswerOne !== undefined) {
                        if (correctAnswerOne === userCorrectAnswerOne) {
                          correctAnswer++;
                          questionResolve.isCorrect = true;
                        } else {
                          incorrectAnswer++;
                        }
                      } else {
                        noResponseAnswer++;
                      }
                    }
                    if (questionResolve.examQuestionType === 'multipleAnswer') {
                      let isCorrect = true;
                      let isResolve = false;
                      questionResolve.examQuestionAnswer.forEach(async (userAnswer) => {
                        if (userAnswer.examQuestionAnswerResponseIsCorrect === true) {
                          isResolve = true;
                        }
                      });
                      if (isResolve) {
                        questionResolve.examQuestionAnswer.forEach(async (userAnswer) => {
                          question.examQuestionAnswer.forEach(async (answer) => {
                            if (userAnswer.examQuestionAnswerCode === answer.examQuestionAnswerCode) {
                              if (userAnswer.examQuestionAnswerResponseIsCorrect !== answer.examQuestionAnswerResponseIsCorrect) {
                                isCorrect = false;
                              }
                            }
                          });
                        });
                        if (isCorrect) {
                          correctAnswer++;
                          questionResolve.isCorrect = true;
                        } else {
                          incorrectAnswer++;
                        }
                      } else {
                        noResponseAnswer++;
                      }
                    }
                    if (questionResolve.examQuestionType === 'themeDevelopAnswer') {
                      themeAnswer++;
                    }
                  });
                  let showInList: boolean = false;
                  let hasFinish: any = assign.userExamEnd === undefined ? 'en curso' : new Date(assign.userExamEnd).toLocaleString('es-GT');
                  if (hasFinish === 'en curso') {
                    const endDate = await this.checkEnd(assign, exam);
                    const currentDateTime = new Date();
                    if (currentDateTime > endDate) {
                      assign.userExamEnd = new Date(endDate);
                      showInList = true;
                    }

                  }
                  if (hasFinish !== 'en curso') { showInList = true; }
                  if (assign.userExamQualified) { showInList = false; }
                  // console.log(exam.examName, name.Value, assign)
                  if (showInList === true) {
                    if (assign.userExamScore === undefined) {
                      userCompleteData.push({
                        userCode: cognitoUser.Username,
                        userNameShow: name.Value,
                        userName: name.Value,
                        userEmail: email.Value,
                        examStart: new Date(assign.userExamStart).toLocaleString('es-GT'),
                        examEnd: assign.userExamEnd === undefined ? 'en curso' : new Date(assign.userExamEnd).toLocaleString('es-GT'),
                        examStreamCode: assign.userStreamCode,
                        examCode: exam.examCode,
                        examTime: exam.examTime,
                        examQualified: assign.userExamQualified,
                        examNameShow: exam.examName,
                        examDateAssignShow: new Date(assign.userExamStartDateTime).toLocaleString('es-GT'),
                        examDateStartShow: new Date(assign.userExamStart).toLocaleString('es-GT'),
                        examDateEndShow: new Date(assign.userExamEnd).toLocaleString('es-GT'),
                        userResponses: assign.userExamResults,
                        userAssignExam: assign.userExam,
                        examCorrectAnswers: correctAnswer,
                        examIncorrectAnswers: incorrectAnswer,
                        examNoResponse: noResponseAnswer,
                        examThemeQuestions: themeAnswer,
                        clientGroups: clientGroups.Value === '' ? undefined : clientGroups.Value,
                      });
                    }
                  }
                }
              });
            }
          }
        }
        if (index === array.length - 1) {
          // this.checkList = new LocalDataSource(userCompleteData);
          resolve(userCompleteData);
        }
      });
      // this.checkList = new LocalDataSource(userCompleteData);
      // console.log(userCompleteData);
      resolve(userCompleteData);
    });
  }

  checkEnd(assign, exam) {
    const userAssign = assign;
    const startDate = new Date(userAssign.userExamStartDateTime);
    const endDate = new Date(startDate);
    endDate.setMinutes(endDate.getMinutes() + parseInt(exam.examTime, 0));
    return endDate;
  }

  getUserCognito() {
    this.loading = true;
    return this.userService.allUserCognito().then(
      (result) => {
        const userCognito = result.Users
        return userCognito;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getUserExamsBackend() {
    this.loading = true;
    return this.userService.allUserExamBackend().then(
      (result) => {
        console.log(result);
        const userExamBackend = result.body
        return userExamBackend;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  // getUserAssignAll(userCode) {
  //   this.loading = true;
  //   return this.userService.allUserAssignBackend(userCode).then(
  //     (result) => {
  //       const userExamBackend = result.body
  //       return userExamBackend;
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show(
  //         '',
  //         err.message,
  //         { position, status });
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  // getAssign(streamCode) {
  //   this.loading = true;
  //   return this.userService.oneUserAssignBackend(streamCode).then(
  //     (result) => {
  //       const userExamBackend = result.body
  //       return userExamBackend;
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show(
  //         '',
  //         err.message,
  //         { position, status });
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  scoreVerification(event) {
    const examCode = event.data.examCode;
    const userCode = event.data.userCode;
    const streamCode = event.data.examStreamCode;
    this.router.navigate(['/examCheckValidation/' + examCode + '/' + userCode + '/' + streamCode]);
  }

  changeTreeview(event: []) {
    const tempUserExamList = new Array<any>();
    this.userExamList.forEach((user, index) => {
      const find = event.find(x => x === user.userCode);
      if (find !== undefined) {
        if (user.userCode === find) {
          tempUserExamList.push(user);
        }
      }
      if (user.clientGroups === undefined) {
        tempUserExamList.push(user);
      }
    });
    this.checkList = new LocalDataSource(tempUserExamList);
  }

}
