import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { StreamingService } from 'src/app/services/streaming.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-exam-verification',
  templateUrl: './exam-verification.component.html',
  styleUrls: ['./exam-verification.component.scss']
})
export class ExamVerificationComponent implements OnInit {
  @Input() streamCode;
  @Input() results;
  stream;
  video;

  @ViewChild('video', { static: false }) videoPlayer: ElementRef;

  constructor(
    private streamingService: StreamingService,
    private dialogRef: NbDialogRef<ExamVerificationComponent>
  ) { }

  ngOnInit() {
    this.getStream();
  }

  getStream() {
    this.streamingService.oneStreamBackend(this.streamCode).then(
      (oneStream) => {
        this.stream = oneStream.body;
        this.videoPlayer.nativeElement.src = environment.cloudFrontUrl + this.stream.videoKey;
      }
    ).catch();
  }

  close() {
    this.dialogRef.close();
  }
}
