import { Component, OnInit } from '@angular/core';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService, NbDialogService } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import { ExamService } from 'src/app/services/exam.service';
import { ActivatedRoute } from '@angular/router';
import reportsUserCheckResultsTableSettings from '../../../../assets/config/reportsTable/reportsUserCheckResultsTableSettings.json';
import { User } from 'src/app/models/user';
import { UserAssign } from 'src/app/models/userAssign';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { LocalDataSource } from 'ng2-smart-table';
import { CompanyService } from 'src/app/services/company.service';
import * as xlsx from 'xlsx';

import { reportCheckExamsTableSettings  } from "../../../shared/TableSettings/reportsTable/reportCheckExamTableSettings";
import reportFilterSettings from '../../../../assets/config/filterTable/reports/reportsCheckExamsTableFilters.json';

@Component({
  selector: 'app-report-check-exams',
  templateUrl: './report-check-exams.component.html',
  styleUrls: ['./report-check-exams.component.scss']
})
export class ReportCheckExamsComponent implements OnInit {
  loading: boolean;
  userExamList = new Array<any>();
  items: TreeviewItem[];
  values: [];
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000
  });

  settings = reportCheckExamsTableSettings;
  reportFSettings = reportFilterSettings;

  checkList = new LocalDataSource(this.userExamList);

  constructor(
    private userService: UserService,
    private examService: ExamService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
  ) { }

  async ngOnInit() {
    await this.reloadReport();
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        const company = result.body;
        return company;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async reloadReport() {
    const userCognito = await this.getUserCognito();
    const userExamBackend = await this.getUserExamsBackend();
    const examBackend = await this.getExams();
    const userCompleteData = await this.combineUser(userCognito, userExamBackend, examBackend);
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const company = await this.getCompany(userLogged.company.companyCode);
    // const groupsList = JSON.parse(company.companyApiFields);
    // const treeMode = await this.treeMode(userCompleteData, groupsList);
    // this.items = treeMode;
    this.userExamList = userCompleteData;
    this.checkList = new LocalDataSource(this.userExamList);
  }

  cleanData() {
    this.checkList.setFilter([]);
  }

  filterData(event) {
    let filters = []
    event.forEach(element => {
      filters.push({
        field: element.fieldname,
        search: element.value
      });
    });
    this.checkList.setFilter(filters)

  }

  treeMode(users, params): TreeviewItem[] {
    let items = new Array<TreeviewItem>();
    const orderedApiFields = this.getOrderedFields(params);
    const orderedUsers = this.getOrderedUsers(users, orderedApiFields);
    orderedUsers.forEach(item => {
      items.push(new TreeviewItem(item));
    });
    return items;
  }

  getOrderedUsers(users, orderedApiFields) {
    let orderedUsers = {}
    let keysAndSubkeys = [];
    let formedJson = [];

    users.forEach(completeUser => {
      if (completeUser.clientGroups !== undefined) {
        const user = JSON.parse(completeUser.clientGroups);
        let prevKeys = [];
        let keysAndSubkeysIndexes = [];
        let prevJson = {};

        for (let k = 0; k <= orderedApiFields.length; k++) {
          let currentApiField = "";
          let prevObj = {};
          if (k < orderedApiFields.length) {
            currentApiField = orderedApiFields[k].value;
            if (k == 0) {
              if (orderedUsers[user[currentApiField]] == undefined) {
                orderedUsers[user[currentApiField]] = {};
              }
              prevKeys.push(user[currentApiField]);

              if (keysAndSubkeys[0] == undefined) {
                keysAndSubkeys[0] = [user[currentApiField]]
                keysAndSubkeysIndexes.push(0);
                formedJson.push(
                  {
                    text: user[currentApiField],
                    value: user[currentApiField],
                    collapsed: true,
                    children: []
                  }
                )
              }
              else if (keysAndSubkeys[0].indexOf(user[currentApiField]) == -1) {
                keysAndSubkeys[0].push(user[currentApiField])
                keysAndSubkeysIndexes.push(keysAndSubkeys[0].length - 1);
                formedJson.push(
                  {
                    text: user[currentApiField],
                    value: user[currentApiField],
                    collapsed: true,
                    children: []
                  }
                )

              }
              else {
                let currentIndex = keysAndSubkeys[0].indexOf(user[currentApiField]);
                keysAndSubkeysIndexes.push(currentIndex);
              }

              prevJson = formedJson;
            }
            else {
              for (let j = 0; j < prevKeys.length; j++) {
                if (j == 0) {
                  prevObj = orderedUsers[prevKeys[j]];
                }
                else {
                  prevObj = prevObj[prevKeys[j]]
                }
              }
              if (prevObj[user[currentApiField]] == undefined) {
                prevObj[user[currentApiField]] = {}
                if (keysAndSubkeys[k] == undefined) {
                  keysAndSubkeys[k] = [user[currentApiField]]
                  keysAndSubkeysIndexes.push(0);
                }
                else {
                  let index = keysAndSubkeys[k].indexOf(user[currentApiField]);
                  if (index == -1) {
                    keysAndSubkeys[k].push(user[currentApiField])
                    keysAndSubkeysIndexes.push(keysAndSubkeys.length - 1);
                  }
                  else {

                    keysAndSubkeysIndexes.push(index);
                  }
                }
                let tempJsonChildren = {
                  text: user[currentApiField],
                  value: user[currentApiField],
                  children: []
                }
                if (prevJson[keysAndSubkeysIndexes[k - 1]].children == undefined) {
                  prevJson[keysAndSubkeysIndexes[k - 1]].children = [tempJsonChildren];
                }
                else {
                  prevJson[keysAndSubkeysIndexes[k - 1]].children.push(tempJsonChildren);
                }
                keysAndSubkeys[k] = Object.keys(prevObj)
                keysAndSubkeysIndexes[k] = (keysAndSubkeys[k].indexOf(user[currentApiField]));
                prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
              }
              else {
                if (keysAndSubkeys[k].indexOf(user[currentApiField]) == -1) {
                  keysAndSubkeys[k].push(user[currentApiField])
                  keysAndSubkeysIndexes.push(keysAndSubkeys.length - 1);
                  prevJson[keysAndSubkeysIndexes[k - 1]].children.push(
                    {
                      text: user[currentApiField],
                      value: user[currentApiField],
                      children: []
                    }
                  )
                  prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
                }
                else {
                  let currentIndex = keysAndSubkeys[k].indexOf(user[currentApiField]);
                  keysAndSubkeysIndexes.push(currentIndex);
                  prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
                }
              }
              prevKeys.push(user[currentApiField]);
            }
          }
          else {
            if (orderedUsers[user[currentApiField]] == undefined) {
              if (k == (orderedApiFields.length)) {
                if (prevJson[keysAndSubkeysIndexes[k - 1]] !== undefined) {
                  if (prevJson[keysAndSubkeysIndexes[k - 1]].children == undefined) {
                    prevJson[keysAndSubkeysIndexes[k - 1]].children = [{ text: completeUser.userName, value: completeUser.userCode, checked: true, }]
                  }
                  else {
                    prevJson[keysAndSubkeysIndexes[k - 1]].children.push({ text: completeUser.userName, value: completeUser.userCode, checked: true, })
                  }
                }
              }
            }
            else {
              if (k == (orderedApiFields.length - 1)) {
                prevObj[user[currentApiField]].users.push(user);
                prevJson[keysAndSubkeysIndexes[k - 1]].children[keysAndSubkeysIndexes[k]].push(
                  { text: completeUser.userName, value: completeUser.userCode, checked: true, }
                );
              }
            }
          }
        }
      }
    });
    return formedJson;
  }

  getOrderedFields(companyApiFields) {
    const companyApiFieldsWithParent = [];
    let biggestDaddy = undefined;
    const orderedApiFields = [];

    companyApiFields.forEach((companyApiField, index) => {
      if (companyApiField.parent === undefined) {
        biggestDaddy = companyApiField;
        orderedApiFields.push(companyApiField);
      }
      else {
        companyApiFieldsWithParent.push(companyApiField);
      }
    });
    let lookingForParentAndChild = true;
    let currentDaddy = biggestDaddy;
    while (lookingForParentAndChild === true) {
      let daddysFirstChild = this.getFirstChild(currentDaddy, companyApiFieldsWithParent);
      orderedApiFields.push(daddysFirstChild.firstChild);
      companyApiFieldsWithParent.splice(daddysFirstChild.index, 1);
      currentDaddy = daddysFirstChild.firstChild;

      if (companyApiFieldsWithParent.length === 0) {
        lookingForParentAndChild = false;
      }
    }
    return orderedApiFields;
  }

  getFirstChild(daddy, childs) {
    let firstChild = undefined;
    let index = 0;
    for (let i = 0; i < childs.length; i++) {
      let parent = childs[i].parent;
      if (parent === daddy.value) {
        firstChild = childs[i];
        index = i;
        break;
      }
    }

    return { firstChild, index }
  }

  getUserExamsBackend() {
    this.loading = true;
    return this.userService.allUserExamBackend().then(
      (result) => {
        const userExamBackend = result.body
        return userExamBackend;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getUserCognito() {
    this.loading = true;
    return this.userService.allUserCognito().then(
      (result) => {
        const userCognito = result.Users
        return userCognito;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getExams() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    return this.examService.allExamCompanyBackend(userLogged.company.companyCode).then(
      (result) => {
        const exams = result.body
        return exams;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async combineUser(userCognito, userExamBackend, examBackend) {
    const userCompleteData = new Array<any>();
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    await userCognito.forEach(async (cognitoUser) => {
      const name = cognitoUser.Attributes.find((x) => x.Name === 'name');
      const email = cognitoUser.Attributes.find((x) => x.Name === 'email');
      const company = cognitoUser.Attributes.find((x) => x.Name === 'custom:company');
      let clientGroups = cognitoUser.Attributes.find((x) => x.Name === 'custom:clientGroups');
      if (clientGroups === undefined) {
        clientGroups = { Value: '' };
      }
      const userExam = userExamBackend.find((x) => x.userCode === cognitoUser.Username);
      if (company.Value === userLogged.company.companyCode) {
        if (userExam !== undefined) {
          if (userExam.userAssign !== undefined) {
            userExam.userAssign.forEach(async (userAssign) => {
              const exam = examBackend.find((x) => x.examCode === userAssign.userExamCode);
              const assign = userAssign;
              // const assign = await this.getAssign(userAssign.userStreamCode);
              if (assign.userExamScore !== undefined) {
                let scoreOneAnswer = 0;
                let scoreOneAnswerB = 0;
                let scoreMultipleAnswer = 0;
                let scoreDevelopAnswer = 0;
                let scoreCasesAnswer = 0;
                let scoreTotal = 0;

                let scoreOneAnswerOver = 0;
                let scoreOneAnswerBOver = 0;
                let scoreMultipleAnswerOver = 0;
                let scoreDevelopAnswerOver = 0;
                let scoreCasesAnswerOver = 0;
                let scoreTotalOver = 0;
                assign.userExamScore.scoresOneAnswer.forEach(async (question: any) => {
                  scoreOneAnswer = scoreOneAnswer + question.score;
                  scoreOneAnswerOver = scoreOneAnswerOver + question.eachQuestionValue;
                });
                assign.userExamScore.scoresOneAnswerB.forEach(async (question: any) => {
                  scoreOneAnswerB = scoreOneAnswerB + question.score;
                  scoreOneAnswerBOver = scoreOneAnswerBOver + question.eachQuestionValue;
                });
                assign.userExamScore.scoresMultipleAnswer.forEach(async (question) => {
                  scoreMultipleAnswer = scoreMultipleAnswer + question.score;
                  scoreMultipleAnswerOver = scoreMultipleAnswerOver + question.eachQuestionValue;
                });
                assign.userExamScore.scoresDevelopAnswer.forEach(async (question) => {
                  scoreDevelopAnswer = scoreDevelopAnswer + parseFloat(question.score);
                  if (question.checked === true) {
                    scoreDevelopAnswerOver = scoreDevelopAnswerOver + parseFloat(question.eachQuestionValue);
                  }
                });
                assign.userExamScore.scoresCasesAnswer.forEach(async (question) => {
                  scoreCasesAnswer = scoreCasesAnswer + parseFloat(question.score);
                  scoreCasesAnswerOver = scoreCasesAnswerOver + parseFloat(question.eachQuestionValue);
                });
                scoreTotal = scoreOneAnswer + scoreOneAnswerB + scoreMultipleAnswer + scoreDevelopAnswer + scoreCasesAnswer;
                scoreTotalOver = scoreOneAnswerOver + scoreOneAnswerBOver + scoreMultipleAnswerOver + scoreDevelopAnswerOver + scoreCasesAnswerOver;

                try {
                  userCompleteData.push({
                    userCode: cognitoUser.Username,
                    userName: name.Value,
                    userEmail: email.Value,
                    examStartDateTime: new Date(assign.userExamStartDateTime).toLocaleString('es-GT'),
                    examStart: new Date(assign.userExamStart).toLocaleString('es-GT'),
                    examEnd: assign.userExamEnd === undefined ? 'en curso' : new Date(assign.userExamEnd).toLocaleString('es-GT'),
                    examName: exam.examName,
                    examScoreOneAnswer: scoreOneAnswer + ' / ' + scoreOneAnswerOver,
                    examScoreOneAnswerB: scoreOneAnswerB + ' / ' + scoreOneAnswerBOver,
                    examScoreMultipleAnswer: scoreMultipleAnswer + ' / ' + scoreMultipleAnswerOver,
                    examScoreDevelopAnswer: scoreDevelopAnswer + ' / ' + scoreDevelopAnswerOver,
                    examScoreCasesAnswer: scoreCasesAnswer + ' / ' + scoreCasesAnswerOver,
                    examScore: scoreTotal + ' / ' + scoreTotalOver,
                    clientGroups: clientGroups.Value === '' ? undefined : clientGroups.Value,
                  });
                } catch (e) {

                }

              }
            });
          }
        }
      }
    });
    console.log(userCompleteData);
    return userCompleteData;
  }

  // getAssign(streamCode) {
  //   this.loading = true;
  //   return this.userService.oneUserAssignBackend(streamCode).then(
  //     (result) => {
  //       const userExamBackend = result.body
  //       return userExamBackend;
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show(
  //         '',
  //         err.message,
  //         { position, status });
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  changeTreeview(event: []) {
    const tempUserExamList = new Array<any>();
    this.userExamList.forEach((user, index) => {
      const find = event.find(x => x === user.userCode);
      if (find !== undefined) {
        if (user.userCode === find) {
          tempUserExamList.push(user);
        }
      }
      if (user.clientGroups === undefined) {
        tempUserExamList.push(user);
      }
    });
    this.checkList = new LocalDataSource(tempUserExamList);
  }

  async downloadToExcel() {
    const checkList = await this.checkList.getFilteredAndSorted()
    const exportFile = new Array<any>();
    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        json[this.settings.columns[item].title] = element[item];
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'resultados_calificados.xlsx');
  }

}
