import { SimpleTimeComponent } from "../../simple-time/simple-time.component";

export const reportCheckExamsTableSettings = {
  hideSubHeader: false,
  mode: "external",
  actions: {
    edit: false,
    delete: false,
    add: false,
    columnTitle: "",
    position: "left",
    class: "action-colum"
  },
  edit: {
    editButtonContent: "<i class='fa fa-eye' title='Ver'></i>"
  },
  delete: {
    deleteButtonContent: "<i class='nb-trash' title='Eliminar'></i>"
  },
  columns: {
    examName: {
      title: "Nombre examen",
      type: "string"
    },
    userName: {
      title: "Nombre estudiante",
      type: "string"
    },
    examStartDateTime: {
      title: "Hora asignada",
      type: "custom",
      renderComponent: SimpleTimeComponent,
    },
    examStart: {
      title: "Hora inicio",
      type: "custom",
      renderComponent: SimpleTimeComponent,
    },
    examEnd: {
      title: "Hora fin",
      type: "custom",
      renderComponent: SimpleTimeComponent,
    },
    examScoreOneAnswer: {
      title: "Puntaje opción múltiple con una respuesta",
      type: "string"
    },
    examScoreOneAnswerB: {
      title: "Puntaje opción Verdadero y Falso",
      type: "string"
    },
    examScoreMultipleAnswer: {
      title: "Puntaje opción múltiple",
      type: "string"
    },
    examScoreDevelopAnswer: {
      title: "Puntaje Desarrollo de tema",
      type: "string"
    },
    examScoreCasesAnswer: {
      title: "Puntaje Casos",
      type: "string"
    },
    examScore: {
      title: "Puntaje",
      type: "string"
    }
  },
  pager: {
    perPage: 25
  },
  attr: {
    class: "table table-bordered"
  },
  noDataMessage: "No hay resultados."
}
