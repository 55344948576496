import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { Router } from '@angular/router';
import roleListJson from '../../../../assets/data/userRole/userRole.json';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-user-maintenance-fields-info',
  templateUrl: './user-maintenance-fields-info.component.html',
  styleUrls: ['./user-maintenance-fields-info.component.scss']
})
export class UserMaintenanceFieldsInfoComponent implements OnInit, OnChanges {
  @Input() user = {} as User;
  loading = false;
  statusAlert = 'danger';
  messageAlert = '';
  roleList = new Array<any>();
  groupList = new Array<any>();

  @ViewChild('selectGroup', { static: false }) selectGroup;
  @ViewChild('selectRole', { static: false }) selectRole;
  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private toastrService: NbToastrService,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.getAllGroups();
    await this.getAllRoles();
  }

  getAllRoles() {
    this.loading = true;
    this.roleList = new Array<any>();
    roleListJson.forEach(role => {
      this.roleList.push(role);
    });
    if (this.user.userRole !== undefined) {
      if (this.user.userRole.toString() === 'administrator') { this.roleList.push({ type: 'Administrador', value: 'administrator' }); }
      const currentRole = this.roleList.find((x) => x.value === this.user.userRole);
      this.user.userRole = currentRole;

      this.selectRole.selectValue(this.user.userRole);
    }
    this.loading = false;
  }

  getAllGroups() {
    this.loading = true;
    let user: User;
    let companyCode;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
      companyCode = user.company.companyCode;
    }
    this.groupList = new Array<any>();
    this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        const companyGroups = result.body.companyGroups;
        this.groupList.push('Sin Grupo');
        if (companyGroups !== undefined) {
          companyGroups.forEach(group => {
            this.groupList.push(group);
          });
        }
        if (this.user.userId !== undefined) {
          const currentGroup = this.groupList.find((x) => x === this.user.group);
          this.user.group = currentGroup === undefined ? 'Sin Grupo' : currentGroup;
          this.selectGroup.selectValue(this.user.group);
        }
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async ngOnChanges() {
    if (this.user.userId === undefined) { this.user.userStatus = true; }
    if (this.user.userStatus === undefined) { this.user.userStatus = true; }
    await this.getAllRoles();
  }

  closeUser() {
    this.router.navigate(['/userMaintenance/']);
  }

  async saveUser() {
    this.loading = true;

    const cognitoUser = this.getExamValues();
    if (await this.validateExamFields(cognitoUser) === true) {
      if (cognitoUser.userId === undefined) {
        const insertedUser: any = await this.insertUser(cognitoUser);
        if (this.user.userStatus === false) {
          await this.userService.disableUser(insertedUser.UserSub);
        }
        if (insertedUser !== undefined) {
          await this.insertUserBackend(cognitoUser, insertedUser);
        }
      } else {
        if (this.user.userStatus === false) {
          await this.userService.disableUser(cognitoUser.userId);
        } else {
          await this.userService.enableUser(cognitoUser.userId);
        }
        this.updateUser(cognitoUser);
      }
    } else {
      this.loading = false;
    }
  }

  validateExamFields(cognitoUser) {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(cognitoUser.userEmail) === false) {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        'Correo invalido',
        { position, status });
      return false;
    }
    return true;
  }

  getExamValues() {
    // Company code
    let user: User;
    if (localStorage.getItem('user') !== undefined) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    const companyCode = user.company.companyCode;
    const generator = require('generate-password-browser');
    const cognitoUser = {
      userId: this.user.userId,
      userName: this.user.name,
      userEmail: this.user.email,
      userRole: this.user.userRole.value,
      userGroup: this.user.group,
      userCompany: companyCode,
      userTheme: this.user.theme === undefined ? 'default' : this.user.theme,
      userPicture: this.user.email,
      userPassword: generator.generate({
        length: 8,
        numbers: true,
        symbols: true,
        lowercase: true,
        uppercase: true,
        excludeSimilarCharacters: true,
        strict: true,
      })
    };
    return cognitoUser;
  }

  insertUserBackend(cognitoUser, insertedUser) {
    this.loading = true;
    const userExam = { userCode: insertedUser.UserSub, userName: cognitoUser.userName, firstPassword: cognitoUser.userPassword };
    return this.userService.insertUserExamBackend(userExam).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show(
          '',
          'Guardado',
          { position, status });
        this.router.navigate(['/userMaintenance']);
      }
    ).catch((err) => {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        err.message,
        { position, status });
    }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  insertUser(cognitoUser) {
    this.loading = true;
    return this.userService.insertUserCognito(cognitoUser).then(
      (result) => {
        return result;
      }
    ).catch((err) => {
      if (err.message == 'Invalid version. Version should be 1') {
        err.message = 'Correo electronico ya registrado';
      }
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        err.message,
        { position, status });
    }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }
  updateUser(cognitoUser) {
    this.loading = true;
    this.userService.updateUserCognito(cognitoUser).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show(
          '',
          'Actualizado',
          { position, status });
        this.router.navigate(['/userMaintenance']);
      }
    ).catch((err) => {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        err.message,
        { position, status });
    }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

}
