// QA
/* export const environment = {
  production: true,
  userPoolId: 'us-east-1_wA8E9vGaC', 
  clientId: '5mns5ke3c2i2ka6aplrecp6auo',
  identityPoolId: 'us-east-1:b24e703d-17da-43a9-91d8-84769d309e5d',
  cognitoDomain: 'mexam-qa.auth.us-east-1.amazoncognito.com',
  cognitoRedirectSignIn: 'https://qa.myappexam.net/',
  cognitoRedirectSignOut: 'https://qa.myappexam.net/',
  cognitoResponseType: 'code',
  apiUrl: 'https://10ibjyfdtb.execute-api.us-east-1.amazonaws.com/qa/',
  apiUrlWebSocket: 'wss://a99lpdq5ab.execute-api.us-east-1.amazonaws.com/qa',
  accessKeyId: 'AKIAV42YBFE6WLEIRGSQ',
  secretAccessKey: '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',
  region: 'us-east-1',
  bucketName: 'mexam-qa-content',
  bucketNameApp: 'mexam-qa-myapp',
  regionSES: 'us-east-1',
  pipelineId: '1591316268307-tjpjsk',
  mRekognitionQueueUrl: 'https://sqs.us-east-1.amazonaws.com/405522164029/mrekognition-qa-mconvert-inputbatches.fifo',
  mRekognitionReturnQueueUrl: 'https://sqs.us-east-1.amazonaws.com/405522164029/mexam-qa-rekognition-response.fifo',
  emailUrl: 'https://myappexam.net/',
  emailImageUrl: 'https://qa.myappexam.net/assets/images/mLogo.png',
  cloudFrontUrl: 'https://dwtwae0t6uz5x.cloudfront.net/',
  functionRekognitionObject: 'mrekognitionservice-qa-inputSingleImageDetectLabels',
  functionRekognitionVideo360: 'mrekognitionservice-qa-video360Check',
  timeUploadVideo: 15000,
  rekognitionFaceIdWithDoc: 'mrekognitionservice-qa-createFaceIdCodeWithID',
  mMeetUrl: 'https://myappmeet.myappsuiteconsole.net/'
}; */



// dev 

/* export const environment = {
  production: true,
  userPoolId: 'us-east-1_Yo4zU7C6b',
  clientId: '6p150uuaq6gc51imra3k2i5pu7',
  identityPoolId: 'us-east-1:67bb743f-6e78-4165-976b-726103afb43a',
  cognitoDomain: 'mexam-sql.auth.us-east-1.amazoncognito.com',
  cognitoRedirectSignIn: 'https://d3coo3giwnzeqd.cloudfront.net/',
  cognitoRedirectSignOut: 'https://d3coo3giwnzeqd.cloudfront.net/',
  cognitoResponseType: 'code',
  apiUrl: 'https://00hna50ff3.execute-api.us-east-1.amazonaws.com/dev/',
  apiUrlWebSocket: 'wss://i81557cgzb.execute-api.us-east-1.amazonaws.com/dev',
  accessKeyId: 'AKIAV42YBFE6WLEIRGSQ',
  secretAccessKey: '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',
  region: 'us-east-1',
  bucketName: 'mexam-dev-content',
  bucketNameApp: 'mexam-dev-myapp',
  regionSES: 'us-east-1',
  pipelineId: '1591316268307-tjpjsk', 
  mRekognitionQueueUrl: 'https://sqs.us-east-1.amazonaws.com/405522164029/mrekognition-dev-mconvert-inputbatches.fifo',
  mRekognitionReturnQueueUrl: 'https://sqs.us-east-1.amazonaws.com/405522164029/mexam-dev-rekognition-response.fifo',
  emailUrl: 'https://myappexam.net/',
  emailImageUrl: 'https://dev.myappexam.net/assets/images/mLogo.png',
  cloudFrontUrl: 'https://d3coo3giwnzeqd.cloudfront.net/',
  functionRekognitionObject: 'mrekognitionservice-dev-inputSingleImageDetectLabels',
  functionRekognitionVideo360: 'mrekognitionservice-dev-video360Check',
  timeUploadVideo: 15000,
  rekognitionFaceIdWithDoc: 'mrekognitionservice-dev-createFaceIdCodeWithID',
  mMeetUrl: 'https://myappmeet.myappsuiteconsole.net/'
}; */

// ambiente utt
export const environment = {
  production: true,
  userPoolId: 'us-west-2_aE6ZthYRI', 
  clientId: '715q8frantt1hphr57omera82b',
  identityPoolId: 'us-west-2:1f3d4ceb-4b12-4970-b698-a8ce6f491889',
  cognitoDomain: 'https://mexam-utt.auth.us-west-2.amazoncognito.com',
  cognitoRedirectSignIn: 'https://d3o7z6xv29997g.cloudfront.net/', 
  cognitoRedirectSignOut: 'https://d3o7z6xv29997g.cloudfront.net/',
  cognitoResponseType: 'code',
  apiUrl: 'https://8plqgjbteh.execute-api.us-west-2.amazonaws.com/utt/',
  apiUrlWebSocket: 'wss://v7srf1l6ob.execute-api.us-west-2.amazonaws.com/utt',
  accessKeyId: 'AKIA2STYDQA7O5J4POEP',
  secretAccessKey: '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',
  region: 'us-west-2',
  bucketName: 'mexam-utt-content',
  bucketNameApp: 'mexam-utt-myapp',
  regionSES: 'us-west-2',
  pipelineId: '1591316268307-tjpjsk',
  mRekognitionQueueUrl: 'https://sqs.us-west-2.amazonaws.com/405522164029/mrekognition-utt-mconvert-inputbatches.fifo',  //preguntar
  mRekognitionReturnQueueUrl: 'https://sqs.us-west-2.amazonaws.com/727175102526/mexam-utt-rekognition-response.fifo',
  emailUrl: 'https://myappexam.net/', //preguntar
  emailImageUrl: 'https:/qa.myappexam.net/assets/images/mLogo.png', //preguntar
  cloudFrontUrl: 'https://d3o7z6xv29997g.cloudfront.net/',
  functionRekognitionObject: 'mrekognitionservice-utt-inputSingleImageDetectLabels', //preguntar
  functionRekognitionVideo360: 'mrekognitionservice-utt-video360Check', //preguntar
  timeUploadVideo: 15000,
  rekognitionFaceIdWithDoc: 'mrekognitionservice-utt-createFaceIdCodeWithID', //preguntar
  mMeetUrl: 'https://myappmeet.myappsuiteconsole.net/'
};