import { AuthResenValidComponent } from './../auth-resen-valid/auth-resen-valid.component';
import { UserResetPasswordComponent } from './../user-reset-password/user-reset-password.component';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NbDialogService } from '@nebular/theme';
import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';
import { CompanyService } from 'src/app/services/company.service';
import { Auth } from 'aws-amplify';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loading = false;
  urlLog = 'assets/images/mLogo.png';
  urlAlt;
  resetPassword = true;
  resendVCode = true;
  gmailLogin = false;
  azureLogin = false;
  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    const companyLogo = window.location.hostname;
    this.urlAlt = companyLogo.split('.')[0];
    // if (this.urlAlt === 'localhost') { this.urlAlt = 'myappsoftware'; }
    this.companyService.searchCompanyBackend(this.urlAlt).then((result) => {
      const body = result.body[0];
      const logo = body.companyLogo;
      this.urlLog = logo === undefined ? 'assets/images/mLogo.png' : logo;
      this.resendVCode = body.companyResendVCode;
      this.resetPassword = body.companyResetPassword;
      this.gmailLogin = body.companyGmailLogin;
      this.azureLogin = body.companyAzureLogin
    }).catch((err) => {
      this.urlLog = 'assets/images/mLogo.png';
    });
  }

  onSubmit(form: NgForm) {
    const email = form.value.email;
    const password = form.value.password;
    this.authService.signIn(email, password);
  }

  async signInWithGoogle() {
    this.loading = true;
    await this.authService.socialSignIn(AuthService.GOOGLE).then(
      (auth) => {
        this.loading = false;
      }
    ).catch(
      (err) => {
        this.loading = false;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async signInWithAzureAD() {
    window.location.assign(
      'https://' +
      environment.cognitoDomain +
      '/oauth2/authorize?' +
      'identity_provider=office365&redirect_uri=' +
      environment.cognitoRedirectSignIn +
      '&response_type=code&client_id=' +
      environment.clientId +
      '&scope=aws.cognito.signin.user.admin+email+openid+phone+profile'
    );
  }

  onClickValidateUser() {
    this.dialogService.open(UserConfirmationComponent,
      {
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      });
  }

  onResendValid() {
    this.dialogService.open(AuthResenValidComponent,
      {
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      });
  }

  onClickResetPassword() {
    this.dialogService.open(UserResetPasswordComponent,
      {
        hasBackdrop: true,
        closeOnEsc: true,
        closeOnBackdropClick: false
      });
  }

}
