import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { ExamService } from 'src/app/services/exam.service';
import examUnassignedTableSettings from '../../../../assets/config/examTable/examUnassignedTableSettings.json';
import examFilterSettings from '../../../../assets/config/filterTable/exam/examUnassignTableFilters.json';
import { UserExam } from 'src/app/models/userExam';
import { Exam } from 'src/app/models/exam';
import { UserAssign } from 'src/app/models/userAssign';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-exam-unassign',
  templateUrl: './exam-unassign.component.html',
  styleUrls: ['./exam-unassign.component.scss']
})
export class ExamUnassignComponent implements OnInit, OnChanges {

  settings = examUnassignedTableSettings;

  loading = false;
  examList;
  examListLocal;
  hasFaceId = true;

  examFSettings = examFilterSettings;

  source: LocalDataSource;

  items: TreeviewItem[];
  unAssignList: [];
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000
  });

  constructor(
    private router: Router,
    private toastrService: NbToastrService,
    private userService: UserService,
    private examService: ExamService,
    private dialogService: NbDialogService,
  ) {
    this.source = new LocalDataSource(this.examList);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);

    if (changes.examList) {
      this.source = new LocalDataSource(this.examList);
    }
  }

  async ngOnInit() {
    await this.reloadUnassignExams();
  }

  async reloadUnassignExams() {
    const users = await this.listAllExams();
    const groupsList = [
      { "tittle": "Hora", "value": "examTimeShow", "parent": "examDateShow", "children": "users" },
      { "tittle": "Fecha", "value": "examDateShow", "parent": "examNameShow" },
      { "tittle": "Examen", "value": "examNameShow" }];
    const treeMode = await this.treeMode(users, groupsList);
    this.items = treeMode;
  }

  treeMode(users, params): TreeviewItem[] {
    let items = new Array<TreeviewItem>();
    const orderedApiFields = this.getOrderedFields(params);
    const orderedUsers = this.getOrderedUsers(users, orderedApiFields);
    orderedUsers.forEach(item => {
      items.push(new TreeviewItem(item));
    });
    return items;
  }

  changeTreeview(event: []) {
    this.unAssignList = event;
  }

  async unassignExams() {
    // const userExam: UserExam = event.data.user;
    this.dialogService.open(ConfirmWindowComponent,
      {
        context: {
          message: 'Desea desasignar a este grupo?'
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose.subscribe(async (result) => {
        if (result === true) {
          this.unAssignList.forEach(async (assignCode) => {
            const data = this.examList.find(x => x.streamCode === assignCode);
            const userExam: UserExam = data.user;
            const assign = userExam.userAssign.find(x => x.userStreamCode === assignCode);
            const assignIndex = userExam.userAssign.indexOf(assign);
            userExam.userAssign.splice(assignIndex, 1);
            const dataIndex = this.examList.indexOf(data);
            this.examList[dataIndex].user = userExam;
            await this.updateUser(userExam);
          });
        }
      });
  }

  getOrderedUsers(users, orderedApiFields) {
    let orderedUsers = {}
    let keysAndSubkeys = [];
    let formedJson = [];

    users.forEach(user => {
      // if (completeUser.clientGroups !== undefined) {
      // const user = JSON.parse(completeUser.clientGroups);
      let prevKeys = [];
      let keysAndSubkeysIndexes = [];
      let prevJson = {};

      for (let k = 0; k <= orderedApiFields.length; k++) {
        let currentApiField = "";
        let prevObj = {};
        if (k < orderedApiFields.length) {
          currentApiField = orderedApiFields[k].value;
          if (k == 0) {
            if (orderedUsers[user[currentApiField]] == undefined) {
              orderedUsers[user[currentApiField]] = {};
            }
            prevKeys.push(user[currentApiField]);

            if (keysAndSubkeys[0] == undefined) {
              keysAndSubkeys[0] = [user[currentApiField]]
              keysAndSubkeysIndexes.push(0);
              formedJson.push(
                {
                  text: user[currentApiField],
                  value: user[currentApiField],
                  collapsed: true,
                  children: []
                }
              )
            }
            else if (keysAndSubkeys[0].indexOf(user[currentApiField]) == -1) {
              keysAndSubkeys[0].push(user[currentApiField])
              keysAndSubkeysIndexes.push(keysAndSubkeys[0].length - 1);
              formedJson.push(
                {
                  text: user[currentApiField],
                  value: user[currentApiField],
                  collapsed: true,
                  children: []
                }
              )

            }
            else {
              let currentIndex = keysAndSubkeys[0].indexOf(user[currentApiField]);
              keysAndSubkeysIndexes.push(currentIndex);
            }

            prevJson = formedJson;
          }
          else {
            for (let j = 0; j < prevKeys.length; j++) {
              if (j == 0) {
                prevObj = orderedUsers[prevKeys[j]];
              }
              else {
                prevObj = prevObj[prevKeys[j]]
              }
            }
            if (prevObj[user[currentApiField]] == undefined) {
              prevObj[user[currentApiField]] = {}
              if (keysAndSubkeys[k] == undefined) {
                keysAndSubkeys[k] = [user[currentApiField]]
                keysAndSubkeysIndexes.push(0);
              }
              else {
                let index = keysAndSubkeys[k].indexOf(user[currentApiField]);
                if (index == -1) {
                  keysAndSubkeys[k].push(user[currentApiField])
                  keysAndSubkeysIndexes.push(keysAndSubkeys.length - 1);
                }
                else {

                  keysAndSubkeysIndexes.push(index);
                }
              }
              let tempJsonChildren = {
                text: user[currentApiField],
                value: user[currentApiField],
                children: []
              }
              if (prevJson[keysAndSubkeysIndexes[k - 1]].children == undefined) {
                prevJson[keysAndSubkeysIndexes[k - 1]].children = [tempJsonChildren];
              }
              else {
                prevJson[keysAndSubkeysIndexes[k - 1]].children.push(tempJsonChildren);
              }
              keysAndSubkeys[k] = Object.keys(prevObj)
              keysAndSubkeysIndexes[k] = (keysAndSubkeys[k].indexOf(user[currentApiField]));
              prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
            }
            else {
              if (keysAndSubkeys[k].indexOf(user[currentApiField]) == -1) {
                keysAndSubkeys[k].push(user[currentApiField])
                keysAndSubkeysIndexes.push(keysAndSubkeys.length - 1);
                prevJson[keysAndSubkeysIndexes[k - 1]].children.push(
                  {
                    text: user[currentApiField],
                    value: user[currentApiField],
                    children: []
                  }
                )
                prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
              }
              else {
                let currentIndex = keysAndSubkeys[k].indexOf(user[currentApiField]);
                keysAndSubkeysIndexes.push(currentIndex);
                prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
              }
            }
            prevKeys.push(user[currentApiField]);
          }
        }
        else {

          if (orderedUsers[user[currentApiField]] == undefined) {
            if (k == (orderedApiFields.length)) {
              if (prevJson[keysAndSubkeysIndexes[k - 1]].children == undefined) {
                prevJson[keysAndSubkeysIndexes[k - 1]].children = [{ text: user.userNameShow, value: user.streamCode, checked: false, }]
              }
              else {
                prevJson[keysAndSubkeysIndexes[k - 1]].children.push({ text: user.userNameShow, value: user.streamCode, checked: false, })
              }
            }
          }
          else {
            if (k == (orderedApiFields.length - 1)) {
              prevObj[user[currentApiField]].users.push(user);
              prevJson[keysAndSubkeysIndexes[k - 1]].children[keysAndSubkeysIndexes[k]].push(
                { text: user.userNameShow, value: user.streamCode, checked: false, }
              );
            }
          }
        }
      }
      // }
    });
    return formedJson;
  }

  getOrderedFields(companyApiFields) {
    const companyApiFieldsWithParent = [];
    let biggestDaddy = undefined;
    const orderedApiFields = [];

    companyApiFields.forEach((companyApiField, index) => {
      if (companyApiField.parent === undefined) {
        biggestDaddy = companyApiField;
        orderedApiFields.push(companyApiField);
      }
      else {
        companyApiFieldsWithParent.push(companyApiField);
      }
    });
    let lookingForParentAndChild = true;
    let currentDaddy = biggestDaddy;
    while (lookingForParentAndChild === true) {
      let daddysFirstChild = this.getFirstChild(currentDaddy, companyApiFieldsWithParent);
      orderedApiFields.push(daddysFirstChild.firstChild);
      companyApiFieldsWithParent.splice(daddysFirstChild.index, 1);
      currentDaddy = daddysFirstChild.firstChild;
      if (companyApiFieldsWithParent.length === 0) {
        lookingForParentAndChild = false;
      }
    }
    return orderedApiFields;
  }

  getFirstChild(daddy, childs) {
    let firstChild = undefined;
    let index = 0;
    for (let i = 0; i < childs.length; i++) {
      let parent = childs[i].parent;
      if (parent === daddy.value) {
        firstChild = childs[i];
        index = i;
        break;
      }
    }
    return { firstChild, index }
  }

  async unassign(event) {
    const userExam: UserExam = event.data.user;
    this.dialogService.open(ConfirmWindowComponent,
      {
        context: {
          message: 'Desea desasignar a ' + userExam.userName + '?'
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose.subscribe(async (result) => {
        if (result === true) {
          const assign = userExam.userAssign.find(x => x.userStreamCode === event.data.streamCode);
          const assignIndex = userExam.userAssign.indexOf(assign);
          userExam.userAssign.splice(assignIndex, 1);
          await this.updateUser(userExam);
        }
      });
  }

  async listAllExams() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (user !== undefined && user !== null) {
      const myExams: UserExam[] = await this.myExamsBackend();
      const allExams: Exam[] = await this.allCompanyExams(user.company.companyCode);
      const cognitoUsers = await this.cognitoUsersBackend();
      const examList = new Array<any>();
      if (myExams !== undefined) {
        myExams.forEach((user) => {
          if (user.userAssign !== undefined) {
            user.userAssign.forEach((exam) => {
              const examAllData = allExams.find((x) => x.examCode === exam.userExamCode);
              const userAllData = cognitoUsers.find((x) => x.Username === user.userCode);
              console.log(userAllData)
              if (examAllData !== undefined && userAllData !== undefined) {
                if (exam.userExamEnd === undefined && exam.userExamStart === undefined) {
                  const name = userAllData.Attributes.find((x) => x.Name === 'name');
                  const group = userAllData.Attributes.find((x) => x.Name === 'custom:group');
                  const startDate = new Date(exam.userExamStartDateTime);
                  const endDate = new Date(startDate);
                  endDate.setMinutes(endDate.getMinutes() + parseInt(examAllData.examTime, 0));

                  examList.push({
                    examAllData,
                    exam,
                    user,
                    streamCode: exam.userStreamCode,
                    userNameShow: name.Value,
                    examNameShow: examAllData.examName,
                    examDateShow: new Date(exam.userExamStartDateTime).toLocaleDateString('es-GT'),
                    examTimeShow: this.getTimeOnly(new Date(exam.userExamStartDateTime)),
                    groupUser: group.Value,
                  });
                }
              }
            });
          }
        });
        this.examList = await this.showOnlyAllow(examList);
        this.source = new LocalDataSource(this.examList);
        return this.examList;
      }
    }
  }

  showOnlyAllow(examList) {
    const list = new Array<any>();
    examList.forEach(examL => {
      const userAssign: UserAssign = examL.exam;
      const exam: Exam = examL.examAllData;
      const currentDateTime = new Date();
      const startDate = new Date(userAssign.userExamStartDateTime);
      const validationDate = new Date(startDate);
      const endDate = new Date(startDate);
      validationDate.setMinutes(validationDate.getMinutes() - parseInt(exam.examPreValidationMinutes, 0));
      endDate.setMinutes(endDate.getMinutes() + parseInt(exam.examTime, 0));
      if (
        currentDateTime >= validationDate &&
        currentDateTime < endDate
      ) {
        list.push(examL);
      }

    });
    return list;
  }

  getTimeOnly(d) {
    const x = document.getElementById('demo');
    const h = this.addZero(d.getHours());
    const m = this.addZero(d.getMinutes());
    const s = this.addZero(d.getSeconds());
    return h + ':' + m;
  }

  addZero(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  myExamsBackend() {
    this.loading = true;
    return this.userService.allUserExamBackend().then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  cognitoUsersBackend() {
    this.loading = true;
    return this.userService.allUserCognito().then(
      (result) => {
        return result.Users;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  allCompanyExams(companyCode) {
    this.loading = true;
    return this.examService.allExamCompanyBackend(companyCode).then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  updateUser(user) {
    this.loading = true;
    this.userService.updateUserBackend(user).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show(
          '',
          'Actualizado',
          { position, status });
        this.reloadUnassignExams();
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  cleanData() {
    this.source.setFilter([]);
  }

  filterData(event) {
    let filters = []
    event.forEach(element => {
      filters.push({
        field: element.fieldname,
        search: element.value
      });
    });
    this.source.setFilter(filters)

  }
}
