import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import { UserExam } from 'src/app/models/userExam';
import { ExamService } from 'src/app/services/exam.service';
import { Exam } from 'src/app/models/exam';
import { UserAssign } from 'src/app/models/userAssign';
import examCheckOneAnswerTableSettings from '../../../../assets/config/examTable/examCheckOneAnswerTableSettings.json';
import examCheckOneAnswerBTableSettings from '../../../../assets/config/examTable/examCheckOneAnswerTableSettings.json';
import examCheckMultipleAnswerTableSettings from '../../../../assets/config/examTable/examCheckMultipleAnswerTableSettings.json';
import examCheckDevelopAnswerTableSettings from '../../../../assets/config/examTable/examCheckDevelopAnswerTableSettings.json';
import examCheckCasesAnswerTableSettings from '../../../../assets/config/examTable/examCheckCasesAnswerTableSettings.json';
import { ExamCheckValidationAnswerComponent } from '../exam-check-validation-answer/exam-check-validation-answer.component';
import { LocalDataSource } from 'ng2-smart-table';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-exam-check-validation',
  templateUrl: './exam-check-validation.component.html',
  styleUrls: ['./exam-check-validation.component.scss']
})
export class ExamCheckValidationComponent implements OnInit {
  loading: boolean;
  examValidation = {
    company: {
      companyCheckMultipleOptionShow: true
    }
  } as any;
  userCode;
  examCode;
  streamCode;

  settingsOneAnswer = examCheckOneAnswerTableSettings;
  settingsOneAnswerB = examCheckOneAnswerBTableSettings;
  settingsMultipleAnswer = examCheckMultipleAnswerTableSettings;
  settingsDevelopAnswer = examCheckDevelopAnswerTableSettings;
  settingsCasesAnswer = examCheckCasesAnswerTableSettings;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private userService: UserService,
    private examService: ExamService,
    private dialogService: NbDialogService,
    private companyService: CompanyService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async (params: any) => {
      this.examCode = params.params.examCode;
      this.userCode = params.params.userCode;
      this.streamCode = params.params.streamCode;
      await this.getExam(this.examCode, this.userCode, this.streamCode);
    });
  }

  async getExam(examCode, userCode, streamCode) {
    const user = JSON.parse(localStorage.getItem('user'));
    const company = await this.getCompany(user.company.companyCode);
    const userData: UserExam = await this.getUserBackend(userCode);
    const userAssign = userData.userAssign.find(x => x.userStreamCode === streamCode);
    // const userAssign = await this.getAssign(streamCode);
    const examData: Exam = await this.getExamBackend(examCode);
    const check = await this.checkAnswerResults(userAssign, examData);
    this.examValidation['examName'] = examData.examName;
    this.examValidation['exam'] = examData;
    this.examValidation['company'] = company;
    this.examValidation['scoresOneAnswer'] = check.scoresOneAnswer;
    this.examValidation['scoresOneAnswerB'] = check.scoresOneAnswerB;
    this.examValidation['scoresMultipleAnswer'] = check.scoresMultipleAnswer;
    this.examValidation['scoresDevelopAnswer'] = new LocalDataSource(check.scoresDevelopAnswer);
    this.examValidation['scoresCasesAnswer'] = new LocalDataSource(check.scoresCasesAnswer);
    console.log(this.examValidation);
  }

  // getAssign(streamCode) {
  //   this.loading = true;
  //   return this.userService.oneUserAssignBackend(streamCode).then(
  //     (result) => {
  //       const userExamBackend = result.body
  //       return userExamBackend;
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show(
  //         '',
  //         err.message,
  //         { position, status });
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  getUserBackend(userCode) {
    this.loading = false;
    return this.userService.oneUserExamBackend(userCode).then(
      (result) => {
        return result.body;
      }
    ).catch((err) => {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show('', err, { position, status });
    }).finally(
      () => {
        this.loading = false;
      });
  }

  getExamBackend(examCode) {
    this.loading = true;
    return this.examService.oneExamBackend(examCode).then(
      (result) => {
        const lzw = require("node-lzw");
        result.body = JSON.parse(lzw.decode(result.body));
        return result.body;
      }
    ).catch((err) => {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show('', err, { position, status });
    }).finally(
      () => {
        this.loading = false;
      });
  }

  checkAnswerResults(userAssign: UserAssign, exam: Exam) {
    const scoresOneAnswer = new Array<any>();
    const scoresOneAnswerB = new Array<any>();
    const scoresMultipleAnswer = new Array<any>();
    const scoresDevelopAnswer = new Array<any>();
    const scoresCasesAnswer = new Array<any>();
    userAssign.userExam.forEach((question) => {
      const questionUserResponse = userAssign.userExamResults.find(x => x.examQuestionCode === question.examQuestionCode);
      let score = 0;
      let eachQuestionValue = 0;
      switch (question.examQuestionType) {
        case 'oneAnswer':
          question.examQuestionAnswer.forEach(answer => {
            if (answer.examQuestionAnswerResponseIsCorrect === true) {
              question.examQuestionAnswerOne = answer.examQuestionAnswerResponse;
            }
          });
          eachQuestionValue = Math.round((parseInt(exam.examScoreOneAnswer) * 100) / parseInt(exam.examCounterRandomOneAnswer)) / 100;
          eachQuestionValue = isNaN(eachQuestionValue) === false ? eachQuestionValue : 0;
          if (question.examQuestionAnswerOne === questionUserResponse.examQuestionAnswerOne) {
            score = eachQuestionValue;
          }
          scoresOneAnswer.push({
            question: questionUserResponse,
            questionShow: question.examQuestion,
            answerShow: questionUserResponse.examQuestionAnswerOne,
            eachQuestionValue,
            score
          });
          break;
        case 'oneAnswerB':
          question.examQuestionAnswer.forEach(answer => {
            if (answer.examQuestionAnswerResponseIsCorrect === true) {
              question.examQuestionAnswerOne = answer.examQuestionAnswerResponse;
            }
          });
          eachQuestionValue = Math.round((parseInt(exam.examScoreOneAnswerB) * 100) / parseInt(exam.examCounterRandomOneAnswerB)) / 100;
          eachQuestionValue = isNaN(eachQuestionValue) === false ? eachQuestionValue : 0;
          if (question.examQuestionAnswerOne === questionUserResponse.examQuestionAnswerOne) {
            score = eachQuestionValue;
          }
          scoresOneAnswerB.push({
            question: questionUserResponse,
            questionShow: question.examQuestion,
            answerShow: questionUserResponse.examQuestionAnswerOne,
            eachQuestionValue,
            score
          });
          break;
        case 'multipleAnswer':
          let isCorrect = true;
          questionUserResponse.examQuestionAnswer.forEach(answer => {
            const compareAnswer = question.examQuestionAnswer.find(x => x.examQuestionAnswerCode === answer.examQuestionAnswerCode);

            if (compareAnswer.examQuestionAnswerResponseIsCorrect !== answer.examQuestionAnswerResponseIsCorrect) {
              isCorrect = false;
            }
          });
          eachQuestionValue = Math.round((parseInt(exam.examScoreMultipleAnswers) * 100) / parseInt(exam.examCounterRandomMultipleAnswers)) / 100;
          eachQuestionValue = isNaN(eachQuestionValue) === false ? eachQuestionValue : 0;
          if (isCorrect === true) {
            score = eachQuestionValue;
          }
          let responses = '';
          const arr = JSON.parse(questionUserResponse.examQuestionAnswerOne);
          arr.forEach((response, index, arr) => {
            const res = questionUserResponse.examQuestionAnswer.find(x => x.examQuestionAnswerCode === response);
            responses = responses + res.examQuestionAnswerResponse
            if (index !== (arr.length - 1)) {
              responses = responses + ','
            }

          });
          scoresMultipleAnswer.push({
            question,
            questionShow: question.examQuestion,
            answerShow: responses,
            eachQuestionValue,
            score
          });
          break;
        case 'themeDevelopAnswer':
          eachQuestionValue = Math.round((parseInt(exam.examScoreDevelopAnswers) * 100) / parseInt(exam.examScoreDevelopAnswersOver)) / 100;
          eachQuestionValue = isNaN(eachQuestionValue) === false ? eachQuestionValue : 0;
          scoresDevelopAnswer.push({
            question: questionUserResponse,
            questionShow: question.examQuestion,
            answerShow: questionUserResponse.examQuestionAnswerOne,
            eachQuestionValue,
            comment: '',
            score
          });
          break;
        case 'themeCasesAnswer':
          eachQuestionValue = Math.round((parseInt(exam.examScoreCasesAnswers) * 100) / parseInt(exam.examCounterRandomCasesAnswers)) / 100;
          eachQuestionValue = isNaN(eachQuestionValue) === false ? eachQuestionValue : 0;
          scoresCasesAnswer.push({
            question: questionUserResponse,
            questionShow: question.examQuestion,
            answerShow: questionUserResponse.examQuestionAnswerOne,
            eachQuestionValue,
            comment: '',
            score
          });
          break;
      }

    });

    return { scoresOneAnswer, scoresOneAnswerB, scoresMultipleAnswer, scoresDevelopAnswer, scoresCasesAnswer };
  }

  showAnswer(event) {
    // console.log(event.data);
    // console.log(this.examValidation['scoresDevelopAnswer'].data)
    this.dialogService.open(ExamCheckValidationAnswerComponent,
      {
        context: {
          scoreData: event.data
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose.subscribe((result) => {
        // console.log(result);
        this.examValidation['scoresDevelopAnswer'] = new LocalDataSource(this.examValidation['scoresDevelopAnswer'].data);
        // console.log(this.examValidation['scoresDevelopAnswer'].data);
        this.examValidation['scoresCasesAnswer'] = new LocalDataSource(this.examValidation['scoresCasesAnswer'].data);
      });
  }

  close() {
    this.router.navigate(['/examCheck/']);
  }

  async save() {
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    let total = 0;
    this.examValidation.scoresOneAnswer.forEach(element => {
      total = total + element.score;
    });
    this.examValidation.scoresOneAnswerB.forEach(element => {
      total = total + element.score;
    });
    this.examValidation.scoresMultipleAnswer.forEach(element => {
      total = total + element.score;
    });
    let counterDevelopAnswer = 0;
    this.examValidation.scoresDevelopAnswer.data.forEach(element => {
      total = total + parseFloat(element.score);
      if (element.checked === true && parseInt(element.score) !== 0) {
        counterDevelopAnswer++;
      }
    });
    this.examValidation.exam.examScoreDevelopAnswersOver = this.examValidation.exam.examScoreDevelopAnswersOver === undefined ? 0 : this.examValidation.exam.examScoreDevelopAnswersOver;
    if (counterDevelopAnswer !== parseFloat(this.examValidation.exam.examScoreDevelopAnswersOver)) {
      this.toastrService.show(
        '',
        'Se debe calificar ' + this.examValidation.exam.examScoreDevelopAnswersOver + ' de desarrollo de tema.',
        { position, status });
      return false;
    }
    let counterCasesAnswer = 0;
    this.examValidation.scoresCasesAnswer.data.forEach(element => {
      total = total + parseFloat(element.score);
      if (element.checked === true && parseInt(element.score) !== 0) {
        counterCasesAnswer++;
      }
    });
    this.examValidation.exam.examCounterRandomCasesAnswers = this.examValidation.exam.examCounterRandomCasesAnswers === undefined ? 0 : this.examValidation.exam.examCounterRandomCasesAnswers;
    if (counterCasesAnswer !== parseFloat(this.examValidation.exam.examCounterRandomCasesAnswers)) {
      this.toastrService.show(
        '',
        'Se debe calificar ' + this.examValidation.exam.examCounterRandomCasesAnswers + ' de casos.',
        { position, status });
      return false;
    }
    if (total > 100) {

      this.toastrService.show(
        '',
        'Total (' + total + ') no puede ser mayor a 100',
        { position, status });
      return false;
    }
    this.dialogService.open(ConfirmWindowComponent,
      {
        context: {
          message: 'Esta seguro de terminar de calificar el examen?'
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose.subscribe(async (result) => {
        console.log(result);
        if (result === true) {
          const userExam: UserExam = await this.myExamsBackend(this.userCode);
          const assign = userExam.userAssign.find(x => x.userStreamCode === this.streamCode);
          // console.log(assign);
          const assignIndex = userExam.userAssign.indexOf(assign);
          userExam.userAssign[assignIndex].userExamScore = {
            scoresOneAnswer: this.examValidation.scoresOneAnswer,
            scoresOneAnswerB: this.examValidation.scoresOneAnswerB,
            scoresMultipleAnswer: this.examValidation.scoresMultipleAnswer,
            scoresDevelopAnswer: this.examValidation.scoresDevelopAnswer.data,
            scoresCasesAnswer: this.examValidation.scoresCasesAnswer.data,
          };
          userExam.userAssign[assignIndex].userExamQualified = true;

          // console.log(userExam.userAssign[assignIndex]);
          await this.updateUser(userExam);
          // await this.updateUserAssign(userExam.userAssign[assignIndex])
          this.router.navigate(['/examCheck/']);
        }
      });

  }

  myExamsBackend(user) {
    this.loading = true;
    return this.userService.oneUserExamBackend(user).then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  updateUser(user) {
    console.log(user);
    this.loading = true;
    this.userService.updateUserBackend(user).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show(
          '',
          'Actualizado',
          { position, status });
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  // updateUserAssign(userAssign) {
  //   this.loading = true;
  //   this.userService.updateUserAssignBackend(userAssign).then(
  //     (result) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'success';
  //       this.toastrService.show(
  //         '',
  //         'Actualizado',
  //         { position, status });
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show(
  //         '',
  //         err.message,
  //         { position, status });
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        const company = result.body;
        return company;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

}
