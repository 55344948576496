import { ExamAssignComponent } from './pages/exam/exam-assign/exam-assign.component';
import { ExamVerifyComponent } from './pages/exam/exam-verify/exam-verify.component';
import { CompanyComponent } from './pages/company/company.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { UserMyProfileComponent } from './pages/auth/user-my-profile/user-my-profile.component';
import { ExamPreViewerComponent } from './pages/exam/exam-pre-viewer/exam-pre-viewer.component';
import { ExamComponent } from './pages/exam/exam.component';
import { UserAutoConfirmComponent } from './pages/auth/user-auto-confirm/user-auto-confirm.component';
import { ExamPostViewerComponent } from './pages/exam/exam-post-viewer/exam-post-viewer.component';
import { MyExamComponent } from './pages/exam/my-exam/my-exam.component';
import { ExamMaintenanceComponent } from './pages/exam/exam-maintenance/exam-maintenance.component';
import { ExamMaintenanceFieldsComponent } from './pages/exam/exam-maintenance-fields/exam-maintenance-fields.component';
import { ExamViewerComponent } from './pages/exam/exam-viewer/exam-viewer.component';
import { ExamAssignationComponent } from './pages/exam/exam-assignation/exam-assignation.component';
import { UserFaceIdComponent } from './pages/user/user-face-id/user-face-id.component';
import { UserMaintenanceComponent } from './pages/user/user-maintenance/user-maintenance.component';
import { UserMaintenanceFieldsComponent } from './pages/user/user-maintenance-fields/user-maintenance-fields.component';
import { ReportUsersComponent } from './pages/reports/report-users/report-users.component';
import { ExamImportComponent } from './pages/exam/exam-import/exam-import.component';
import { ReportUserResultsComponent } from './pages/reports/report-user-results/report-user-results.component';
import { ExamTestSignalChannelComponent } from './pages/exam/exam-test-signal-channel/exam-test-signal-channel.component';
import { ReportExamChannelListComponent } from './pages/reports/report-exam-channel-list/report-exam-channel-list.component';
import { ExamUnassignComponent } from './pages/exam/exam-unassign/exam-unassign.component';
import { ExamCheckComponent } from './pages/exam/exam-check/exam-check.component';
import { ExamCheckValidationComponent } from './pages/exam/exam-check-validation/exam-check-validation.component';
import { ReportCheckExamsComponent } from './pages/reports/report-check-exams/report-check-exams.component';
import { ExamCloneComponent } from './pages/exam/exam-clone/exam-clone.component';
import { ExamSpreadSheetComponent } from './pages/exam/exam-spread-sheet/exam-spread-sheet.component';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'login', canActivate: [AuthGuard], component: LoginComponent },
  // Company
  { path: 'company', canActivate: [AuthGuard], component: CompanyComponent },
  // User
  { path: 'userMaintenance', canActivate: [AuthGuard], component: UserMaintenanceComponent },
  { path: 'userMaintenanceFields', canActivate: [AuthGuard], component: UserMaintenanceFieldsComponent },
  { path: 'userMaintenanceFields/:userCode', canActivate: [AuthGuard], component: UserMaintenanceFieldsComponent },
  { path: 'userAutoConfirm/:userEmail/:verificationCode', component: UserAutoConfirmComponent },
  { path: 'userProfile', canActivate: [AuthGuard], component: UserMyProfileComponent },
  { path: 'userFaceId', canActivate: [AuthGuard], component: UserFaceIdComponent },
  // Exam
  { path: 'examMaintenance', canActivate: [AuthGuard], component: ExamMaintenanceComponent },
  { path: 'examMaintenanceFields', canActivate: [AuthGuard], component: ExamMaintenanceFieldsComponent },
  { path: 'examMaintenanceFields/:examCode', canActivate: [AuthGuard], component: ExamMaintenanceFieldsComponent },
  { path: 'examMaintenanceFields/:examCode/:examClone', canActivate: [AuthGuard], component: ExamMaintenanceFieldsComponent },
  { path: 'exam', canActivate: [AuthGuard], component: ExamComponent },
  { path: 'examViewerTeacher/:examCode', canActivate: [AuthGuard], component: ExamViewerComponent },
  { path: 'examAssign', canActivate: [AuthGuard], component: ExamAssignComponent },
  { path: 'examUnassign', canActivate: [AuthGuard], component: ExamUnassignComponent },
  { path: 'examAssignation/:examCode', canActivate: [AuthGuard], component: ExamAssignationComponent },
  { path: 'examAssigned', canActivate: [AuthGuard], component: MyExamComponent },
  { path: 'examPreviousValidations/:examCode/:streamCode', canActivate: [AuthGuard], component: ExamPreViewerComponent },
  { path: 'examPreviousValidations/:examCode/:streamCode/:cameraCode', canActivate: [AuthGuard], component: ExamPreViewerComponent },
  { path: 'examViewer/:examCode/:streamCode', canActivate: [AuthGuard], component: ExamViewerComponent },
  { path: 'examResultPostViewer', canActivate: [AuthGuard], component: ExamPostViewerComponent },
  { path: 'examVerification', canActivate: [AuthGuard], component: ExamVerifyComponent },
  { path: 'examImport', canActivate: [AuthGuard], component: ExamImportComponent },
  { path: 'examChannelTest', canActivate: [AuthGuard], component: ExamTestSignalChannelComponent },
  { path: 'examCheck', canActivate: [AuthGuard], component: ExamCheckComponent },
  { path: 'examCheckValidation/:examCode/:userCode/:streamCode', canActivate: [AuthGuard], component: ExamCheckValidationComponent },
  { path: 'examClone', canActivate: [AuthGuard], component: ExamCloneComponent },
  { path: 'examSpreadSheet', canActivate: [AuthGuard], component: ExamSpreadSheetComponent },
  // Reports
  { path: 'reportUsers', canActivate: [AuthGuard], component: ReportUsersComponent },
  { path: 'reportUsersResults', canActivate: [AuthGuard], component: ReportUserResultsComponent },
  { path: 'reportUsersResults/:active', canActivate: [AuthGuard], component: ReportUserResultsComponent },
  { path: 'reportExamChannelList', canActivate: [AuthGuard], component: ReportExamChannelListComponent },
  { path: 'reportCheckExams', canActivate: [AuthGuard], component: ReportCheckExamsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
