import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class StreamingService {
  streamingPath = 'mexam-exam';
  streamProgress;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  uploadAndInsertStreamBackend(event): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.apiUrl + this.streamingPath + '/record/', event, { headers }).toPromise();
  }

  uploadFileS3(file, prefixS3, step): Promise<any> {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const buf = new Buffer(file.imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    const params = {
      Bucket: environment.bucketName,
      Key: prefixS3 + '/step_' + step + '.jpeg',
      Body: buf,
      ACL: 'private',
      ContentEncoding: 'base64',
      ContentType: 'image/jpeg'
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.streamProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }

  verifyStepOne(event) {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    //mexambackend-sql-rekognitionSinglePictureObjects
    return this.http.post(environment.apiUrl + this.streamingPath + '/faceid/', event, { headers }).toPromise();
  }

  verifyStepTwoThreeFour(event) {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.apiUrl + this.streamingPath + '/faceid/object/', event, { headers }).toPromise();
  }

  verifyStepFive(event): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.apiUrl + this.streamingPath + '/video/360/', event, { headers }).toPromise();
  }

  allStreamBackend(): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.apiUrl + 'mexam-stream' + queryParam, { headers }).toPromise();
  }

  oneStreamBackend(stream): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.apiUrl + 'mexam-stream/one/' + stream + queryParam, { headers }).toPromise();
  }

  async getStreamFindS3(key) {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    return new Promise((resolve, reject) => {
      bucket.getObject({ Bucket: environment.bucketName, Key: key }, (err, data) => {
        if (err) reject(err);
        else {
          resolve(data.Body.toString('ascii'));
        }
      });
    });
  }

}
