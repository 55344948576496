import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { Subject } from 'rxjs';
import { User } from '../models/user';
import { UserExam } from '../models/userExam';
import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  AWS = require('aws-sdk');
  userProgress = new Subject<any>();
  userExamTable = 'mexam-user';
  userForValidationTable = 'mexam-exam/faceid/';
  userFolder = 'user';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  uploadFileS3(file, user: User, folder): Promise<any> {
    this.userProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: this.userFolder + '/' + user.userId + '/' + folder + '/' + file.name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.userProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      });
    });
  }

  uploadFileS3ForRekognition(file, prefixS3, step): Promise<any> {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const buf = new Buffer(file.imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    const params = {
      Bucket: environment.bucketName,
      Key: prefixS3 + '/photo_' + step + '.jpeg',
      Body: buf,
      ACL: 'private',
      ContentEncoding: 'base64',
      ContentType: 'image/jpeg'
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.userProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }

  oneUserExamBackendNoLog(userExamCode): Promise<any> {
    const filter = userExamCode;
    return this.http.get(environment.apiUrl + 'mexam-userNoLog' + '/one/' + filter).toPromise();
  }

  rekognitionFaceIdWithDoc(keys): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.apiUrl + 'mexam-exam/faceid/document/', keys, { headers }).toPromise();
  }

  insertUserExamBackend(userExam): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.apiUrl + this.userExamTable, userExam, { headers }).toPromise();
  }

  insertUserAssignBackend(userExam): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.apiUrl + this.userExamTable + '-assign', userExam, { headers }).toPromise();
  }

  // allUserExamBackend(): Promise<any> {
  //   const openedSession = this.authService.getAuthenticatedUserSession();
  //   const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
  //   const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
  //   return this.http.get(environment.apiUrl + this.userExamTable + queryParam, { headers }).toPromise();
  // }

  // allUserAssignBackend(userCode): Promise<any> {
  //   const filter = userCode;
  //   const openedSession = this.authService.getAuthenticatedUserSession();
  //   const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
  //   const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
  //   return this.http.get(environment.apiUrl + this.userExamTable + '-assign/user/'+ filter + queryParam, { headers }).toPromise();
  // }

  async allUserExamBackend(): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    let pages = new Array();
    let paginationToken;
    const firstPage: any = await this.http.get(environment.apiUrl + this.userExamTable + queryParam, { headers }).toPromise();
    if (firstPage.nextPage === undefined) {
      return firstPage;
    } else {
      firstPage.body.forEach(element => {
        pages.push(element);
      });
      paginationToken = firstPage.nextPage;

      while (paginationToken !== undefined) {
        const nextPage: any = await this.allUserExamBackendNextPage(paginationToken.userCode);
        if (nextPage.nextPage === undefined) {
          paginationToken = undefined;
          nextPage.body.forEach(element => {
            pages.push(element);
          });
          return { body: pages };
        } else {
          paginationToken = nextPage.nextPage;
          nextPage.body.forEach(element => {
            pages.push(element);
          });
        }
      }
    }
  }

  allUserExamBackendNextPage(nextPageToken): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.apiUrl + this.userExamTable + '/next/' + nextPageToken + queryParam, { headers }).toPromise();
  }

  allUserApi(url): Promise<any> {
    // const openedSession = this.authService.getAuthenticatedUserSession();
    // const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    // const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(url).toPromise();
  }

  allUserCognito(): Promise<any> {
    let paginationListUser = { Users: new Array() };
    let params = {
      UserPoolId: environment.userPoolId,
      // PaginationToken: undefined,
    };

    return new Promise(async (resolve, reject) => {
      const firstPaging: any = await this.cognitoPagination(params);
      if (firstPaging.PaginationToken === undefined) {
        resolve(firstPaging);
      } else {
        resolve(firstPaging);
        //   firstPaging.Users.forEach(user => {
        //     paginationListUser.Users.push(user);
        //   });
        //   params.PaginationToken = firstPaging.PaginationToken;
        //   while (params.PaginationToken !== undefined) {
        //     const firstPaging2: any = await this.cognitoPagination(params);
        //     if (firstPaging2.PaginationToken === undefined) {
        //       params.PaginationToken = undefined;
        //       firstPaging2.Users.forEach(user => {
        //         paginationListUser.Users.push(user);
        //       });
        //       resolve(paginationListUser);
        //     } else {
        //       params.PaginationToken = firstPaging2.PaginationToken;
        //       firstPaging2.Users.forEach(user => {
        //         paginationListUser.Users.push(user);
        //       });
        //     }
        //   }
      }
    });
  }

  cognitoPagination(params) {
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.listUsers(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

  }

  oneUserCognito(username): Promise<any> {
    const params = {
      UserPoolId: environment.userPoolId,
      Username: username,
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminGetUser(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  async changePasswordCognito(username, previousPassword, proposedPassword): Promise<any> {
    const accessToken: any = await this.getAccessToken(username, previousPassword);
    const params = {
      AccessToken: accessToken.accessToken.jwtToken,
      PreviousPassword: previousPassword,
      ProposedPassword: proposedPassword
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.changePassword(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  getAccessToken(username, password) {
    const POOL_DATA = {
      UserPoolId: environment.userPoolId,
      ClientId: environment.clientId
    };
    const userPool = new CognitoUserPool(POOL_DATA);
    return new Promise((resolve, reject) => {
      userPool.getCurrentUser().getSession(
        (err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }

        }
      );
    });
  }

  oneUserExamBackend(userExamCode): Promise<any> {
    const filter = userExamCode;
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.apiUrl + this.userExamTable + '/one/' + filter + queryParam, { headers }).toPromise();
  }

  // oneUserAssignBackend(streamCode): Promise<any> {
  //   const filter = streamCode;
  //   const openedSession = this.authService.getAuthenticatedUserSession();
  //   const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
  //   const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
  //   return this.http.get(environment.apiUrl + this.userExamTable + '-assign/one/' + filter + queryParam, { headers }).toPromise();
  // }

  // updateUserAssignBackend(userExam): Promise<any> {
  //   const openedSession = this.authService.getAuthenticatedUserSession();
  //   const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
  //   return this.http.put(environment.apiUrl + this.userExamTable + '-assign/', userExam, { headers }).toPromise();
  // }

  updateUserBackend(userCode: User): Promise<any> {
    console.log('updateUserBackend')
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.userExamTable + '/', userCode)
    return this.http.put(environment.apiUrl + this.userExamTable + '/', userCode, { headers }).toPromise();
  }

  postValidationStudentImage(userExam): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.put(environment.apiUrl + this.userForValidationTable, userExam, { headers }).toPromise();
  }

  updateUserFaceId(user) {
    const attrList: CognitoUserAttribute[] = [];
    const userFaceIdAttribute = {
      Name: 'custom:faceId',
      Value: user.faceId
    };
    attrList.push(new CognitoUserAttribute(userFaceIdAttribute));
    const params = {
      UserPoolId: environment.userPoolId,
      Username: user.userId,
      UserAttributes: attrList,
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  updateUserCognito(user) {
    const attrList: CognitoUserAttribute[] = [];
    const userRoleAttribute = {
      Name: 'custom:role',
      Value: user.userRole
    };
    const userGroupAttribute = {
      Name: 'custom:group',
      Value: user.userGroup
    };
    const userNameAttribute = {
      Name: 'name',
      Value: user.userName
    };
    const userThemeAttribute = {
      Name: 'custom:theme',
      Value: user.userTheme
    };
    attrList.push(new CognitoUserAttribute(userRoleAttribute));
    attrList.push(new CognitoUserAttribute(userGroupAttribute));
    attrList.push(new CognitoUserAttribute(userNameAttribute));
    attrList.push(new CognitoUserAttribute(userThemeAttribute));
    const params = {
      UserPoolId: environment.userPoolId,
      Username: user.userId,
      UserAttributes: attrList,
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  updateUserCognitoProfile(user) {
    const attrList: CognitoUserAttribute[] = [];
    const userNameAttribute = {
      Name: 'name',
      Value: user.userName
    };
    const userThemeAttribute = {
      Name: 'custom:theme',
      Value: user.userTheme
    };
    const userPictureAttribute = {
      Name: 'picture',
      Value: user.userPicture
    };
    attrList.push(new CognitoUserAttribute(userPictureAttribute));
    attrList.push(new CognitoUserAttribute(userNameAttribute));
    attrList.push(new CognitoUserAttribute(userThemeAttribute));
    const params = {
      UserPoolId: environment.userPoolId,
      Username: user.userId,
      UserAttributes: attrList,
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  insertUserCognito(user) {
    const attrList: CognitoUserAttribute[] = [];
    const userRoleAttribute = {
      Name: 'custom:role',
      Value: user.userRole
    };
    const userEmailAttribute = {
      Name: 'email',
      Value: user.userEmail
    };
    const userGroupAttribute = {
      Name: 'custom:group',
      Value: user.userGroup
    };
    const userThemeAttribute = {
      Name: 'custom:theme',
      Value: user.userTheme
    };
    const userPictureAttribute = {
      Name: 'picture',
      Value: user.userPicture
    };
    const userCompanyAttribute = {
      Name: 'custom:company',
      Value: user.userCompany
    };
    const userNameAttribute = {
      Name: 'name',
      Value: user.userName
    };
    attrList.push(new CognitoUserAttribute(userRoleAttribute));
    attrList.push(new CognitoUserAttribute(userGroupAttribute));
    attrList.push(new CognitoUserAttribute(userNameAttribute));
    attrList.push(new CognitoUserAttribute(userEmailAttribute));
    attrList.push(new CognitoUserAttribute(userThemeAttribute));
    attrList.push(new CognitoUserAttribute(userPictureAttribute));
    attrList.push(new CognitoUserAttribute(userCompanyAttribute));

    const params = {
      ClientId: environment.clientId,
      Username: user.userEmail,
      UserAttributes: attrList,
      Password: user.userPassword,
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.signUp(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  disableUser(username) {
    const params = {
      UserPoolId: environment.userPoolId,
      Username: username,
    };
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminDisableUser(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  enableUser(username) {
    const params = {
      UserPoolId: environment.userPoolId,
      Username: username,
    };
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminEnableUser(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  notifyCredencialsUserBackend(notify): Promise<any> {
    // const openedSession = this.authService.getAuthenticatedUserSession();
    // const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.apiUrl + this.userExamTable + '/notify/credencials/', notify).toPromise();
  }

  allUserOnlineBackend(): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.apiUrl + this.userExamTable + '/online/' + queryParam, { headers }).toPromise();
  }

  deleteUserCognito(username) {
    const params = {
      UserPoolId: environment.userPoolId,
      Username: username,
    };
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminDeleteUser(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

}
