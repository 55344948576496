import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { User } from './models/user';
import { Location, DOCUMENT } from '@angular/common';
import { CompanyService } from './services/company.service';
import { NbSidebarService } from '@nebular/theme';
import { LayoutService } from './core/utils/layout.service';
import { TreeviewI18n } from 'ngx-treeview';
import { DefaultTreeviewI18n } from './default-treeview-i18n';
import { I18n } from './i18n';
import { $WebSocket, WebSocketSendMode } from 'angular2-websocket';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: TreeviewI18n, useClass: DefaultTreeviewI18n }
  ]
})
export class AppComponent implements OnInit {
  isLogged: boolean;
  isExam = false;
  isTeacherView = false;
  styleBackground;
  loading;
  elem: any;

  @ViewChild('leftSidebar', { static: false }) leftSidebar: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private companyService: CompanyService,
    private sanitizer: DomSanitizer,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private i18n: I18n,
    @Inject(DOCUMENT) private document: any
  ) {
    // this.styleBackground = this.sanitizer.bypassSecurityTrustStyle(
    //   'url(assets/images/mLogo.png)');
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        const pathUrl = val.url.split('/');
        this.isExam = pathUrl[1] === 'examViewer' ? true : false;
        this.isTeacherView = pathUrl[1] === 'examViewerTeacher' ? true : false;
        // this.isExam = pathUrl[1] === 'examViewerTeacher' ? true : false;
      }
      if (val instanceof NavigationEnd) {
        if(document.fullscreenElement && this.isTeacherView) {
          this.closeFullscreen()
        }
        if (this.leftSidebar !== undefined) {
          if (this.leftSidebar.responsiveState === 'mobile') {
            this.toggleCompact(false);
          } else {
            this.toggleCompact(true);
          }
        }
        // this.setBackground();
      }
    });
  }

  setBackground() {
    let user: User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
      this.companyService.oneCompanyBackend(user.company.companyCode).then(
        (result) => {
          let logo = result.body.companyLogo === undefined ? 'assets/images/mLogo.png' : result.body.companyLogo;
          logo = result.body.companyLogo === null ? 'assets/images/mLogo.png' : result.body.companyLogo;
          this.styleBackground = this.sanitizer.bypassSecurityTrustStyle(
            'url(' + logo + ')');
        }).catch(
          (err) => {
            const logo = 'assets/images/mLogo.png';
            this.styleBackground = this.sanitizer.bypassSecurityTrustStyle(
              'url(' + logo + ')');
          }
        ).finally(
          () => {
          }
        );
    }

  }

  async ngOnInit() {
    this.authService.authStatusChanged.subscribe(authenticated => {
      this.isLogged = authenticated;
      if (!authenticated) {
        const pathUrl = this.location.path().split('/');
        if (pathUrl[1] !== 'userAutoConfirm') {
          this.router.navigate(['/login']);
        }
      }
    });

    this.elem = document.documentElement;

    this.authService.initAuth();
    await this.setBackground();
  }

  closeFullscreen() {
    if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  toggleCompact(compact) {
    if (compact === true) {
      this.sidebarService.compact('left');
    } else {
      this.sidebarService.collapse('left');
    }
    this.layoutService.changeLayoutSize();
  }

  set language(language: string) {
    this.i18n.language = language;
  }

  get language(): string {
    return this.i18n.language;
  }
}
