import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exam-clone',
  templateUrl: './exam-clone.component.html',
  styleUrls: ['./exam-clone.component.scss']
})
export class ExamCloneComponent implements OnInit {
  examList;
  loading;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }
  ngOnChanges() {
    console.log(this.examList)
  }

  examListReceiver($event) {
    this.examList = $event;
  }

  editExam(event) {
    const examCode = event.event.data.examCode;
    this.router.navigate(['/examMaintenanceFields/' + examCode + '/clone']);
  }

}
