import { Component, OnInit, Input } from '@angular/core';
import examQuestionImportTableSettings from '../../../../assets/config/examTable/examQuestionImportTableSettings.json';


@Component({
  selector: 'app-exam-maintenance-field-question-table-import',
  templateUrl: './exam-maintenance-field-question-table-import.component.html',
  styleUrls: ['./exam-maintenance-field-question-table-import.component.scss']
})
export class ExamMaintenanceFieldQuestionTableImportComponent implements OnInit {
  @Input() examQuestionList: any[];
  loading;

  settings = examQuestionImportTableSettings;

  constructor() { }

  ngOnInit() {
  }

}
