import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Exam } from '../models/exam';
import * as S3 from 'aws-sdk/clients/s3';
import * as KinesisVideo from 'aws-sdk/clients/kinesisvideo';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  examProgress = new Subject<any>();
  examTable = 'mexam-exam';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  oneExamBackend(examCode: string): Promise<any> {
    console.log('oneExamBackend')
    const filter = examCode;
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.examTable + '/one/' + filter + queryParam)
    return this.http.get(environment.apiUrl + this.examTable + '/one/' + filter + queryParam, { headers }).toPromise();
  }

  //Es esta
  allExamCompanyBackend(companyCode): Promise<any> {
    console.log('allExamCompanyBackend')
    const filter: string = companyCode;
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.examTable + '/company/' + filter + queryParam)
    return this.http.get(environment.apiUrl + this.examTable + '/company/' + filter + queryParam, { headers }).toPromise();
  }

  validatorExamBackend(examBusinessCode: string): Promise<any> {
    console.log('validatorExamBackend')
    const filter = examBusinessCode;
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.examTable + '/validator/' + filter + queryParam)
    return this.http.get(environment.apiUrl + this.examTable + '/validator/' + filter + queryParam, { headers }).toPromise();
  }

  validatorNameExamBackend(examName: string): Promise<any> {
    console.log('validatorNameExamBackend')
    // const filter = examName.replace('/', '-_');
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.examTable + '/validatorName/')
    return this.http.post(environment.apiUrl + this.examTable + '/validatorName/', { validator: examName }, { headers }).toPromise();
  }

  updateExamBackend(exam: Exam): Promise<any> {
    console.log('updateExamBackend')
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.examTable + '/', exam,)
    return this.http.put(environment.apiUrl + this.examTable + '/', exam, { headers }).toPromise();
  }

  insertExamBackend(newexam: Exam): Promise<any> {
    console.log('insertExamBackend')
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.examTable, newexam)
    return this.http.post(environment.apiUrl + this.examTable, newexam, { headers }).toPromise();
  }

  notifyExamBackend(notify): Promise<any> {
    console.log('notifyExamBackend')
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.examTable + '/notify/', notify)
    return this.http.post(environment.apiUrl + this.examTable + '/notify/', notify, { headers }).toPromise();
  }

  notifyMeetExamBackend(notify): Promise<any> {
    console.log('notifyMeetExamBackend')
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    console.log(environment.apiUrl + this.examTable + '/notify/meet/', notify)
    return this.http.post(environment.apiUrl + this.examTable + '/notify/meet/', notify, { headers }).toPromise();
  }

  notifyAssignExamBackend(notify): Promise<any> {
    console.log('notifyAssignExamBackend')
    try {
      const openedSession = this.authService.getAuthenticatedUserSession();
      const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
      console.log(environment.apiUrl + this.examTable + '/notify/assign/', notify)
      return this.http.post(environment.apiUrl + this.examTable + '/notify/assign/', notify, { headers }).toPromise();

    } catch(e){

    }
  }

  listFileS3(): Promise<any> {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName
    };
    return new Promise((resolve, reject) => {
      bucket.listObjects(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  listChannelsKVS() {
    const kinesisvideo = new KinesisVideo({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.region
    });
    var params = {
      ChannelNameCondition: {
        ComparisonOperator: 'BEGINS_WITH',
        ComparisonValue: 'mexam'
      }
    };
    return new Promise((resolve, reject) => {
      kinesisvideo.listSignalingChannels(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          console.log(data)
          resolve(data);
        }
      });
    });
  }

  uploadFileS3(file, prefixS3): Promise<any> {
    this.examProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: prefixS3 + '/' + file.name,
      Body: file,
      ACL: 'private',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.examProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }

  deleteObject(key) {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: key,
    };
    return new Promise((resolve, reject) => {
      bucket.deleteObject(params).send((err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      });
    });
  }

}
