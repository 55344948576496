import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Company } from 'src/app/models/company';
import companyGroupsTable from '../../../../../assets/config/companyTable/companyGroupsTable.json';

@Component({
  selector: 'app-company-groups-table',
  templateUrl: './company-groups-table.component.html',
  styleUrls: ['./company-groups-table.component.scss']
})
export class CompanyGroupsTableComponent implements OnInit, OnChanges {
  @Input() company = {} as Company;
  @Output() groupsDelete = new EventEmitter<any[]>();
  settings = companyGroupsTable;
  companyGroupsList = new Array();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.companyGroupsList = new Array();
    if (this.company.companyGroups !== undefined) {
      this.company.companyGroups.forEach(element => {
        this.companyGroupsList.push({
          group: element
        });
      });
    }
  }

  deleteGroups(event) {
    this.groupsDelete.emit(event);
  }

}
