import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Exam } from 'src/app/models/exam';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import * as xlsx from 'xlsx';
import { ExamQuestion } from 'src/app/models/examQuestion';
import { v4 as uuidv4 } from 'uuid';
import { ExamQuestionAnswer } from 'src/app/models/examQuestionAnswer';
import { User } from 'src/app/models/user';
import { ExamService } from 'src/app/services/exam.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exam-maintenance-field-question-import',
  templateUrl: './exam-maintenance-field-question-import.component.html',
  styleUrls: ['./exam-maintenance-field-question-import.component.scss']
})
export class ExamMaintenanceFieldQuestionImportComponent implements OnInit {
  @Input() exam = {} as Exam;
  @Output() examQuestionImportList = new EventEmitter<any>();
  @Output() getExam = new EventEmitter<any>();
  file: File;
  fileUploaded: boolean = false;
  questionList = new Array<ExamQuestion>();
  loading: boolean;

  constructor(
    private examService: ExamService,
    private toastrService: NbToastrService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  uploadFile(event) {
    const file = event[0] as File;
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.file = event[0];
      this.verifyFile();
    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show('', 'archivo no soportado', { position, status });
    }

  }

  questionCount(questionList: any) {

    const questions = questionList.map(question => question.examQuestion);
    const uniqueQuestions = [...new Set(questions)];
    return uniqueQuestions.length;

  }

  verifyFile() {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.file;
    reader.onload = (event) => {
      const data = reader.result;
      workBook = xlsx.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = xlsx.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const xlsxJsonData = jsonData;
      console.log(xlsxJsonData);
      console.log(this.questionCount(xlsxJsonData.Question))
      if (this.questionCount(xlsxJsonData.Question) > 250) {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show('', 'Solo se pueden ingresar máximo 250 preguntas por importación.', { position, status });
      } else {
        this.fileUploaded = true;
        console.log(xlsxJsonData);
        this.transformData(xlsxJsonData);
      }
    }
    reader.readAsBinaryString(file);
  }

  transformData(xlsxJsonData) {
    const questionTypeList = ['opcion multiple', 'opcion multiple b', 'casos', 'desarrollo tema'];
    let user: User;
    if (localStorage.getItem('user') !== undefined) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    const questionList = new Array<ExamQuestion>();
    if (xlsxJsonData.Question !== undefined) {
      xlsxJsonData.Question.forEach((question, questionIndex) => {
        if (question.examQuestion !== undefined && questionTypeList.includes(question.examQuestionType.toString().toLowerCase().trim())) {
          const questionExist = questionList.find((x) => x.examQuestion === this.stringToHTML(question.examQuestion));
          if (questionExist !== undefined) {
            const questionExistIndex = questionList.indexOf(questionExist);
            if (questionList[questionExistIndex].examQuestionAnswer === undefined) {
              questionList[questionExistIndex].examQuestionAnswer = new Array<ExamQuestionAnswer>();
            }
            let isCorrect = false;
            if (question.examQuestionAnswerResponseIsCorrect.toString().toLowerCase().trim() === 'correcta') {
              isCorrect = true;
            }
            questionList[questionExistIndex].examQuestionAnswer.push({
              examQuestionAnswerCode: uuidv4(),
              examQuestionAnswerResponse: question.examQuestionAnswerResponse,
              examQuestionAnswerResponseIsCorrect: isCorrect,
              examQuestionAnswerIsCorrectResponseShow: isCorrect ? 'Correcta' : 'Incorrecta',
              examQuestionAnswerCreationDate: new Date(),
              examQuestionAnswerModifyDate: new Date(),
              examQuestionAnswerCreationUser: user.userId,
              examQuestionAnswerModifyUser: user.userId,
              examQuestionAnswerMedia: undefined
            });
            questionList[questionExistIndex].examQuestionAnswerCounter++;
            let newExamType;
            let newExamQuestionAnswerCorrectCounter = 0;
            questionList[questionExistIndex].examQuestionAnswer.forEach((answer) => {
              if (answer.examQuestionAnswerResponseIsCorrect === true) {
                newExamQuestionAnswerCorrectCounter++;
              }
            });
            if (newExamQuestionAnswerCorrectCounter === 1) {
              switch (question.examQuestionType) {
                case 'opcion multiple':
                  questionList[questionExistIndex].examQuestionType = 'oneAnswer';
                  break;
                case 'opcion multiple b':
                  questionList[questionExistIndex].examQuestionType = 'oneAnswerB';
                  break;

                default:
                  questionList[questionExistIndex].examQuestionType = 'oneAnswer';
                  break;
              }
            }
            if (newExamQuestionAnswerCorrectCounter > 1) {
              questionList[questionExistIndex].examQuestionType = 'multipleAnswer';
            }
          } else {
            let isCorrect = false;
            if (question.examQuestionAnswerResponseIsCorrect !== undefined) {
              if (question.examQuestionAnswerResponseIsCorrect.toString().toLowerCase().trim() === 'correcta') {
                isCorrect = true;
              }
            }
            const answer = new Array<ExamQuestionAnswer>()
            answer.push({
              examQuestionAnswerCode: uuidv4(),
              examQuestionAnswerResponse: question.examQuestionAnswerResponse,
              examQuestionAnswerResponseIsCorrect: isCorrect,
              examQuestionAnswerIsCorrectResponseShow: isCorrect ? 'Correcta' : 'Incorrecta',
              examQuestionAnswerCreationDate: new Date(),
              examQuestionAnswerModifyDate: new Date(),
              examQuestionAnswerCreationUser: user.userId,
              examQuestionAnswerModifyUser: user.userId,
              examQuestionAnswerMedia: undefined
            });
            let type = 'oneAnswer';
            if (question.examQuestionType.toString().toLowerCase() === 'opcion multiple b') {
              type = 'oneAnswerB';
            }
            if (question.examQuestionType.toString().toLowerCase() === 'desarrollo tema') {
              type = 'themeDevelopAnswer';
            }
            if (question.examQuestionType.toString().toLowerCase() === 'casos') {
              type = 'themeCasesAnswer';
            }
            questionList.push({
              examQuestion: this.stringToHTML(question.examQuestion),
              examQuestionAnswer: answer,
              examQuestionAnswerCounter: 1,
              examQuestionAnswerOne: undefined,
              examQuestionCode: uuidv4(),
              examQuestionCreationDate: new Date(),
              examQuestionFlag: undefined,
              examQuestionModifyDate: new Date(),
              examQuestionCreationUser: user.userId,
              examQuestionModifyUser: user.userId,
              examQuestionType: type,
              examQuestionTypeControl: undefined,
              examQuestionXls: undefined,
              examQuestionAnswerXls: undefined,
              examQuestionMedia: undefined
            });
          }
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', 'error en fila # ' + (questionIndex + 2), { position, status });
        }
      });
      this.verifyData(questionList);
    }
    console.log(questionList);
  }

  stringToHTML(str: string) {
    str.replace("\n", "<br/>");
    str.replace("\ ".trim(), "");
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return new XMLSerializer().serializeToString(doc);
  };

  verifyData(questionList: ExamQuestion[]) {
    this.questionList = questionList;
    this.examQuestionImportList.emit(questionList);
  }

  importQuestions() {
    // if (this.exam.examQuestion === undefined) {
    this.exam.examQuestion = new Array<ExamQuestion>();
    // }
    if (this.questionList !== undefined) {
      this.questionList.forEach(question => {
        this.exam.examQuestion.push(question);

       
      });
      this.updateExam();
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'success';
      this.toastrService.show('', 'Actualice su navegador para visualizar las preguntas en la tabla general.', { position, status });
    }

  }

  updateExam() {
    this.loading = true;
    this.examService.updateExamBackend(this.exam)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Actualizado', { position, status });
          this.file = undefined;
          this.questionList = new Array<ExamQuestion>();
          this.examQuestionImportList.emit([]);
          this.getExam.emit();
          this.router.navigate(['/examMaintenanceFields/' + this.exam.examCode]);
        })
      .catch(
        (err: any) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        })
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  deleteAttachment() {
    this.file = undefined;
    this.fileUploaded = false;
    this.examQuestionImportList.emit([]);
  }

}
