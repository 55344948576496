import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exam-maintenance',
  templateUrl: './exam-maintenance.component.html',
  styleUrls: ['./exam-maintenance.component.scss']
})
export class ExamMaintenanceComponent implements OnInit, OnChanges {
  examList;
  loading;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }
  ngOnChanges() {
  }

  examListReceiver($event) {
    this.examList = $event;
  }

  editExam(event) {
    const examCode = event.event.data.examCode;
    this.router.navigate(['/examMaintenanceFields/' + examCode]);
  }

  deleteExam(event) {
   console.log(event)
  }

}
