import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NbDialogRef } from '@nebular/theme';


@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss']
})
export class UserConfirmationComponent implements OnInit {
  userName: string;
  validationCode: string;

  constructor(
    private authService: AuthService,
    private dialogRef: NbDialogRef<UserConfirmationComponent>) { }

  ngOnInit() {
  }

  onConfirmSubmit() {
    const usrName = this.userName;
    const validationCode = this.validationCode;
    if (this.authService.confirmUser(usrName, validationCode)) {
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }

}
