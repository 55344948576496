import { Component, OnInit } from '@angular/core';
import { Exam } from 'src/app/models/exam';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamService } from 'src/app/services/exam.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';
import { ExamMaintenanceFieldQuestionComponent } from '../exam-maintenance-field-question/exam-maintenance-field-question.component';
import * as xlsx from 'xlsx';
import questionTemplate from '../../../../assets/data/template/questionTemplate.json';
import { ExamValidation } from 'src/app/models/examValidation';

@Component({
  selector: 'app-exam-maintenance-fields',
  templateUrl: './exam-maintenance-fields.component.html',
  styleUrls: ['./exam-maintenance-fields.component.scss']
})
export class ExamMaintenanceFieldsComponent implements OnInit {
  loading = false;
  exam = {} as Exam;
  examQuestionImportList = new Array<any>();
  questionShow = false;
  questionCode;
  questionIndex: number = 0;

  activetab = [true, false, false]



  constructor(
    private route: ActivatedRoute,
    private examService: ExamService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async (params: any) => {
      const examCode = params.params.examCode;
      if (examCode !== undefined) {
        const examClone = params.params.examClone;
        await this.getExam(examCode, examClone);
      }
    });
  }

  getExam(examCode, examClone = undefined) {
    this.loading = true;
    return this.examService.oneExamBackend(examCode).then(
      (result: any) => {
        const lzw = require("node-lzw");
        //console.log(lzw.decode(result.body));
        result.body = JSON.parse(lzw.decode(result.body));
        const selectedExam = result.body;

        if (examClone !== undefined) {
          selectedExam.examCode = undefined;
        }
        if (selectedExam.examValidationFace === undefined) {
          selectedExam.examValidationFace = {
            examValidationValidate: true,
            examValidationRestrictPass: true
          } as ExamValidation;
        };
        if (selectedExam.examValidationWrist === undefined) {
          selectedExam.examValidationWrist = {
            examValidationValidate: true,
            examValidationRestrictPass: true
          } as ExamValidation;
        };
        if (selectedExam.examValidationHeadphones === undefined) {
          selectedExam.examValidationHeadphones = {
            examValidationValidate: true,
            examValidationRestrictPass: true
          } as ExamValidation;
        };
        if (selectedExam.examValidationEnvironment === undefined) {
          selectedExam.examValidationEnvironment = {
            examValidationValidate: true,
            examValidationRestrictPass: true
          } as ExamValidation;
        };
        this.exam = selectedExam;
        return;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show('', err, { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );

  }

  addExamQuestion() {
    this.questionCode = undefined;
    this.questionShow = true;
  }

  editExamQuestion(event) {
    this.questionCode = event.event.data.examQuestionCode;
    this.questionShow = true;
  }

   closeExamQuestion(event) {
    this.questionIndex = event;
    this.questionShow = false;
    this.getExam(this.exam.examCode);
  }

  deleteExamQuestion(event) {
    this.loading = true;
    const question = this.exam.examQuestion.find((x) => x.examQuestionCode === event.event.data.examQuestionCode);
    const questionIndex = this.exam.examQuestion.indexOf(question);
    this.exam.examQuestion.splice(questionIndex, 1);
    this.updateExam();

  }

  changeActiveTab(event) {
    this.activetab = [false, false, false]

    this.activetab[event] = true
  }

  updateExam() {
    this.examService.updateExamBackend(this.exam)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Actualizado', { position, status });
          this.getExam(this.exam.examCode);
        })
      .catch(
        (err: any) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        })
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  downloadQuestionTemplate() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(questionTemplate);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Question');
    xlsx.writeFile(wb, 'question-template.xlsx');
  }

  examQuestionImport(event) {
    this.examQuestionImportList = event;
    this.examQuestionImportList.forEach((question) => {
      switch (question.examQuestionType) {
        case 'oneAnswer':
          question.examQuestionTypeShow = 'Opción multiple con una respuesta';
          break;
        case 'oneAnswerB':
          question.examQuestionTypeShow = 'Opción multiple con una respuesta B';
          break;
        case 'multipleAnswer':
          question.examQuestionTypeShow = 'Opción multiple';
          break;
        case 'themeDevelopAnswer':
          question.examQuestionTypeShow = 'Desarrollo de tema';
          break;
      }
    });
  }

}
