import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';
// import reportsUserResultsTableSettings from '../../../../assets/config/reportsTable/reportsUserResultsTableSettings.json';
import { User } from 'src/app/models/user';
import { ExamService } from 'src/app/services/exam.service';
import { ReportUserResultsViewComponent } from './report-user-results-view/report-user-results-view.component';
import { ActivatedRoute } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as xlsx from 'xlsx';

import { reportUserResultTableSettings  } from "../../../shared/TableSettings/reportsTable/reportUsersResultTableSettings";
import reportFilterSettings from '../../../../assets/config/filterTable/reports/reportsUserResultsFilters.json';

@Component({
  selector: 'app-report-user-results',
  templateUrl: './report-user-results.component.html',
  styleUrls: ['./report-user-results.component.scss']
})
export class ReportUserResultsComponent implements OnInit {
  loading: boolean;
  userExamList = new Array<any>();
  filter = 'all';
  reportFSettings = reportFilterSettings;


  settings = reportUserResultTableSettings;
  checkList = new LocalDataSource(this.userExamList);

  constructor(
    private userService: UserService,
    private examService: ExamService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(async (params: any) => {
      const active = params.params.active;
      if (active !== undefined) {
        console.log(active)
        this.filter = active;
      }
      await this.reloadReport();
    });

  }

  async reloadReport() {
    const userCognito = await this.getUserCognito();
    const userExamBackend = await this.getUserExamsBackend();
    const examBackend = await this.getExams();
    const userCompleteData = await this.combineUser(userCognito, userExamBackend, examBackend);
    console.log('userCompleteData');
    console.log(userCompleteData);
    this.userExamList = userCompleteData;
    this.checkList = new LocalDataSource(this.userExamList);
    console.log('this.checkList');
    console.log(this.checkList);
  }

  getExams() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    return this.examService.allExamCompanyBackend(userLogged.company.companyCode).then(
      (result) => {
        const exams = result.body
        return exams;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  cleanData() {
    this.checkList.setFilter([]);
  }

  filterData(event) {
    let filters = []
    event.forEach(element => {
      filters.push({
        field: element.fieldname,
        search: element.value
      });
    });
    this.checkList.setFilter(filters)

  }

  combineUser(userCognito, userExamBackend, examBackend) {


    const userCompleteData = new Array<any>();
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    userCognito.forEach(cognitoUser => {
      const name = cognitoUser.Attributes.find((x) => x.Name === 'name');
      const email = cognitoUser.Attributes.find((x) => x.Name === 'email');
      const company = cognitoUser.Attributes.find((x) => x.Name === 'custom:company');
      const userExam = userExamBackend.find((x) => x.userCode === cognitoUser.Username);
      if (company.Value === userLogged.company.companyCode) {
        if (userExam !== undefined) {
          if (userExam.userAssign !== undefined) {
            console.log('------------userExam------------')
            console.log(userExam)
          
            userExam.userAssign.forEach(async (userAssign) => {
              const exam = examBackend.find((x) => x.examCode === userAssign.userExamCode);
              
              
              
              const assign = userAssign;
                console.log('assign')
                console.log(assign)
              //const assign = await this.getAssign(userAssign.userStreamCode);              
              let correctAnswer = 0;
              let incorrectAnswer = 0;
              let noResponseAnswer = 0;
              let themeAnswer = 0;

              if (assign.userExam != undefined) {
                assign.userExam.forEach((questionResolves) => {
                  if (questionResolves.examQuestionType === 'oneAnswer' || questionResolves.examQuestionType === 'oneAnswerB' || questionResolves.examQuestionType === 'multipleAnswer') {
                    noResponseAnswer++;
                  }
                  
                });

              
              }

              if (
                assign.userExam !== undefined && assign.userExam !== null &&
                assign.userExamResults !== undefined && exam !== undefined
              ) {
                
                assign.userExamResults.forEach((questionResolve) => {
                  /* console.log('questionResolve')
                  console.log(questionResolve) */
                  questionResolve.isCorrect = false;
                  const question = assign.userExam.find((x) => x.examQuestionCode === questionResolve.examQuestionCode);
                  if (questionResolve.examQuestionType === 'oneAnswer') {
                    let correctAnswerOne;
                    question.examQuestionAnswer.forEach(answer => {
                     /*  console.log('question')
                      console.log(question) */
                      if (answer.examQuestionAnswerResponseIsCorrect === true) {
                        correctAnswerOne = answer.examQuestionAnswerResponse;
                        question.examQuestionAnswerOne = answer.examQuestionAnswerResponse
                        
                      }
                    });
                    let userCorrectAnswerOne = questionResolve.examQuestionAnswerOne
                    //console.log('userCorrectAnswerOne');
                    //console.log(userCorrectAnswerOne);
                    if (userCorrectAnswerOne !== undefined) {
                      if (correctAnswerOne === userCorrectAnswerOne) {
                        correctAnswer++;
                        noResponseAnswer--;
                        questionResolve.isCorrect = true;
                      } else if (userCorrectAnswerOne !='' &&  correctAnswerOne != userCorrectAnswerOne) {
                        incorrectAnswer++;
                        noResponseAnswer--;
                      }
                    }
                  }
                  if (questionResolve.examQuestionType === 'oneAnswerB') {
                    let correctAnswerOneB;
                    question.examQuestionAnswer.forEach(answer => {
                      if (answer.examQuestionAnswerResponseIsCorrect === true) {
                        correctAnswerOneB = answer.examQuestionAnswerResponse;
                        question.examQuestionAnswerOne = answer.examQuestionAnswerResponse
                      }
                    });
                    let userCorrectAnswerOneB = questionResolve.examQuestionAnswerOne
                    if (userCorrectAnswerOneB !== undefined) {
                      if (correctAnswerOneB === userCorrectAnswerOneB) {
                        correctAnswer++;
                        noResponseAnswer--;
                        questionResolve.isCorrect = true;
                      } else if (userCorrectAnswerOneB !='' &&  correctAnswerOneB != userCorrectAnswerOneB) {
                        incorrectAnswer++;
                        noResponseAnswer--;
                      }
                    }
                  }
                  if (questionResolve.examQuestionType === 'multipleAnswer') {
                    let isCorrect = true;
                    let isResolve = false;
                    questionResolve.examQuestionAnswer.forEach((userAnswer) => {
                      if (userAnswer.examQuestionAnswerResponseIsCorrect === true) {
                        isResolve = true;
                      }
                    });
                    if (isResolve) {
                      questionResolve.examQuestionAnswer.forEach((userAnswer) => {
                        question.examQuestionAnswer.forEach((answer) => {
                          if (userAnswer.examQuestionAnswerCode === answer.examQuestionAnswerCode) {
                            if (userAnswer.examQuestionAnswerResponseIsCorrect !== answer.examQuestionAnswerResponseIsCorrect) {
                              isCorrect = false;
                            }
                          }
                        });
                      });
                      if (isCorrect) {
                        correctAnswer++;
                        noResponseAnswer--
                        questionResolve.isCorrect = true;
                      } else {
                        incorrectAnswer++;
                        noResponseAnswer--
                      }
                    }
                  }
                  if (questionResolve.examQuestionType === 'themeDevelopAnswer') {
                    let respuesta = questionResolve.examQuestionAnswerOne
                    var new_resp = respuesta.replace(/(<([^>]+)>)/ig,'');
                    new_resp = new_resp.replace('&nbsp;', '')
                    questionResolve.examQuestionAnswerOne = new_resp;
                    themeAnswer++;
                  }
                });
                let showInList: boolean = false;
                let hasFinish: any = assign.userExamEnd === undefined ? 'en curso' : new Date(assign.userExamEnd).toLocaleString('es-GT');
                console.log('hasFinish')
                console.log(hasFinish)
                if (hasFinish === 'en curso') {
                  const endDate = await this.checkEnd(assign, exam);
                  const currentDateTime = new Date();
                  if (currentDateTime > endDate) {
                    assign.userExamEnd = new Date(endDate);
                    hasFinish = new Date(assign.userExamEnd).toLocaleString('es-GT')
                  }
                }
                if (this.filter === 'active' && hasFinish === 'en curso') { showInList = true; }
                else if (this.filter === 'finish' && hasFinish !== 'en curso') { showInList = true; }
                else if (this.filter === 'all') { showInList = true; }
                else{console.log(' --- NO HAY EXAMENES ----')}
                if (showInList === true) {
                  userCompleteData.push({
                    userCode: cognitoUser.Username,
                    userName: name.Value,
                    userEmail: email.Value,
                    examStartDateTime: new Date(assign.userExamStartDateTime).toLocaleString('es-GT'),
                    examStart: new Date(assign.userExamStart).toLocaleString('es-GT'),
                    examEnd: assign.userExamEnd === undefined ? 'en curso' : new Date(assign.userExamEnd).toLocaleString('es-GT'),
                    examStreamCode: assign.userStreamCode,
                    examCode: exam.examCode,
                    examTime: exam.examTime,
                    examName: exam.examName,
                    userResponses: assign.userExamResults,
                    userAssignExam: assign.userExam,
                    examCorrectAnswers: correctAnswer,
                    examIncorrectAnswers: incorrectAnswer,
                    examNoResponse: noResponseAnswer,
                    examThemeQuestions: themeAnswer,
                    
                  });
                }
              }
            });
          }else{
            console.log('sin examenes')
          }
        }
      }
     
    });
    return userCompleteData;
  }

  // getAssign(streamCode) {
  //   this.loading = true;
  //   return this.userService.oneUserAssignBackend(streamCode).then(
  //     (result) => {
  //       const userExamBackend = result.body
  //       return userExamBackend;
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show(
  //         '',
  //         err.message,
  //         { position, status });
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  checkEnd(assign, exam) {
    const userAssign = assign;
    const startDate = new Date(userAssign.userExamStartDateTime);
    const validationDate = new Date(startDate);
    const endDate = new Date(startDate);
    validationDate.setMinutes(validationDate.getMinutes() - parseInt(exam.examPreValidationMinutes, 0));
    endDate.setMinutes(endDate.getMinutes() + parseInt(exam.examTime, 0));
    return endDate;
  }

  getUserCognito() {
    this.loading = true;
    return this.userService.allUserCognito().then(
      (result) => {
        const userCognito = result.Users
        return userCognito;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getUserExamsBackend() {
    this.loading = true;
    return this.userService.allUserExamBackend().then(
      (result) => {
        const userExamBackend = result.body
        return userExamBackend;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  reviewExam(event) {
    this.dialogService.open(ReportUserResultsViewComponent,
      {
        context: {
          exam: event.data,
          streamCode: event.data.examStreamCode,
          userCode: event.data.userCode
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (exam) => {
        });
  }

  async downloadToExcel() {
    const checkList = await this.checkList.getFilteredAndSorted()
    const exportFile = new Array<any>();
    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        json[this.settings.columns[item].title] = element[item];
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'resultados.xlsx');
  }

}
