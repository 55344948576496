import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-user-face-id',
  templateUrl: './user-face-id.component.html',
  styleUrls: ['./user-face-id.component.scss']
})
export class UserFaceIdComponent implements OnInit {
  enableNextButtonDocument = false;
  enableNextButtonFace = false;
  photosForRekognition = {} as {
    documentPhoto: { event, s3BucketKey },
    userPhoto: { event, s3BucketKey }
  };
  loading: boolean;
  company;

  @ViewChild('stepper', { static: false }) private stepper;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private toastrService: NbToastrService,
    private companyService: CompanyService,
  ) { }

  async ngOnInit() {
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    this.company = await this.getCompany(userLogged.company.companyCode);
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        const company = result.body;
        return company;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async snapshotDocumentPhoto(event) {
    this.photosForRekognition.documentPhoto = { event, s3BucketKey: undefined };
    this.enableNextButtonDocument = true;
    // this.stepper.next();
  }

  async snapshotUserPhoto(event) {
    this.photosForRekognition.userPhoto = { event, s3BucketKey: undefined };
    this.enableNextButtonFace = true;
    // this.stepper.next();
  }

  async uploadAndCompare() {
    if (this.company.companyFaceIdDocument === true) {
      this.photosForRekognition.documentPhoto.s3BucketKey =
        await this.uploadTos3(this.photosForRekognition.documentPhoto.event, 'document');
    }
    this.photosForRekognition.userPhoto.s3BucketKey =
      await this.uploadTos3(this.photosForRekognition.userPhoto.event, 'user');
    let user;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user')) as User;
    }
    const photos = {
      userName: user.name,
      withID: this.company.companyFaceIdDocument,
      typeofdoc: 'general',
      s3_object1: {
        bucketName: environment.bucketName,
        objectKey: this.photosForRekognition.documentPhoto === undefined ? undefined : this.photosForRekognition.documentPhoto.s3BucketKey
      },
      s3_object2: {
        bucketName: environment.bucketName,
        objectKey: this.photosForRekognition.userPhoto.s3BucketKey
      },
      functionName: environment.rekognitionFaceIdWithDoc,
    };
    const rekognitionFaceId = await this.verifyWithService(photos);
    const serviceResponse = JSON.parse(rekognitionFaceId.Payload);
    const serviceResponseBody = JSON.parse(serviceResponse.body);
    if (serviceResponseBody.code === 20) {
      const faceId = JSON.parse(serviceResponseBody.msg);
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'success';
      this.toastrService.show(
        '',
        'Identidad confirmada',
        { position, status });

      await this.updateUser(faceId.faceId);
    } else {
      const msg = serviceResponseBody.msg;
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'warning';
      this.toastrService.show(
        '',
        msg,
        { position, status });
    }


  }

  verifyWithService(photos) {
    this.loading = true;
    return this.userService.rekognitionFaceIdWithDoc(photos).then(
      (result) => {
        return result;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  uploadTos3(event, fileName) {
    this.loading = true;
    let user;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user')) as User;
    }
    const prefixS3 = 'company/' +
      user.company.companyCode +
      '/user/' +
      user.userId +
      '/rekognitionImage';
    return this.userService.uploadFileS3ForRekognition(event, prefixS3, fileName)
      .then(
        (result) => {
          return result.Key;
        }
      )
      .catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
        }
      )
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  restart() {
    this.router.navigate(['/examAssigned']);
  }

  updateUser(faceId) {
    this.loading = true;
    let user;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user')) as User;
    }
    user.faceId = faceId;
    this.userService.updateUserFaceId(user).then(
      (result) => {
        localStorage.setItem('user', JSON.stringify(user));
        this.router.navigate(['/examAssigned']);
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

}
