import { Component, OnInit } from '@angular/core';
import {
  NbDialogService,
  NbGlobalPosition, NbThemeService,
  NbToastrService, NbGlobalPhysicalPosition, NbComponentStatus
} from '@nebular/theme';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import themeListJson from '../../../../assets/data/themes/themes.json';
import roleListJson from '../../../../assets/data/userRole/userRole.json';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserMyProfileImageComponent } from '../user-my-profile-image/user-my-profile-image.component';
import { UserChangePasswordComponent } from '../user-change-password/user-change-password.component';

@Component({
  selector: 'app-user-my-profile',
  templateUrl: './user-my-profile.component.html',
  styleUrls: ['./user-my-profile.component.scss']
})
export class UserMyProfileComponent implements OnInit {
  user = {} as User;
  userRoleShow;

  themes = themeListJson;
  loading: boolean;

  constructor(
    private dialogService: NbDialogService,
    private userService: UserService,
    private authService: AuthService,
    private themeService: NbThemeService,
    private toastrService: NbToastrService,
    private router: Router,
  ) { }

  async ngOnInit() {
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    await this.getUser(userLogged.userId);
  }

  getUser(userCode) {
    this.loading = true;
    this.userService.oneUserCognito(userCode).then(
      (result: any) => {
        console.log(result);
        const userCognito = result;
        const userId = userCognito.Username;
        const email = userCognito.UserAttributes.find((x) => x.Name === 'email');
        const name = userCognito.UserAttributes.find((x) => x.Name === 'name');
        const picture = userCognito.UserAttributes.find((x) => x.Name === 'picture');
        const role = userCognito.UserAttributes.find((x) => x.Name === 'custom:role');
        const group = userCognito.UserAttributes.find((x) => x.Name === 'custom:group');
        const company = userCognito.UserAttributes.find((x) => x.Name === 'custom:company');
        const theme = userCognito.UserAttributes.find((x) => x.Name === 'custom:theme');
        const status = userCognito.Enabled;
        if (role.Value === 'administrator') { roleListJson.push({ type: 'Administrador', value: 'administrator' }); }
        const roleShow = roleListJson.find((x) => x.value === role.Value);
        let groupShow;
        if (group === undefined) { groupShow = 'Sin Grupo'; } else { groupShow = group.Value; }
        this.user = {
          userId,
          name: name.Value,
          email: email.Value,
          group: groupShow,
          userRole: roleShow.value,
          company: company.Value,
          password: undefined,
          picture: picture.Value,
          theme: theme.Value,
          userRekognitionImage: undefined,
          userStatus: status,
          username: userId,
        };
        this.userRoleShow = roleShow.type;
      
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show('', err, { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );

  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  addUserImage() {
    this.dialogService.open(UserMyProfileImageComponent,
      {
        context: {
          user: this.user
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (user) => {
          this.user = user;
        });
  }

  saveUser() {
    const user = {
      userId: this.user.userId,
      userName: this.user.name,
      userTheme: this.user.theme,
      userPicture: this.user.picture,
    };
    this.userService.updateUserCognitoProfile(user).then(
      (result) => {
        let userLogged: User = JSON.parse(localStorage.getItem('user'));
        userLogged.picture = this.user.picture;
        localStorage.setItem('user', JSON.stringify(userLogged));
        this.authService.userProfileChanged.next(true);
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show(status || 'Success', 'Guardado', { position, status });
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          status || 'Danger',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  cancel() {
    this.router.navigateByUrl('/');
  }

  changePassword() {
    this.dialogService.open(UserChangePasswordComponent,
      {
        context: {
          user: this.user
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      });
  }

}
