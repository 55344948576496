import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Exam } from 'src/app/models/exam';
import { ExamService } from 'src/app/services/exam.service';
import { NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { isNumber } from 'util';

@Component({
  selector: 'app-exam-maintenance-field-info',
  templateUrl: './exam-maintenance-field-info.component.html',
  styleUrls: ['./exam-maintenance-field-info.component.scss']
})
export class ExamMaintenanceFieldInfoComponent implements OnInit, OnChanges {
  @Input() exam = {} as Exam;
  loading = false;
  statusAlert = 'danger';
  messageAlert = '';
  hoursAndMinutes: {
    hour,
    minute
  } = { hour: '', minute: '' };

  constructor(
    private examService: ExamService,
    private toastrService: NbToastrService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.exam.examCode === undefined) { this.exam.examStatus = true; }
    if (this.exam.examStatus === undefined) { this.exam.examStatus = true; }
    if (this.exam.examTime !== undefined) {
      this.hoursAndMinutes.hour = Math.trunc(this.exam.examTime / 60);
      this.hoursAndMinutes.minute = this.exam.examTime - Math.trunc(this.hoursAndMinutes.hour * 60)
    }
  }

  validateEmptyFields(): boolean{
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    let message = 'Por favor, ingrese ';
    let errors: string[] = [];
    if(this.exam.examBusinessCode === undefined || this.exam.examBusinessCode ==='') {
      errors.push('el código del examen');
    }
    if(this.exam.examName === undefined || this.exam.examName ==='') {
      errors.push('el nombre del examen');
    }
    if(this.hoursAndMinutes.hour === undefined || this.hoursAndMinutes.hour ==='') {
      errors.push('las horas necesarias para el examen');
    }
    if(this.hoursAndMinutes.minute === undefined || this.hoursAndMinutes.minute ==='') {
      errors.push('los minutos necesarios para el examen');
    }
    if(this.exam.examPreValidationMinutes === undefined || this.exam.examPreValidationMinutes ==='') {
      errors.push('el tiempo necesario necesario para las validaciones');
    }
    if(this.exam.examCounterReEntry === undefined || this.exam.examCounterReEntry ==='') {
      errors.push('número de reingresos permitidos al estudiante');
    }
    if(this.exam.examCounterAssign === undefined || this.exam.examCounterAssign ==='') {
      errors.push('número de asignaiones permitidas a un estudiante');
    }
    if(this.exam.examCounterNotify === undefined || this.exam.examCounterNotify ==='') {
      errors.push('número de salidas del examen sin notificar del estudiante');
    }
    if(this.exam.examNotifyEmail === undefined || this.exam.examNotifyEmail ==='') {
      errors.push('correo electrónico para notificaciones');
    }
    if (errors.length) {
      for (const index in errors) {
        message += errors[parseInt(index)];
        message += parseInt(index) == errors.length - 1? '.': parseInt(index) == errors.length - 2? ' y ': ', ';
      }
      this.toastrService.show('', message, { position, status });
      return false;
    }
    return true;
  }

  closeExam() {
    this.router.navigate(['/examMaintenance/']);
  }

  async saveExam() {

    if (await this.validateEmptyFields()){
      this.loading = true;
      this.getExamValues();
      if (await this.validateExamFields() === true) {
        this.validateExamCode();
      }
      else {
        this.loading = false;
      }
    }
  }

  validateExamFields() {
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    let message;
    if (this.exam.examTime === undefined || isNaN(this.exam.examTime) === true) {
      message = 'El tiempo del examen debe ser entre 1 y 360.';
    }
    if (this.exam.examBusinessCode === undefined) {
      message = 'El código de examen no puede ser vacio.';
    }
    if (this.exam.examBusinessCode === '') {
      message = 'El código de examen no puede ser vacio.';
    }
    if (this.exam.examTime < 1) {
      message = 'El tiempo del examen no puede ser menor a 1.';
    }
    if (this.exam.examTime > 360) {
      message = 'El tiempo del examen no puede ser mayor a 360 minutos.';
    }
    if (isNaN(parseInt(this.exam.examPreValidationMinutes)) === true) {
      message = 'El tiempo de validación debe ser entre 1 y 30.';
    }
    if (parseInt(this.exam.examPreValidationMinutes) < 1) {
      message = 'El tiempo de validación no puede ser menos de 1.';
    }
    if (parseInt(this.exam.examPreValidationMinutes) > 30) {
      message = 'El tiempo de validación no puede ser mayor de 30.';
    }
    if (isNaN(parseInt(this.exam.examCounterReEntry)) === true) {
      message = 'La cantidad de reingreso debe ser entre 1 y 15.';
    }
    if (parseInt(this.exam.examCounterReEntry) < 1) {
      message = 'La cantidad de reingreso no puede ser menos de 1.';
    }
    if (parseInt(this.exam.examCounterReEntry) > 15) {
      message = 'La cantidad de reingreso no puede ser mayor de 15.';
    }
    if (isNaN(parseInt(this.exam.examCounterAssign)) === true) {
      message = 'La cantidad de asignaciones debe ser entre 1 y 15.';
    }
    if (parseInt(this.exam.examCounterAssign) < 1) {
      message = 'La cantidad de asignaciones no puede ser menos de 1.';
    }
    if (parseInt(this.exam.examCounterAssign) > 15) {
      message = 'La cantidad de asignaciones no puede ser mayor de 15.';
    }
    if (isNaN(parseInt(this.exam.examCounterNotify)) === true) {
      message = 'La cantidad mínima de perdida de atención debe ser entre 1 y 15.';
    }
    if (parseInt(this.exam.examCounterNotify) < 1) {
      message = 'La cantidad mínima de perdida de atención no puede ser menos de 1.';
    }
    if (parseInt(this.exam.examCounterNotify) > 30) {
      message = 'La cantidad mínima de perdida de atención no puede ser mayor de 15.';
    }
    if (message !== undefined) { this.toastrService.show('', message, { position, status }); return false; }
    return true;
  }

  getExamValues() {
    // Company code
    let user: User;
    if (localStorage.getItem('user') !== undefined) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    const companyCode = user.company.companyCode;
    this.exam.examCompanyCode = companyCode;
    this.exam.examName.trim();
    if (this.exam.examCode === undefined) {
      this.exam.examCreationDate = new Date();
      this.exam.examCreationUser = user.userId;
    }
    this.exam.examModifyDate = new Date();
    this.exam.examModifyUser = user.userId;
    this.exam.examNameForFilter = this.exam.examName.toLowerCase();
  }

  validateExamName() {
    this.loading = true;
    this.examService.validatorNameExamBackend(this.exam.examName.toLowerCase())
      .then(
        (result: any) => {
          const validatedExam: Exam[] = result.body;
          const validatorLength = validatedExam.length;
          if (this.exam.examCode) {
            if (validatorLength === 0) {
              this.updateExam();
            } else {
              const filteredValidatedExam: Exam[] = validatedExam.filter(exam => {
                exam.examCode = this.exam.examCode;
              });
              if (filteredValidatedExam.length > 0) {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'danger';
                this.toastrService.show('', 'Nombre de examen ya utilizado.', { position, status });
              } else {
                this.updateExam();
              }
            }
          } else {
            if (validatorLength === 0) {
              this.insertExam();
            } else {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show('', 'Nombre de examen ya utilizado.', { position, status });
            }
          }
        })
      .catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        }
      )
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  validateExamCode() {
    this.loading = true;
    this.examService.validatorExamBackend(this.exam.examBusinessCode)
      .then(
        (result: any) => {
          const validatedExam: Exam[] = result.body;
          const validatorLength = validatedExam.length;
          if (this.exam.examCode) {
            if (validatorLength === 0) {
              this.validateExamName();
            } else {
              const filteredValidatedExam: Exam[] = validatedExam.filter(exam => {
                exam.examCode = this.exam.examCode;
              });
              if (filteredValidatedExam.length > 0) {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'danger';
                this.toastrService.show('', 'Código de examen ya utilizado.', { position, status });
              } else {
                this.validateExamName();
              }
            }
          } else {
            if (validatorLength === 0) {
              this.validateExamName();
            } else {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show('', 'Código de examen ya utilizado.', { position, status });
            }
          }
        })
      .catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        }
      )
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  insertExam() {
    this.loading = true;
    this.examService.insertExamBackend(this.exam)
      .then(
        (result: any) => {
          this.exam.examCode = result.examCode;
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Examen guardado', { position, status });
          this.router.navigate(['/examMaintenanceFields/' + this.exam.examCode]);
        })
      .catch(
        (err: any) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        })
      .finally(
        () => {
          this.loading = false;
        });
  }

  updateExam() {
    this.loading = true;
    this.examService.updateExamBackend(this.exam)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Examen actualizado', { position, status });
        })
      .catch(
        (err: any) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        })
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  // Validations
  validateExamDuration(event) {
    if (this.exam.examTime !== undefined) {
      if (this.exam.examTime !== '') {
        const examTime = Number(this.exam.examTime);
        this.statusAlert = 'warning';
        this.messageAlert = '';
        if (examTime < 1 || examTime > 360) { this.messageAlert = 'La duración del examen debe ser entre 1 y 360 minutos (6 horas)'; return; }
        //if (isNaN(examTime)) { this.messageAlert = 'La duración del examen debe ser un numero'; return; }
        //if (examTime % 1 !== 0) { this.messageAlert = 'La duración del examen debe ser un numero entero no un decimal'; return; }
      }
    }
  }
  validateExamDurationHour(event) {
    if (this.hoursAndMinutes.hour !== undefined) {
      if (this.hoursAndMinutes.hour !== '') {
        const examTime = Number(this.hoursAndMinutes.hour);
        if (this.hoursAndMinutes.hour.length <= 3) {
          this.statusAlert = 'warning';
          this.messageAlert = '';
          if (examTime < 0 || examTime > 6) { this.messageAlert = 'La duración debe ser entre 0 y 6 horas'; return; }
          //if (isNaN(examTime)) { this.messageAlert = 'La duración del examen debe ser un numero'; return; }
          //if (examTime % 1 !== 0) { this.messageAlert = 'La duración del examen debe ser un numero entero no un decimal'; return; }
        }
      }
      this.exam.examTime = Number(parseInt(this.hoursAndMinutes.minute) + (parseInt(this.hoursAndMinutes.hour) * 60));
      this.validateExamDuration(event);
    }
  }
  validateExamDurationMinute(event) {
    if (this.hoursAndMinutes.minute !== undefined) {
      if (this.hoursAndMinutes.minute !== '') {
        const examTime = Number(this.hoursAndMinutes.minute);
        if (this.hoursAndMinutes.minute.length <= 2) {
          this.statusAlert = 'warning';
          this.messageAlert = '';
          if (examTime < 0 || examTime > 59) { this.messageAlert = 'La duración debe ser entre 0 minuto y 59 minutos'; return; }
          //if (isNaN(examTime)) { this.messageAlert = 'La duración del examen debe ser un numero'; return; }
          //if (examTime % 1 !== 0) { this.messageAlert = 'La duración del examen debe ser un numero entero no un decimal'; return; }
        }
      }
      this.exam.examTime = Number(parseInt(this.hoursAndMinutes.minute) + (parseInt(this.hoursAndMinutes.hour) * 60));
      this.validateExamDuration(event);
    }
  }

  validateExamPreValidationMinutes(event) {
    if (this.exam.examPreValidationMinutes !== undefined) {
      if (this.exam.examPreValidationMinutes !== '') {
        const examTime = Number(this.exam.examPreValidationMinutes);
        if (this.exam.examPreValidationMinutes.length <= 3) {
          this.statusAlert = 'warning';
          this.messageAlert = '';
          if (examTime < 1 || examTime > 30) {
            this.messageAlert =
              'El tiempo previo para validaciones del examen debe ser entre 1 y 30 minutos';
            return;
          }
          //if (isNaN(examTime)) { this.messageAlert = 'El tiempo previo para validaciones del examen debe ser un numero'; return; }
          /*if (examTime % 1 !== 0) {
            this.messageAlert =
              'El tiempo previo para validaciones del examen debe ser un numero entero no un decimal';
            return;
          }*/
        }
      }
    }
  }

  validateExamCounterReEntry(event) {
    if (this.exam.examCounterReEntry !== undefined) {
      if (this.exam.examCounterReEntry !== '') {
        const examTime = Number(this.exam.examCounterReEntry);
        if (this.exam.examCounterReEntry.length <= 3) {
          this.statusAlert = 'warning';
          this.messageAlert = '';
          if (examTime < 1 || examTime > 15) {
            this.messageAlert =
              'La cantidad de reingresos del examen debe ser entre 1 y 15';
            return;
          }
          // if (isNaN(examTime)) { this.messageAlert = 'La cantidad de reingreso del examen debe ser un numero'; return; }
          // if (examTime % 1 !== 0) {
          //   this.messageAlert =
          //     'La cantidad de reingreso del examen debe ser un numero entero no un decimal';
          //   return;
          // }
        }
      }
    }
  }

  validateExamCounterAssign(event) {
    if (this.exam.examCounterAssign !== undefined) {
      if (this.exam.examCounterAssign !== '') {
        const examTime = Number(this.exam.examCounterAssign);
        if (this.exam.examCounterAssign.length <= 3) {
          this.statusAlert = 'warning';
          this.messageAlert = '';
          if (examTime < 1 || examTime > 15) {
            this.messageAlert =
              'La cantidad de asignaciones del examen debe ser entre 1 y 15';
            return;
          }
          // if (isNaN(examTime)) { this.messageAlert = 'La cantidad de asignaciones del examen debe ser un numero'; return; }
          // if (examTime % 1 !== 0) {
          //   this.messageAlert =
          //     'La cantidad de asignaciones del examen debe ser un numero entero no un decimal';
          //   return;
          // }
        }
      }
    }
  }

  keyPaste(event, value: string) {
    let isnum = typeof(value) === 'number';
    if (!isnum) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  validateExamCounterNotify(event) {
    if (this.exam.examCounterNotify !== undefined) {
      if (this.exam.examCounterNotify !== '') {
        const examTime = Number(this.exam.examCounterNotify);
        if (this.exam.examCounterNotify.length <= 3) {
          this.statusAlert = 'warning';
          this.messageAlert = '';
          if (examTime < 1 || examTime > 15) {
            this.messageAlert =
              'La cantidad de notificaciones del examen debe ser entre 1 y 15';
            return;
          }
          // if (isNaN(examTime)) { this.messageAlert = 'La cantidad de notificación del examen debe ser un numero'; return; }
          // if (examTime % 1 !== 0) {
          //   this.messageAlert =
          //     'La cantidad de notificación del examen debe ser un numero entero no un decimal';
          //   return;
          // }
        }
      }
    }
  }

}
