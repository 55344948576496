import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import examQuestionTableSettings from '../../../../assets/config/examTable/examQuestionTableSettings.json';
import { ExamQuestion } from 'src/app/models/examQuestion';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-exam-maintenance-field-question-table',
  templateUrl: './exam-maintenance-field-question-table.component.html',
  styleUrls: ['./exam-maintenance-field-question-table.component.scss']
})
export class ExamMaintenanceFieldQuestionTableComponent implements OnInit, OnChanges {
  @Input() examQuestionList: any[];
  @Input() questionIndex: number;

  @Output() examQuestionEdit = new EventEmitter<any>();
  @Output() examQuestionDelete = new EventEmitter<any>();
  @Output() updateQuestion = new EventEmitter<any>();
  loading;

  settings = examQuestionTableSettings;

  source;



  constructor() { }

  ngOnInit() {
      this.source = new LocalDataSource(this.examQuestionList);
  }
  ngOnChanges() {
    try {
      this.examQuestionList.forEach((question) => {
        if (question.examQuestionAnswerCounter === undefined) {
          question.examQuestionAnswerCounter = 0;
        }
        switch (question.examQuestionType) {
          case 'oneAnswer':
            question.examQuestionTypeShow = 'Opción multiple con una respuesta';
            break;
          case 'oneAnswerB':
            question.examQuestionTypeShow = 'Opción Verdadero y Falso';
            break;
          case 'multipleAnswer':
            question.examQuestionTypeShow = 'Opción multiple';
            break;
          case 'themeDevelopAnswer':
            question.examQuestionTypeShow = 'Desarrollo de tema';
            question.examQuestionAnswerCounter = 1;
            break;
          case 'themeCasesAnswer':
            question.examQuestionTypeShow = 'Casos';
            question.examQuestionAnswerCounter = 1;
            break;
        }

      });

      console.log(this.questionIndex);

      if(this.questionIndex === -1) {
          this.source.setPage(Math.ceil(this.examQuestionList.length / this.settings.pager.perPage));
      } else {
          this.source.setPage(Math.ceil(this.questionIndex / this.settings.pager.perPage));
      }

    } catch(e) {
    }
  }

  editExamQuestion(event) {
    this.examQuestionEdit.emit({ event, readOnly: 'false' });
  }

  deleteExamQuestion(event) {
    this.examQuestionDelete.emit({ event, readOnly: 'false' });
  }

}
