import { Component, OnInit } from '@angular/core';
import reportExamChannelTableSettings from '../../../../assets/config/reportsTable/reportExamChannelTableSettings.json';
import reportFilterSettings from '../../../../assets/config/filterTable/reports/reportsExamChannelTableFilters.json';
import { UserService } from 'src/app/services/user.service';
import { ExamService } from 'src/app/services/exam.service';
import { NbToastrService, NbDialogService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbWindowService } from '@nebular/theme';
import { ReportExamChannelViewerComponent } from './report-exam-channel-viewer/report-exam-channel-viewer.component';
import { User } from 'src/app/models/user';
import { MeetComponent } from '../../meet/meet.component';
import { $WebSocket, WebSocketSendMode } from 'angular2-websocket/angular2-websocket'
import { environment } from 'src/environments/environment';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-report-exam-channel-list',
  templateUrl: './report-exam-channel-list.component.html',
  styleUrls: ['./report-exam-channel-list.component.scss']
})
export class ReportExamChannelListComponent implements OnInit {
  loading: boolean;
  userExamList = new Array<any>();
  userExamLocalData = new LocalDataSource();

  settings = reportExamChannelTableSettings;
  reportFSettings = reportFilterSettings;

  constructor(
    private userService: UserService,
    private examService: ExamService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private windowService: NbWindowService,
  ) { }

  async ngOnInit() {
    this.settings['rowClassFunction'] = (row) => {
      if (row.data.help === 'Si') {
        return 'good';
      }
      return '';
    };
    await this.reloadReport();

  }

  async reloadReport() {
    const online = await this.getOnlineUser();
   
    const channels = await this.getChannels();
    const userCognito = await this.getUserCognito();
    const userExamBackend = await this.getUserExamsBackend();
    const userCompleteData = await this.combineUser(userCognito, userExamBackend, channels, online);
    this.userExamList = userCompleteData;
    this.userExamLocalData = new LocalDataSource(this.userExamList);
  }

  cleanData() {
    this.userExamLocalData.setFilter([]);
  }

  filterData(event) {
    let filters = []
    event.forEach(element => {
      filters.push({
        field: element.fieldname,
        search: element.value
      });
    });
    this.userExamLocalData.setFilter(filters)

  }

  getUserExamsBackend() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    return this.userService.allUserExamBackend().then(
      (result: any) => {
        const userExamBackend = result.body
        return userExamBackend;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getOnlineUser() {
    this.loading = true;
    return this.userService.allUserOnlineBackend().then(
      (result) => {
        console.log(result.body);
        const userExamBackend = result.body
        return userExamBackend;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getChannels() {
    this.loading = true;
    return this.examService.listChannelsKVS().then(
      (result: any) => {
        console.log('getChannels')
        console.log(result)
        const channelList = result.ChannelInfoList;
        return channelList;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getUserCognito() {
    this.loading = true;
    return this.userService.allUserCognito().then(
      (result) => {
        const userCognito = result.Users
        return userCognito;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  combineUser(userCognito, userExamBackend, channels, onlines) {
    const userCompleteData = new Array<any>();
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    userCognito.forEach(cognitoUser => {
      const name = cognitoUser.Attributes.find((x) => x.Name === 'name');
      const email = cognitoUser.Attributes.find((x) => x.Name === 'email');
      const company = cognitoUser.Attributes.find((x) => x.Name === 'custom:company');
      const userExam = userExamBackend.find((x) => x.userCode === cognitoUser.Username);
      let channel = channels.find((x) => x.ChannelName.replace('mexam-test-channel-', '') === cognitoUser.Username);
      let online = onlines.find((x) => x.userOnlineKeys.userCode === cognitoUser.Username);
      if (channel === undefined) {
        channel = channels.find((x) => x.ChannelName.replace('mexam-exam-channel-', '') === cognitoUser.Username);
      }
      if (company.Value === userLogged.company.companyCode) {
        if (channel !== undefined) {
          if (userExam !== undefined) {
            if (userExam.userAssign !== undefined) {
              const assign = userExam.userAssign.find((x) => x.userStreamCode === online.userOnlineKeys.streamCode);
              let help = false
              if (assign !== undefined) {
                help = assign.userExamNeedHelp;
              }

              userCompleteData.push({
                channelName: channel.ChannelName,
                userName: name.Value,
                userEmail: email.Value,
                online: online,
                help: help === false ? 'No' : 'Si',
              });
            }
          }
        }
      }
    });
    // console.log(userCompleteData);
    return userCompleteData;
  }

  viewCamera(event) {
    this.dialogService.open(ReportExamChannelViewerComponent,
      {
        context: {
          channel: event.data,
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (exam) => {
        });
  }

  openMeet(event) {
    const user: any = JSON.parse(localStorage.getItem('user'));
    this.windowService.open(MeetComponent,
      {
        title: `Alumno`,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        hasBackdrop: false,
        windowClass: 'meetWindow',
        context: {
          examCode: event.data.online.userOnlineKeys.examCode,
          user: user,
          streamCode: event.data.online.userOnlineKeys.streamCode,
        }
      });
    // console.log(environment.apiUrlWebSocket +
    //   '?streamCode=' + event.data.online.userOnlineKeys.streamCode +
    //   '&examCode=' + event.data.online.userOnlineKeys.examCode +
    //   '&userCode=' + user.userId);
    var ws = new $WebSocket(
      environment.apiUrlWebSocket +
      '?streamCode=' + event.data.online.userOnlineKeys.streamCode +
      '&examCode=' + event.data.online.userOnlineKeys.examCode +
      '&userCode=' + user.userId
    );
    ws.send({ "action": "sendMessage", "data": event.data.online.userOnlineKeys.streamCode }, WebSocketSendMode.Promise).then(
      (T) => {
        // console.log("is send");
      },
      (T) => {
        // console.log("not send");
      }
    );

  }

  openMeetTest(event) {
    const user: any = JSON.parse(localStorage.getItem('user'));
    this.windowService.open(MeetComponent,
      {
        title: `Alumno`,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        hasBackdrop: false,
        windowClass: 'meetWindow',
        context: {
        }
      });


  }

}
