import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Exam } from 'src/app/models/exam';
import { ExamService } from 'src/app/services/exam.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { isNumber } from 'util';

@Component({
  selector: 'app-exam-maintenance-field-configuration',
  templateUrl: './exam-maintenance-field-configuration.component.html',
  styleUrls: ['./exam-maintenance-field-configuration.component.scss']
})
export class ExamMaintenanceFieldConfigurationComponent implements OnInit, OnChanges {
  @Input() exam = {} as Exam;

  loading = false;
  statusAlert = 'danger';
  messageAlert = '';

  constructor(
    private examService: ExamService,
    private toastrService: NbToastrService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.exam.examCode === undefined) { this.exam.examStatus = true; }
    if (this.exam.examStatus === undefined) { this.exam.examStatus = true; }
    // if (this.exam.examBusinessCode !== undefined) { }
  }

  validateEmptyFields(): boolean{
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    let message = 'Por favor, ingrese ';
    let errors: string[] = [];
    if(this.exam.examCounterRandomMultipleAnswers === undefined || this.exam.examCounterRandomMultipleAnswers ==='') {
      errors.push('el número de preguntas de opción múltiple con varias respuestas');
    }
    if(this.exam.examCounterRandomOneAnswer === undefined || this.exam.examCounterRandomOneAnswer ==='') {
      errors.push('el número de preguntas de opción múltiple con una respuesta');
    }
    if(this.exam.examCounterRandomOneAnswerB === undefined || this.exam.examCounterRandomOneAnswerB ==='') {
      errors.push('el número de preguntas de opción múltiple con una respuesta');
    }
    if(this.exam.examCounterRandomCasesAnswers === undefined || this.exam.examCounterRandomCasesAnswers ==='') {
      errors.push('el número de preguntas con casos');
    }
    if(this.exam.examCounterRandomDevelopAnswers === undefined || this.exam.examCounterRandomDevelopAnswers ==='') {
      errors.push('el número de preguntas de desarrollo');
    }
    if(this.exam.examScoreMultipleAnswers === undefined || this.exam.examScoreMultipleAnswers ==='') {
      errors.push('la ponderación de preguntas de opción múltiple con varias respuestas');
    }
    if(this.exam.examScoreOneAnswerB === undefined || this.exam.examScoreOneAnswerB ==='') {
      errors.push('la ponderación de preguntas de opción múltiple con una respuesta');
    }
    if(this.exam.examScoreOneAnswer === undefined || this.exam.examScoreOneAnswer ==='') {
      errors.push('la ponderación de preguntas de opción múltiple con una respuesta');
    }
    if(this.exam.examScoreCasesAnswers === undefined || this.exam.examScoreCasesAnswers ==='') {
      errors.push('la ponderación de preguntas con casos');
    }
    if(this.exam.examScoreDevelopAnswers === undefined || this.exam.examScoreDevelopAnswers ==='') {
      errors.push('la ponderación de preguntas de desarrollo');
    }
    if(this.exam.examScoreDevelopAnswersOver === undefined || this.exam.examScoreDevelopAnswersOver ==='') {
      errors.push('la ponderación máxima de preguntas de desarrollo');
    }
    if (errors.length > 0) {
      for (const index in errors) {
        message += errors[parseInt(index)];
        message += parseInt(index) == errors.length - 1? '.': parseInt(index) == errors.length - 2? ' y ': ', ';
      }
      this.toastrService.show('', message, { position, status });
      return false;
    }
    return true;
  }

  closeExam() {
    this.router.navigate(['/examMaintenance/']);
  }

  async saveExam() {
    if (this.validateEmptyFields()){
      this.loading = true;
      this.getExamValues();

      if (await this.validateExamFields() === true) { this.validateExamCode(); }
    }

  }

  validateExamFields() {
    let counterMultipleAnswers = 0;
    let counterOneAnswers = 0;
    let counterOneAnswersB = 0;
    let counterDevelopAnswers = 0;
    let counterCasesAnswers = 0;
    this.exam.examQuestion.forEach(question => {
      if (question.examQuestionType === 'oneAnswer') { counterOneAnswers++; }
      if (question.examQuestionType === 'oneAnswerB') { counterOneAnswersB++; }
      if (question.examQuestionType === 'multipleAnswer') { counterMultipleAnswers++; }
      if (question.examQuestionType === 'themeDevelopAnswer') { counterDevelopAnswers++; }
      if (question.examQuestionType === 'themeCasesAnswer') { counterCasesAnswers++; }
    });
    this.statusAlert = 'warning';
    this.messageAlert = '';
    if (this.exam.examCounterRandomMultipleAnswers < 0 ||
      this.exam.examCounterRandomMultipleAnswers > counterMultipleAnswers) {
      this.loading = false;
      this.messageAlert = 'La cantidad de preguntas con múltiples respuestas solo puede ser entre 0 y ' +
        counterMultipleAnswers;
      return false;
    }
    if (this.exam.examCounterRandomOneAnswer < 0 || this.exam.examCounterRandomOneAnswer > counterOneAnswers) {
      this.loading = false;
      this.messageAlert = 'La cantidad de preguntas con una sola respuesta solo puede ser entre 0 y ' + counterOneAnswers; return false;
    }
    if (this.exam.examCounterRandomOneAnswerB < 0 || this.exam.examCounterRandomOneAnswerB > counterOneAnswersB) {
      this.loading = false;
      this.messageAlert = 'La cantidad de preguntas con una sola respuesta solo puede ser entre 0 y ' + counterOneAnswers; return false;
    }
    if (this.exam.examCounterRandomDevelopAnswers < 0 || this.exam.examCounterRandomDevelopAnswers > counterDevelopAnswers) {
      this.loading = false;
      this.messageAlert = 'La cantidad de preguntas con desarrollo de tema solo puede ser entre 0 y ' + counterDevelopAnswers; return false;
    }
    if (this.exam.examCounterRandomCasesAnswers < 0 || this.exam.examCounterRandomCasesAnswers > counterCasesAnswers) {
      this.loading = false;
      this.messageAlert = 'La cantidad de preguntas con casos solo puede ser entre 0 y ' + counterCasesAnswers; return false;
    }
    const score = parseInt(this.exam.examScoreOneAnswer === undefined ? 0 : this.exam.examScoreOneAnswer) +
      parseInt(this.exam.examScoreOneAnswerB === undefined ? 0 : this.exam.examScoreOneAnswerB) +
      parseInt(this.exam.examScoreMultipleAnswers === undefined ? 0 : this.exam.examScoreMultipleAnswers) +
      parseInt(this.exam.examScoreDevelopAnswers === undefined ? 0 : this.exam.examScoreDevelopAnswers) +
      parseInt(this.exam.examScoreCasesAnswers === undefined ? 0 : this.exam.examScoreCasesAnswers);
    if (score > 100) {
      this.loading = false;
      this.messageAlert = 'La suma de la ponderación debe ser 100';
      return false;
    }
    if (score < 100) {
      this.loading = false;
      this.messageAlert = 'La suma de la ponderación debe ser 100';
      return false;
    }
    return true;
  }

  getExamValues() {
    // Company code
    let user: User;
    if (localStorage.getItem('user') !== undefined) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    const companyCode = user.company.companyCode;
    this.exam.examCompanyCode = companyCode;
    if (this.exam.examCode === undefined) {
      this.exam.examCreationDate = new Date();
      this.exam.examCreationUser = user.userId;
    }
    this.exam.examModifyDate = new Date();
    this.exam.examModifyUser = user.userId;
    this.exam.examNameForFilter = this.exam.examName.toLowerCase();
  }

  validateExamName() {
    this.loading = true;
    this.examService.validatorNameExamBackend(this.exam.examName.toLowerCase())
      .then(
        (result: any) => {
          const validatedExam: Exam[] = result.body;
          const validatorLength = validatedExam.length;
          if (this.exam.examCode) {
            if (validatorLength === 0) {
              this.updateExam();
            } else {
              const filteredValidatedExam: Exam[] = validatedExam.filter(exam => {
                exam.examCode = this.exam.examCode;
              });
              if (filteredValidatedExam.length > 0) {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'danger';
                this.toastrService.show('', 'Nombre de examen ya utilizado.', { position, status });
              } else {
                this.updateExam();
              }
            }
          } else {
            if (validatorLength === 0) {
              this.insertExam();
            } else {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show('', 'Nombre de examen ya utilizado.', { position, status });
            }
          }
        })
      .catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        }
      )
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  validateExamCode() {
    this.loading = true;
    this.examService.validatorExamBackend(this.exam.examBusinessCode)
      .then(
        (result: any) => {
          const validatedExam: Exam[] = result.body;
          const validatorLength = validatedExam.length;
          if (this.exam.examCode) {
            if (validatorLength === 0) {
              this.validateExamName();
            } else {
              const filteredValidatedExam: Exam[] = validatedExam.filter(exam => {
                exam.examCode = this.exam.examCode;
              });
              if (filteredValidatedExam.length > 0) {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'danger';
                this.toastrService.show('', 'Código de examen ya utilizado.', { position, status });
              } else {
                this.validateExamName();
              }
            }
          } else {
            if (validatorLength === 0) {
              this.validateExamName();
            } else {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show('', 'Código de examen ya utilizado.', { position, status });
            }
          }
        })
      .catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        }
      )
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  insertExam() {
    this.loading = true;
    this.examService.insertExamBackend(this.exam)
      .then(
        (result: any) => {
          this.exam.examCode = result.examCode;
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.router.navigate(['/examMaintenanceFields/' + this.exam.examCode]);
        })
      .catch(
        (err: any) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        })
      .finally(
        () => {
          this.loading = false;
        });
  }

  updateExam() {
    this.examService.updateExamBackend(this.exam)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Actualizado', { position, status });
        })
      .catch(
        (err: any) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', err, { position, status });
        })
      .finally(
        () => {
          this.loading = false;
        }
      );
  }

  validateExamCounterRandomMultipleAnswers(event) {
    let counterMultipleAnswers = 0;
    this.exam.examQuestion.forEach(question => {
      if (question.examQuestionType === 'multipleAnswer') {
        counterMultipleAnswers++;
      }
    });
    this.statusAlert = 'warning';
    this.messageAlert = '';
    if (this.exam.examCounterRandomMultipleAnswers < 0 || this.exam.examCounterRandomMultipleAnswers > counterMultipleAnswers) {
      this.messageAlert = 'La cantidad de preguntas solo puede ser entre 0 y ' + counterMultipleAnswers; return;
    }
  }

  validateExamCounterRandomOneAnswer(event) {
    let counterMultipleAnswers = 0;
    this.exam.examQuestion.forEach(question => {
      if (question.examQuestionType === 'oneAnswer') {
        counterMultipleAnswers++;
      }
    });
    this.statusAlert = 'warning';
    this.messageAlert = '';
    if (this.exam.examCounterRandomOneAnswer < 0 || this.exam.examCounterRandomOneAnswer > counterMultipleAnswers) {
      this.messageAlert = 'La cantidad de preguntas solo puede ser entre 0 y ' + counterMultipleAnswers; return;
    }
  }

  validateExamCounterRandomOneAnswerB(event) {
    let counterMultipleAnswersB = 0;
    this.exam.examQuestion.forEach(question => {
      if (question.examQuestionType === 'oneAnswerB') {
        counterMultipleAnswersB++;
      }
    });
    this.statusAlert = 'warning';
    this.messageAlert = '';
    if (this.exam.examCounterRandomOneAnswerB < 0 || this.exam.examCounterRandomOneAnswerB > counterMultipleAnswersB) {
      this.messageAlert = 'La cantidad de preguntas solo puede ser entre 0 y ' + counterMultipleAnswersB; return;
    }
  }

  validateExamCounterRandomDevelopAnswers(event) {
    let counterMultipleAnswers = 0;
    this.exam.examQuestion.forEach(question => {
      if (question.examQuestionType === 'themeDevelopAnswer') {
        counterMultipleAnswers++;
      }
    });
    this.statusAlert = 'warning';
    this.messageAlert = '';
    if (this.exam.examCounterRandomDevelopAnswers < 0 || this.exam.examCounterRandomDevelopAnswers > counterMultipleAnswers) {
      this.messageAlert = 'La cantidad de preguntas solo puede ser entre 0 y ' + counterMultipleAnswers; return;
    }
  }

  validateExamCounterRandomCasesAnswers(event) {
    let counterMultipleAnswers = 0;
    this.exam.examQuestion.forEach(question => {
      if (question.examQuestionType === 'themeCasesAnswer') {
        counterMultipleAnswers++;
      }
    });
    this.statusAlert = 'warning';
    this.messageAlert = '';
    if (this.exam.examCounterRandomCasesAnswers < 0 || this.exam.examCounterRandomCasesAnswers > counterMultipleAnswers) {
      this.messageAlert = 'La cantidad de preguntas solo puede ser entre 0 y ' + counterMultipleAnswers; return;
    }
  }

  validateExamCounterRandomDevelopAnswersOver(event) {
    let counterMultipleAnswers = 0;
    this.exam.examQuestion.forEach(question => {
      if (question.examQuestionType === 'themeDevelopAnswer') {
        counterMultipleAnswers++;
      }
    });
    this.statusAlert = 'warning';
    this.messageAlert = '';
    if (this.exam.examScoreDevelopAnswersOver < 0 || this.exam.examScoreDevelopAnswersOver > counterMultipleAnswers) {
      this.messageAlert = 'La cantidad de preguntas solo puede ser entre 0 y ' + counterMultipleAnswers; return;
    }
  }

  validateExamScore(event) {
    const score = parseInt(this.exam.examScoreOneAnswer === undefined ? 0 : this.exam.examScoreOneAnswer) +
      parseInt(this.exam.examScoreOneAnswerB === undefined ? 0 : this.exam.examScoreOneAnswerB) +
      parseInt(this.exam.examScoreMultipleAnswers === undefined ? 0 : this.exam.examScoreMultipleAnswers) +
      parseInt(this.exam.examScoreDevelopAnswers === undefined ? 0 : this.exam.examScoreDevelopAnswers) +
      parseInt(this.exam.examScoreCasesAnswers === undefined ? 0 : this.exam.examScoreCasesAnswers);
    this.statusAlert = 'warning';
    this.messageAlert = '';
    if (score > 100) { this.messageAlert = 'La suma de la ponderación debe ser 100'; return; }
    if (score < 100) { this.messageAlert = 'La suma de la ponderación debe ser 100'; return; }
  }

  keyPaste(event, value: string) {
    let isnum = isNumber(value);
    if (!isnum) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

}
