import { SimpleTimeComponent } from "../../simple-time/simple-time.component";

export const reportUserResultTableSettings = {
  hideSubHeader: false,
  mode: "external",
  actions: {
    edit: true,
    delete: false,
    add: false,
    columnTitle: "",
    position: "left",
    class: "action-colum"
  },
  edit: {
    editButtonContent: "<i class='fa fa-eye' title='Ver'></i>"
  },
  delete: {
    deleteButtonContent: "<i class='nb-trash' title='Eliminar'></i>"
  },
  columns: {
    examName: {
      title: "Nombre examen",
      type: "string"
    },
    userName: {
      title: "Nombre estudiante",
      type: "string"
    },
    examStartDateTime: {
      title: "Hora asignada",
      type: "custom",
      renderComponent: SimpleTimeComponent,
    },
    examStart: {
      title: "Hora inicio",
      type: "custom",
      renderComponent: SimpleTimeComponent,
    },
    examEnd: {
      title: "Hora fin",
      type: "string",
    },
    examCorrectAnswers: {
      title: "Repuestas correctas",
      type: "string"
    },
    examIncorrectAnswers: {
      title: "Repuestas incorrectas",
      type: "string"
    },
    examNoResponse: {
      title: "Respuestas sin responder",
      type: "string"
    },
    examThemeQuestions: {
      title: "Desarrollo de temas",
      type: "string"
    }
  },
  attr: {
    class: "table table-bordered"
  },
  noDataMessage: "No hay resultados."
}
