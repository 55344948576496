import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-exam-check-validation-answer',
  templateUrl: './exam-check-validation-answer.component.html',
  styleUrls: ['./exam-check-validation-answer.component.scss']
})
export class ExamCheckValidationAnswerComponent implements OnInit {
  loading;
  scoreData;
  messageAlert: any = '';
  configTiny = {
    height: 250,
    // theme: 'modern',
    // plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern',
    // toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
    // // image_advtab: true,
    // // imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
    // // templates: [
    // //   { title: 'Test template 1', content: 'Test 1' },
    // //   { title: 'Test template 2', content: 'Test 2' }
    // // ],
    // content_css: [
    //   'fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
    //   'www.tinymce.com/css/codepen.min.css'
    // ]
  };

  constructor(
    private dialogRef: NbDialogRef<ExamCheckValidationAnswerComponent>,
  ) { }

  async ngOnInit() {
  }

  async downloadToExcel() {
    const new_wb = this.xtos(this.scoreData.question.examQuestionAnswerXls.datas);
    xlsx.writeFile(new_wb, "respuesta.xlsx");
  }

  xtos(sdata) {
    var out = xlsx.utils.book_new();
    sdata.forEach(function (xws) {
      var aoa = [[]];
      var rowobj = xws.rows;
      for (var ri = 0; ri < rowobj.len; ++ri) {
        var row = rowobj[ri];
        if (!row) continue;
        aoa[ri] = [];
        Object.keys(row.cells).forEach(function (k) {
          var idx = +k;
          if (isNaN(idx)) return;
          aoa[ri][idx] = row.cells[k].text;
        });
      }
      var ws = xlsx.utils.aoa_to_sheet(aoa);
      xlsx.utils.book_append_sheet(out, ws, xws.name);
    });
    return out;
  }

  close() {
    this.scoreData['checked'] = false;
    this.scoreData.score = 0;
    this.dialogRef.close(this.scoreData);
  }

  save() {
    this.messageAlert = '';
    if (parseInt(this.scoreData.score) > parseInt(this.scoreData.eachQuestionValue) ||
      parseInt(this.scoreData.score) < 0 || isNaN(this.scoreData.score) === true
    ) {
      this.messageAlert = 'Puntaje debe ser entre 0 y ' + this.scoreData.eachQuestionValue;
    } else {
      if (this.scoreData.score < this.scoreData.eachQuestionValue) {
        this.messageAlert = 'Comentario obligatorio resultado menor a ' + this.scoreData.eachQuestionValue;
        if (this.scoreData.comment === undefined || this.scoreData.comment === '') {
          this.messageAlert = 'Comentario obligatorio resultado menor a ' + this.scoreData.eachQuestionValue;
        } else {
          this.scoreData['checked'] = true;
          this.dialogRef.close(this.scoreData);
        }
      } else {
        this.scoreData['checked'] = true;
        this.dialogRef.close(this.scoreData);
      }
    }
  }

  verify() {
    this.messageAlert = '';
    if (parseInt(this.scoreData.score) > parseInt(this.scoreData.eachQuestionValue) ||
      parseInt(this.scoreData.score) < 0
    ) {
      this.messageAlert = 'Puntaje debe ser entre 0 y ' + this.scoreData.eachQuestionValue;
    }
  }

}
