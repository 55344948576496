import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  styleBackground;
  showImg = false;

  constructor(
    private companyService: CompanyService,
    private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit() {
    this.styleBackground = this.sanitizer.bypassSecurityTrustStyle(
      'url(assets/images/mLogo.png)');
    await this.setBackground();
  }

  setBackground() {
    let user: User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
      this.companyService.oneCompanyBackend(user.company.companyCode).then(
        (result) => {
          let logo = result.body.companyLogo === undefined ? 'assets/images/mLogo.png' : result.body.companyLogo;
          logo = result.body.companyLogo === null ? 'assets/images/mLogo.png' : result.body.companyLogo;
          this.styleBackground = this.sanitizer.bypassSecurityTrustStyle(
            'url(' + logo + ')');
          this.showImg = result.body.companyLogo === null ? false : true;
        }).catch(
          (err) => {
            const logo = 'assets/images/mLogo.png';
            this.styleBackground = this.sanitizer.bypassSecurityTrustStyle(
              'url(' + logo + ')');
          }
        ).finally(
          () => {
          }
        );
    }

  }

}
