import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {
  NbToastrService,
  NbGlobalPosition, NbGlobalPhysicalPosition,
  NbComponentStatus, NbDialogService, NbDateService
} from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';
import { UserExam } from 'src/app/models/userExam';
import { UserAssign } from 'src/app/models/userAssign';
import { UserService } from 'src/app/services/user.service';
import { Exam } from 'src/app/models/exam';
import { ExamService } from 'src/app/services/exam.service';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company.service';
const uuid = require('uuid');
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-exam-assignation',
  templateUrl: './exam-assignation.component.html',
  styleUrls: ['./exam-assignation.component.scss']
})
export class ExamAssignationComponent implements OnInit {
  loading: boolean;
  filterUser = '';
  filterGroup = '';
  userList = new Array<UserExam>();
  groupList = new Array<any>();
  groupListExtra = new Array<any>();
  groupsList = new Array<any>();
  disableUsers = false;
  examCode;
  examAssignDate;
  examAssignTime;
  min: Date;
  // minTime;
  items: TreeviewItem[];
  values: [];
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000
  });

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastrService: NbToastrService,
    private router: Router,
    private dialogService: NbDialogService,
    private userService: UserService,
    private companyService: CompanyService,
    private examService: ExamService,
    private dateService: NbDateService<Date>,
  ) {
    this.min = this.dateService.addDay(this.dateService.today(), 0);
    this.examAssignDate = this.min;
    const currentDate = new Date();
    const currentDateWith30Minutes = new Date(currentDate);
    currentDateWith30Minutes.setMinutes(currentDate.getMinutes() + 30);
    const currentHour = this.addZero(currentDate.getHours());
    const currentMinutes = this.addZero(currentDate.getMinutes());
    // this.minTime = currentHour + ':' + currentMinutes;
    this.examAssignTime = currentHour + ':' + currentMinutes;;
  }

  addZero(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  async ngOnInit() {
    this.loading = true;
    let examCode;
    console.table('paso aqui')
    this.route.paramMap.subscribe((params: any) => {
      examCode = params.params.examCode;
      if (examCode !== undefined) {
        this.examCode = examCode;
      }
    });
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    console.log(userLogged)
    const company = await this.getCompany(userLogged.company.companyCode);
    console.log(company)
    this.groupsList = JSON.parse(company.companyApiFields);
    console.log(this.groupsList)
    const user: { userResult, groupResult, extrasResult } = await this.getUser(this.groupsList, company);
    this.userList = user.userResult;
    console.log(this.userList)
    console.log(user.groupResult)
    console.log(user.extrasResult)

    this.groupList = user.groupResult;
    this.groupListExtra = user.extrasResult;
    const treeMode = await this.treeMode(user.userResult, user.extrasResult, this.groupsList, company);
    this.items = treeMode;
  }

  treeMode(users, extrasResultArr, params, company): TreeviewItem[] {
    let items = new Array<TreeviewItem>();
    const orderedApiFields = this.getOrderedFields(params);
    const orderedUsers = this.getOrderedUsers(users, orderedApiFields, company);
    orderedUsers.forEach(item => {
      items.push(new TreeviewItem(item));
    });
    return items;
  }

  getOrderedUsers(users, orderedApiFields, company) {
    let orderedUsers = {}
    let keysAndSubkeys = [];
    let formedJson = [];

    users.forEach(completeUser => {
      if (completeUser.clientGroups !== undefined) {
        const user = this.IsJsonString(completeUser.clientGroups) ? JSON.parse(completeUser.clientGroups) : completeUser.clientGroups;
        let prevKeys = [];
        let keysAndSubkeysIndexes = [];
        let prevJson = {};

        for (let k = 0; k <= orderedApiFields.length; k++) {
          let currentApiField = "";
          let prevObj = {};
          if (k < orderedApiFields.length) {
            currentApiField = orderedApiFields[k].value;
            if (k == 0) {
              if (orderedUsers[user[currentApiField]] == undefined) {
                orderedUsers[user[currentApiField]] = {};
              }
              prevKeys.push(user[currentApiField]);

              if (keysAndSubkeys[0] == undefined) {
                keysAndSubkeys[0] = [user[currentApiField]]
                keysAndSubkeysIndexes.push(0);
                formedJson.push(
                  {
                    text: user[currentApiField],
                    value: user[currentApiField],
                    collapsed: true,
                    children: []
                  }
                )
              }
              else if (keysAndSubkeys[0].indexOf(user[currentApiField]) == -1) {
                keysAndSubkeys[0].push(user[currentApiField])
                keysAndSubkeysIndexes.push(keysAndSubkeys[0].length - 1);
                formedJson.push(
                  {
                    text: user[currentApiField],
                    value: user[currentApiField],
                    collapsed: true,
                    children: []
                  }
                )

              }
              else {
                let currentIndex = keysAndSubkeys[0].indexOf(user[currentApiField]);
                keysAndSubkeysIndexes.push(currentIndex);
              }

              prevJson = formedJson;
            }
            else {
              for (let j = 0; j < prevKeys.length; j++) {
                if (j == 0) {
                  prevObj = orderedUsers[prevKeys[j]];
                }
                else {
                  prevObj = prevObj[prevKeys[j]]
                }
              }
              if (prevObj[user[currentApiField]] == undefined) {
                prevObj[user[currentApiField]] = {}
                if (keysAndSubkeys[k] == undefined) {
                  keysAndSubkeys[k] = [user[currentApiField]]
                  keysAndSubkeysIndexes.push(0);
                }
                else {
                  let index = keysAndSubkeys[k].indexOf(user[currentApiField]);
                  if (index == -1) {
                    keysAndSubkeys[k].push(user[currentApiField])
                    keysAndSubkeysIndexes.push(keysAndSubkeys.length - 1);
                  }
                  else {

                    keysAndSubkeysIndexes.push(index);
                  }
                }
                let tempJsonChildren = {
                  text: user[currentApiField],
                  value: user[currentApiField],
                  children: []
                }
                if (prevJson[keysAndSubkeysIndexes[k - 1]].children == undefined) {
                  prevJson[keysAndSubkeysIndexes[k - 1]].children = [tempJsonChildren];
                }
                else {
                  prevJson[keysAndSubkeysIndexes[k - 1]].children.push(tempJsonChildren);
                }
                keysAndSubkeys[k] = Object.keys(prevObj)
                keysAndSubkeysIndexes[k] = (keysAndSubkeys[k].indexOf(user[currentApiField]));
                prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
              }
              else {
                if (keysAndSubkeys[k].indexOf(user[currentApiField]) == -1) {
                  keysAndSubkeys[k].push(user[currentApiField])
                  keysAndSubkeysIndexes.push(keysAndSubkeys.length - 1);
                  prevJson[keysAndSubkeysIndexes[k - 1]].children.push(
                    {
                      text: user[currentApiField],
                      value: user[currentApiField],
                      children: []
                    }
                  )
                  prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
                }
                else {
                  let currentIndex = keysAndSubkeys[k].indexOf(user[currentApiField]);
                  keysAndSubkeysIndexes.push(currentIndex);
                  prevJson = prevJson[keysAndSubkeysIndexes[k - 1]].children;
                }
              }
              prevKeys.push(user[currentApiField]);
            }
          }
          else {

            let showDataUser = completeUser.userName;
            if (company.companyApiFieldsShow !== undefined) {
              const companyApiFieldsShow = JSON.parse(company.companyApiFieldsShow).sort(this.getSortOrder("order"));
              showDataUser = '';
              companyApiFieldsShow.forEach(fields => {
                const clientGroup = this.IsJsonString(completeUser.clientGroups) ? JSON.parse(completeUser.clientGroups) : completeUser.clientGroups;
                showDataUser = showDataUser + ' ' + clientGroup[fields.value];
              });
            }

            if (orderedUsers[user[currentApiField]] == undefined) {
              if (k == (orderedApiFields.length)) {
                if (prevJson[keysAndSubkeysIndexes[k - 1]].children == undefined) {
                  prevJson[keysAndSubkeysIndexes[k - 1]].children = [{ text: showDataUser, value: completeUser.userCode, checked: false, }]
                }
                else {
                  prevJson[keysAndSubkeysIndexes[k - 1]].children.push({ text: showDataUser, value: completeUser.userCode, checked: false, })
                }
              }
            }
            else {
              if (k == (orderedApiFields.length - 1)) {
                prevObj[user[currentApiField]].users.push(user);
                prevJson[keysAndSubkeysIndexes[k - 1]].children[keysAndSubkeysIndexes[k]].push(
                  { text: showDataUser, value: completeUser.userCode, checked: false, }
                );
              }
            }
          }
        }
      }
    });
    return formedJson;
  }

  getSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  getOrderedFields(companyApiFields) {
    const companyApiFieldsWithParent = [];
    let biggestDaddy = undefined;
    const orderedApiFields = [];

    companyApiFields.forEach((companyApiField, index) => {
      if (companyApiField.parent === undefined) {
        biggestDaddy = companyApiField;
        orderedApiFields.push(companyApiField);
      }
      else {
        companyApiFieldsWithParent.push(companyApiField);
      }
    });
    let lookingForParentAndChild = true;
    let currentDaddy = biggestDaddy;
    while (lookingForParentAndChild === true) {
      let daddysFirstChild = this.getFirstChild(currentDaddy, companyApiFieldsWithParent);
      orderedApiFields.push(daddysFirstChild.firstChild);
      companyApiFieldsWithParent.splice(daddysFirstChild.index, 1);
      currentDaddy = daddysFirstChild.firstChild;
      if (companyApiFieldsWithParent.length === 0) {
        lookingForParentAndChild = false;
      }
    }
    return orderedApiFields;
  }

  getFirstChild(daddy, childs) {
    let firstChild = undefined;
    let index = 0;
    for (let i = 0; i < childs.length; i++) {
      let parent = childs[i].parent;
      if (parent === daddy.value) {
        firstChild = childs[i];
        index = i;
        break;
      }
    }

    return { firstChild, index }
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        const company = result.body;
        return company;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getUser(groups, company) {
    this.loading = true;
    const exam: Exam = await this.getExamBackend();
    const userBackend: UserExam[] = await this.getUserBackend();
    
    /* const usersApi: any[] = await this.getUserApi(company.companyApi); */
    /* console.log(usersApi); */
    return this.userService.allUserCognito().then(
      (result: any) => {
        console.log('pasa por aca')
        const userCognito = result;
        const userResult = new Array<UserExam>();
        const groupResult = new Array<any>();
        const extrasResult = new Array<any>();
        userCognito.Users.forEach((user) => {
          const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
          if (userRole.Value === 'student') {
            const userId = user.Username;
            const email = user.Attributes.find((x) => x.Name === 'email');
            const name = user.Attributes.find((x) => x.Name === 'name');

            let clientGroups = user.Attributes.find((x) => x.Name === 'custom:clientGroups');
            if (clientGroups === undefined) {
              clientGroups = { Value: '' };
            }
            //comentado 7-10-22 by Kevin para que no llame a la API de la UFM (actual company)
            /* if (email !== undefined && company.companyApiSearchKey !== undefined) {
              const userApi = usersApi.find((x) => x[company.companyApiSearchKey] === email.Value.split('@')[0]);
              if (userApi !== undefined) {
                clientGroups.Value = userApi;
              }
            } */
            let group = user.Attributes.find((x) => x.Name === 'custom:group') ?
              user.Attributes.find((x) => x.Name === 'custom:group') : undefined;
            if (group === undefined) { group = { Value: 'Sin Grupo' }; }
            let userAssign;
            const userExamBackend = userBackend.find((x) => x.userCode === userId);
            if (userExamBackend !== undefined) {
              userAssign = userExamBackend.userAssign;
            }

            if (userRole.Value === 'student') {
              userResult.push({
                userCode: userId,
                userName: name.Value,
                userEmail: email.Value,
                userRole: userRole.Value,
                assign: false,
                userGroup: group.Value,
                userAssign,
                clientGroups: clientGroups.Value === '' ? undefined : clientGroups.Value,
              });
            }
          }
        });
        userResult.forEach((userExam, index) => {
          if (userExam.userAssign !== undefined) {
            if (userExam.userAssign.length > 0) {
              let counter = 0;
              userExam.userAssign.forEach(assign => {
                if (assign.userExamCode === this.examCode) {
                  counter++;
                }
              });
              if (counter >= parseInt(exam.examCounterAssign, 0)) {
                userResult.splice(index, 1);
                
              }
            }
          }
        });
         userResult.forEach((userExam, index) => {
          if (userExam.userGroup !== undefined) {
             const groupFind = groupResult.find((x) => x.group === userExam.userGroup);
            if (groupFind === undefined) {
              groupResult.push({
                 group: userExam.userGroup,
                 assign: false,
               });
             }
           }
         });
        console.log(userResult);
        userResult.forEach((userExam, index) => {
          if (userExam.clientGroups !== undefined) {
            const groupsExtras = this.IsJsonString(userExam.clientGroups) ? JSON.parse(userExam.clientGroups) : userExam.clientGroups;
            console.log(groupsExtras)
            if (groups !== undefined) {
              groups.forEach(group => {
                if (groupsExtras[group.value] !== undefined) {
                  let findAgroup = extrasResult.find((x) => x.agroup === group.value);
                  let findAgroupIndex = undefined;
                  if (findAgroup === undefined) {
                    findAgroup = {
                      agroupTittle: group.tittle,
                      agroup: group.value,
                      children: group.children,
                      parent: group.parent,
                      groupList: new Array(),
                    };
                  } else {
                    findAgroupIndex = extrasResult.indexOf(findAgroup);
                  }
                  const groupFind = findAgroup.groupList.find((x) => x.group === groupsExtras[group.value]);
                  if (groupFind === undefined) {
                    findAgroup.groupList.push({
                      group: groupsExtras[group.value],
                      assign: false,
                    });
                  }
                  if (findAgroupIndex !== undefined) {
                    extrasResult[findAgroupIndex] = findAgroup;
                  } else {
                    extrasResult.push(findAgroup);
                  }
                }
              });
            }else{
              console.log("No groups found");
            }

          }
        });
        return { userResult, groupResult, extrasResult };
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getExamBackend() {
    this.loading = true;
    return this.examService.oneExamBackend(this.examCode).then(
      (result) => {
        const lzw = require("node-lzw");
        result.body = JSON.parse(lzw.decode(result.body));
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getUserApi(url) {
    this.loading = true;
    return this.userService.allUserApi(url).then(
      (result) => {
        return result;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  changeGroup(group, assing) {
    this.loading = true;
    this.userList.forEach((user) => {
      if (user.userGroup === group) {
        user.assign = assing;
      }
    });
    this.groupList.some((groups) => {
      if (groups.assign === true) {
        // this.disableUsers = true;
        return true;
      } else {
        // this.disableUsers = false;
      }
    });
    this.loading = false;
  }

  changeAGroup(group, assing, agroup) {
    this.loading = true;
    this.userList.forEach((user) => {
      if (user.clientGroups !== undefined) {
        const agroups = JSON.parse(user.clientGroups);
        if (agroups[agroup] !== undefined) {
          if (agroups[agroup] === group) {
            user.assign = assing;
          }
        }
      }

    });
    this.loading = false;
  }

  changeTreeview(event: []) {
    this.userList.forEach(user => {
      const find = event.find(x => x === user.userCode);
      if (find !== undefined) {
        if (user.userCode === find) {
          user.assign = true;
        } else {
          user.assign = false;
        }
      } else {
        user.assign = false;
      }
    });
    if (event.length > 0) {
      this.disableUsers = true;
    } else {
      this.disableUsers = false;
    }
  }

  cancelAssignUser() {
    this.router.navigate(['/examAssign/']);
  }

  async assignUser() {
    let assignable_users = 0;
    if (await this.validateDateTime() === true) {
      this.userList.forEach(user => assignable_users += user.assign? 1: 0)
      if(assignable_users > 0) {
        this.dialogService.open(ConfirmWindowComponent,
          {
            context: {
              message: '¿Está seguro de asignar el examen?'
            },
            hasBackdrop: true,
            closeOnEsc: false,
            closeOnBackdropClick: false
          }).onClose.subscribe((result) => {
            if (result === true) {
              this.saveAssignUser();
            }
          });
      } else {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          'No asignó a ningún usuario para examinarse.',
          { position, status });
      }
    }
  }

  validateDateTime() {
    return true;
  }

  async saveAssignUser() {
    this.loading = true;
    let insert = false;
    let userLog: User;
    if (localStorage.getItem('user') !== undefined) {
      userLog = JSON.parse(localStorage.getItem('user'));
    }
    const userBackend: UserExam[] = await this.getUserBackend();
    if (await this.canAssign(userBackend) === true) {
      this.userList.forEach(user => {
        if (user.assign) {
          let userExam: UserExam = userBackend.find((x) => x.userCode === user.userCode);
          if (userExam === undefined) {
            userExam = user;
            insert = true;
          }
          if (userExam.userAssign === undefined) {
            userExam.userAssign = new Array<UserAssign>();
          }
          const year = this.examAssignDate.getFullYear();
          const month = this.examAssignDate.getMonth();
          const date = this.examAssignDate.getDate();
          const hour = this.examAssignTime.split(':')[0];
          const minute = this.examAssignTime.split(':')[1];
          const userExamDateTime = new Date(year, month, date, hour, minute);
          const userAssign: UserAssign = {
            userExamCode: this.examCode,
            userStreamCode: uuid.v4(),
            userExamStartDateTime: userExamDateTime,
            userExamExtraTime: undefined,
            userExam: undefined,
            userExamResults: undefined,
            userAttemptIntoExam: 0,
            userOutOfExam: 0,
            userAssignDate: new Date,
            userAssignUser: userLog.userId,
            userExamStart: undefined,
            userExamEnd: undefined,
            userExamScore: undefined,
            userExamQualified: false,
            userExamNeedHelp: false
          };
          userExam.userAssign.push(userAssign);
          userExam.userEmail = user.userEmail;
          if (insert === true) {
            this.insertUser(userExam);
          } else {
            this.updateUser(userExam);
          }

        }
      });
      this.loading = false;
      this.router.navigate(['/examAssign/']);
    }
    else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        'No cuenta con la cantidad necesaria de exámenes para continuar',
        { position, status });
    }
  }

  async canAssign(userBackend) {
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const company = await this.getCompany(userLogged.company.companyCode);
    let assignedExams = 0;
    userBackend.forEach(user => {
      if (user.userAssign !== undefined) {
        user.userAssign.forEach(assign => {
          assignedExams++;
        });
      }
    });
    this.userList.forEach(user => {
      if (user.assign) {
        assignedExams++;
      }
    });
    if (assignedExams <= parseInt(company.companyMaxUserExam)) {
      return true;
    }

    return false;
  }

  getUserBackend() {
    this.loading = true;
    return this.userService.allUserExamBackend().then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  updateUser(user) {
    this.loading = true;
    this.userService.updateUserBackend(user).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show(
          '',
          'Examen asignado a ' + user.userName,
          { position, status });
        const companyLogo = window.location.hostname;
        let subdomain = companyLogo.split('.')[0];
        subdomain = subdomain === 'mexam' ? '' : subdomain + '.';
        subdomain = 'https://' + subdomain + environment.emailUrl.replace('https://', '')
        this.examService.notifyAssignExamBackend({
          studentName: user.userName,
          teacherEmail: user.userEmail,
          companyUrl: subdomain
        });
        // this.ExamNotificationSES.sendEmail(user.userName, user.userEmail, subdomain);
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  insertUser(user) {
    this.loading = true;
    this.userService.insertUserExamBackend(user).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show(
          '',
          'Examen assignado a ' + user.userName,
          { position, status });
        const companyLogo = window.location.hostname;
        let subdomain = companyLogo.split('.')[0];
        subdomain = subdomain === 'mexam' ? '' : subdomain + '.';
        this.examService.notifyAssignExamBackend({
          studentName: user.userName,
          teacherEmail: user.userEmail,
          companyUrl: 'https://' + subdomain + environment.emailUrl.replace('https://', '')
        });
        // this.ExamNotificationSES.sendEmail(user.userName, user.userEmail, subdomain);
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }


}
