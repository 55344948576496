import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable()
export class AuthGuard implements CanActivate {
    isLogged: boolean;

    constructor(
        private authService: AuthService,
        private router: Router,) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let result;
        this.authService.isAuthenticated().
            subscribe((res) => {
                if (res) {
                    if (state.url === '/login') {
                        result = false;
                    } else {
                        result = true;
                    }
                } else {
                    if (state.url === '/login') {
                        result = true;
                    } else {
                        result = false;
                    }
                }
            },
                (err) => {
                    console.error(err);
                }
            );
        if (result === undefined) {
            this.takeOutApp();
        }
        const user: User = JSON.parse(localStorage.getItem('user'));
        if (user === undefined) {
            this.takeOutApp();
        }
        if (user !== null) {
            if (user.userRole !== undefined) {
                switch (user.userRole.toString()) {
                    case 'student':
                        if (state.url === '/examMaintenance') { this.takeOutApp(); }
                        if (state.url === '/examMaintenanceFields') { this.takeOutApp(); }
                        if (state.url === '/exam') { this.takeOutApp(); }
                        if (state.url === '/examViewerTeacher') { this.takeOutApp(); }
                        if (state.url === '/examAssign') { this.takeOutApp(); }
                        if (state.url === '/examUnassign') { this.takeOutApp(); }
                        if (state.url === '/examVerification') { this.takeOutApp(); }
                        if (state.url === '/examImport') { this.takeOutApp(); }
                        if (state.url === '/examChannelTest') { this.takeOutApp(); }
                        if (state.url === '/examCheck') { this.takeOutApp(); }
                        if (state.url === '/examCheckValidation') { this.takeOutApp(); }
                        if (state.url === '/examClone') { this.takeOutApp(); }
                        if (state.url === '/examSpreadSheet') { this.takeOutApp(); }
                        if (state.url === '/reportUsers') { this.takeOutApp(); }
                        if (state.url === '/reportUsersResults') { this.takeOutApp(); }
                        if (state.url === '/reportExamChannelList') { this.takeOutApp(); }
                        if (state.url === '/reportCheckExams') { this.takeOutApp(); }
                        if (state.url === '/userMaintenance') { this.takeOutApp(); }
                        if (state.url === '/userMaintenanceFields') { this.takeOutApp(); }
                        if (state.url === '/company') { this.takeOutApp(); }
                        break;
                    case 'teacher_assistent':
                        if (state.url === '/examMaintenance') { this.takeOutApp(); }
                        if (state.url === '/examMaintenanceFields') { this.takeOutApp(); }
                        if (state.url === '/exam') { this.takeOutApp(); }
                        if (state.url === '/examViewerTeacher') { this.takeOutApp(); }
                        if (state.url === '/examAssign') { this.takeOutApp(); }
                        if (state.url === '/examUnassign') { this.takeOutApp(); }
                        if (state.url === '/examVerification') { this.takeOutApp(); }
                        if (state.url === '/examImport') { this.takeOutApp(); }
                        if (state.url === '/examChannelTest') { this.takeOutApp(); }
                        if (state.url === '/examCheck') { this.takeOutApp(); }
                        if (state.url === '/examCheckValidation') { this.takeOutApp(); }
                        if (state.url === '/examClone') { this.takeOutApp(); }
                        if (state.url === '/examSpreadSheet') { this.takeOutApp(); }
                        // if (state.url === '/reportUsers') { this.takeOutApp(); }
                        // if (state.url === '/reportUsersResults') { this.takeOutApp(); }
                        // if (state.url === '/reportExamChannelList') { this.takeOutApp(); }
                        // if (state.url === '/reportCheckExams') { this.takeOutApp(); }
                        if (state.url === '/userMaintenance') { this.takeOutApp(); }
                        if (state.url === '/userMaintenanceFields') { this.takeOutApp(); }
                        if (state.url === '/company') { this.takeOutApp(); }
                        break;
                    case 'teacher':
                        if (state.url === '/examMaintenance') { this.takeOutApp(); }
                        if (state.url === '/examMaintenanceFields') { this.takeOutApp(); }
                        if (state.url === '/exam') { this.takeOutApp(); }
                        if (state.url === '/examViewerTeacher') { this.takeOutApp(); }
                        if (state.url === '/examVerification') { this.takeOutApp(); }
                        if (state.url === '/examImport') { this.takeOutApp(); }
                        if (state.url === '/examChannelTest') { this.takeOutApp(); }
                        if (state.url === '/examClone') { this.takeOutApp(); }
                        if (state.url === '/examSpreadSheet') { this.takeOutApp(); }
                        if (state.url === '/userMaintenance') { this.takeOutApp(); }
                        if (state.url === '/userMaintenanceFields') { this.takeOutApp(); }
                        if (state.url === '/company') { this.takeOutApp(); }
                        break;
                    case 'admin_content':
                        if (state.url === '/examAssign') { this.takeOutApp(); }
                        if (state.url === '/examUnassign') { this.takeOutApp(); }
                        if (state.url === '/examVerification') { this.takeOutApp(); }
                        if (state.url === '/examChannelTest') { this.takeOutApp(); }
                        if (state.url === '/examCheck') { this.takeOutApp(); }
                        if (state.url === '/examCheckValidation') { this.takeOutApp(); }
                        //if (state.url === '/examClone') { this.takeOutApp(); }
                        if (state.url === '/reportUsers') { this.takeOutApp(); }
                        if (state.url === '/reportUsersResults') { this.takeOutApp(); }
                        if (state.url === '/reportExamChannelList') { this.takeOutApp(); }
                        if (state.url === '/reportCheckExams') { this.takeOutApp(); }
                        if (state.url === '/userMaintenance') { this.takeOutApp(); }
                        if (state.url === '/userMaintenanceFields') { this.takeOutApp(); }
                        if (state.url === '/company') { this.takeOutApp(); }
                        break;
                    default:
                        break;
                }
            }
        }
        return result;
    }

    takeOutApp() {
        this.authService.logout();
        this.router.navigate(['/login']);
    }
}
