import { Component, OnInit, ViewChild } from '@angular/core';
import { StreamingService } from 'src/app/services/streaming.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { UserAssign } from 'src/app/models/userAssign';
import { Exam } from 'src/app/models/exam';
import { UserService } from 'src/app/services/user.service';
import { ExamService } from 'src/app/services/exam.service';
import { UserExam } from 'src/app/models/userExam';


@Component({
  selector: 'app-exam-pre-viewer',
  templateUrl: './exam-pre-viewer.component.html',
  styleUrls: ['./exam-pre-viewer.component.scss']
})
export class ExamPreViewerComponent implements OnInit {
  loading;
  examCode;
  streamCode;
  currentPreExam;
  cameraCode;
  reader = new FileReader();
  exam;
  agree = false;
  @ViewChild('stepper', { static: false }) private stepper;

  constructor(
    private streamingService: StreamingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: NbToastrService,
    private userService: UserService,
    private examService: ExamService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async (params: any) => {
      const examCode = params.params.examCode;
      const streamCode = params.params.streamCode;
      const cameraCode = params.params.cameraCode;
      if (examCode !== undefined) {
        this.examCode = examCode;
        this.streamCode = streamCode;
        this.currentPreExam = {
          examCode,
          streamCode,
        };
        this.exam = await this.getExam(examCode) as Exam;
        if (cameraCode !== undefined) {
          this.cameraCode = cameraCode
        }
      }
    });
  }

  async getExam(examCode) {
    this.loading = true;
    return this.examService.oneExamBackend(examCode)
      .then(
        async (result) => {
          const lzw = require("node-lzw");
          result.body = JSON.parse(lzw.decode(result.body));
          const examBackend = result.body as Exam;
          return examBackend;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
  }

  snapshotVerifyOne(event) {
    this.loading = true;
    let user;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user')) as User;
    }
    const prefixS3 = 'company/' +
      user.company.companyCode +
      '/user/' +
      user.userId +
      '/exam/' +
      this.examCode +
      '/preExamImage';
    this.streamingService.uploadFileS3(event, prefixS3, 'one-' + Date.now())
      .then(
        (result) => {
         
          const stepOne = {
            bucketName: environment.bucketName,
            imageKey: result.Key,
            userFaceId: user.faceId
          };
          console.log('verification 1 '+stepOne.bucketName)
          console.log('verification 1 '+stepOne.imageKey)
          console.log('verification 1 '+stepOne.userFaceId)
          this.streamingService.verifyStepOne(stepOne).then(
            (resultStepOne) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = resultStepOne === true ? 'success' : 'danger';
              const message = resultStepOne === true ? 'Identidad confirmada' : 'Identidad no confirmada';
              this.toastrService.show(
                '',
                message,
                { position, status });
              if (resultStepOne === true || this.exam.examValidationFace.examValidationRestrictPass === false) {
                this.stepper.next();
              }
              this.loading = false;
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        }).catch(
          (err) => {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              err.message,
              { position, status });
            this.loading = false;
          }
        );
  }

  snapshotVerifyTwo(event) {
    this.loading = true;
    let user;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user')) as User;
    }
    const prefixS3 = 'company/' +
      user.company.companyCode +
      '/user/' +
      user.userId +
      '/exam/' +
      this.examCode +
      '/preExamImage';
    this.streamingService.uploadFileS3(event, prefixS3, 'two-' + Date.now())
      .then(
        (result) => {
          
          const stepTwo = {
            bucketName: environment.bucketName,
            imageKey: result.Key,
            userFaceId: user.faceId,
            mainlabel: 'smartwatch',
            side: '',
            functionName: environment.functionRekognitionObject,
          };

          // console.log('verification 2 '+stepTwo.bucketName);
          // console.log('verification 2 '+stepTwo.imageKey);
          // console.log('verification 2 '+stepTwo.userFaceId);
          // console.log('verification 2 '+stepTwo.mainlabel);
          // console.log('verification 2 '+stepTwo.side);
          // console.log('verification 2 '+stepTwo.functionName);

          this.streamingService.verifyStepTwoThreeFour(stepTwo).then(
            (resultStepTwo: any) => {
              console.log(resultStepTwo)
              const payload = JSON.parse(resultStepTwo.Payload);
              const response = JSON.parse(payload.body);
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              let status: NbComponentStatus = 'danger';
              let message = 'se a detectado un reloj';
              if (response.smartwatch.code === 20) {
                status = 'success';
                message = response.smartwatch.msg;
              } else if (response.smartwatch.code === 30) {
                message = response.smartwatch.msg;
              } else if (response.smartwatch.code === 10) {
                message = response.smartwatch.msg;
              }

              this.toastrService.show(
                '',
                message,
                { position, status });
              if (response.smartwatch.code === 20 || this.exam.examValidationWrist.examValidationRestrictPass === false) {
                this.stepper.next();
              }
              this.loading = false;
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        }).catch(
          (err) => {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              err.message,
              { position, status });
            this.loading = false;
          }
        );
  }

  snapshotVerifyThree(event) {
    this.loading = true;
    let user;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user')) as User;
    }
    const prefixS3 = 'company/' +
      user.company.companyCode +
      '/user/' +
      user.userId +
      '/exam/' +
      this.examCode +
      '/preExamImage';
    this.streamingService.uploadFileS3(event, prefixS3, 'three-' + Date.now())
      .then(
        (result) => {
          console.log('verification 3 '+result)
          const stepThree = {
            bucketName: environment.bucketName,
            imageKey: result.Key,
            userFaceId: user.faceId,
            mainlabel: 'earbuds',
            side: 'left',
            functionName: environment.functionRekognitionObject,
          };
          console.log('verification 3: '+stepThree.bucketName);
          console.log('verification 3: '+stepThree.imageKey);
          console.log('verification 3: '+stepThree.mainlabel);
          console.log('verification 3: '+stepThree.side);
          console.log('verification 3: '+stepThree.userFaceId);
          console.log('verification 3: '+stepThree.functionName);

          this.streamingService.verifyStepTwoThreeFour(stepThree).then(
            (resultStepThree: any) => {
              console.log(stepThree);
              const payload = JSON.parse(resultStepThree.Payload);
              const response = JSON.parse(payload.body);
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              let status: NbComponentStatus = 'danger';
              let message = 'se a detectado un audifonos';
              if (response.earbuds.code === 20) {
                status = 'success';
                message = response.earbuds.msg;
              } else if (response.earbuds.code === 30) {
                message = response.earbuds.msg;
              } else if (response.earbuds.code === 10) {
                message = response.earbuds.msg;
              }

              this.toastrService.show(
                '',
                message,
                { position, status });
              if (response.earbuds.code === 20 || this.exam.examValidationHeadphones.examValidationRestrictPass === false) {
                this.stepper.next();
              }
              this.loading = false;
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );

        }).catch(
          (err) => {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              err.message,
              { position, status });
            this.loading = false;
          }
        );
  }

  snapshotVerifyFour(event) {
    this.loading = true;
    let user;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user')) as User;
    }
    const prefixS3 = 'company/' +
      user.company.companyCode +
      '/user/' +
      user.userId +
      '/exam/' +
      this.examCode +
      '/preExamImage';
    this.streamingService.uploadFileS3(event, prefixS3, 'four-' + Date.now())
      .then(
        (result) => {
          const stepThree = {
            bucketName: environment.bucketName,
            imageKey: result.Key,
            userFaceId: user.faceId,
            mainlabel: 'earbuds',
            side: 'right',
            functionName: environment.functionRekognitionObject,
          };
          this.streamingService.verifyStepTwoThreeFour(stepThree).then(
            (resultStepThree: any) => {
              const payload = JSON.parse(resultStepThree.Payload);
              const response = JSON.parse(payload.body);
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              let status: NbComponentStatus = 'danger';
              let message = 'se a detectado un audífonos';
              if (response.earbuds.code === 20) {
                status = 'success';
                message = response.earbuds.msg;
              } else if (response.earbuds.code === 30) {
                message = response.earbuds.msg;
              } else if (response.earbuds.code === 10) {
                message = response.earbuds.msg;
              }

              this.toastrService.show(
                '',
                message,
                { position, status });
              if (response.earbuds.code === 20 || this.exam.examValidationHeadphones.examValidationRestrictPass === false) {
                this.stepper.next();

              }
              this.loading = false;
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        }).catch(
          (err) => {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              err.message,
              { position, status });
            this.loading = false;
          }
        );
  }

  async snapshotVerifyFive(event) {
    this.loading = true;
    if (event.size > 0) {
      this.reader.onloadend = async () => {
        const userData: any = JSON.parse(localStorage.getItem('user'));
        const send = {
          userCode: userData.userId,
          company: userData.company.companyCode,
          examCode: this.examCode,
          blob: this.reader.result,
          bucketName: environment.bucketName,
          region: environment.region,
          functionRekognitionVideo360: environment.functionRekognitionVideo360,
        };
        this.streamingService.verifyStepFive(send).then(
          (resultStepFive) => {
            const response = JSON.parse(resultStepFive.Payload);
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            let status: NbComponentStatus = 'danger';
            let message = 'se a detectado objetos no permitidos';
            if (response.code === 20) {
              status = 'success';
              message = 'habitación segura para examen';
            } else if (response.code === 30) {
              message = 'video no valida';
            } else if (response.code === 10) {
              message = 'se a detectado algún objeto no permitido no es posible continuar';
            }

            this.toastrService.show(
              '',
              message,
              { position, status });
            if (response.code === 20 || this.exam.examValidationEnvironment.examValidationRestrictPass === false) {
              this.stepper.next();
            }
            this.loading = false;
          }
        ).catch(
          (err) => {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              err.message,
              { position, status });
          }
        ).finally(
          () => {
            this.loading = false;
          }
        );
      };
      this.reader.readAsDataURL(event);
    }
  }

  async startExam() {
    console.log('-------------- Start examen ----------------------')
    const user: User = JSON.parse(localStorage.getItem('user'));
    console.log('user')
    console.log(user)
    const userExam: UserExam = await this.myExamsBackend(user);
    console.log('userExam')
    console.log(userExam)
    const userAssign: UserAssign = userExam.userAssign.find((x) => x.userStreamCode === this.streamCode);
    console.log('userAssign')
    console.log(userAssign)
    const userAssignIndex = userExam.userAssign.indexOf(userAssign);
    console.log('userAssignIndex')
    console.log(userAssignIndex)
    const exam: Exam = await this.oneExam();
    console.log('exam')
    console.log(exam)
    const currentDateTime = new Date();
    console.log('currentDateTime')
    console.log(currentDateTime)
    const startDate = new Date(userAssign.userExamStartDateTime);
    console.log('startDate')
    console.log(startDate)
    const validationDate = new Date(startDate);
    console.log('validationDate')
    console.log(validationDate)
    const endDate = new Date(startDate);
    validationDate.setMinutes(validationDate.getMinutes() - parseInt(exam.examPreValidationMinutes, 0));
    endDate.setMinutes(endDate.getMinutes() + parseInt(exam.examTime, 0));
    if (
      currentDateTime >= startDate &&
      currentDateTime < endDate
    ) {
      userExam.userAssign[userAssignIndex].userExamStart = new Date();
      await this.updateUser(userExam);
      console.log('userExam verificacion 2')
      console.log(userExam)
      this.router.navigate(['/examViewer/' + this.examCode + '/' + this.streamCode]);
    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'warning';
      const message = 'Examen no permite ingreso revise su horario.';
      this.toastrService.show(
        '',
        message,
        { position, status });
    }
  }

  updateUser(user) {
    this.loading = true;
    this.userService.updateUserBackend(user).then(
      (result) => {
        console.log('result');
        console.log(JSON.stringify(result));

      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  myExamsBackend(user) {
    this.loading = true;
    return this.userService.oneUserExamBackend(user.userId).then(
      (result) => {
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  oneExam() {
    this.loading = true;
    return this.examService.oneExamBackend(this.examCode).then(
      (result) => {
        const lzw = require("node-lzw");
        result.body = JSON.parse(lzw.decode(result.body));
        return result.body;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        return undefined;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }


}
