import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { reduce } from 'rxjs/operators';

@Component({
  selector: 'app-report-user-results-view',
  templateUrl: './report-user-results-view.component.html',
  styleUrls: ['./report-user-results-view.component.scss']
})
export class ReportUserResultsViewComponent implements OnInit {
  @Input() exam;
  @Input() streamCode;
  @Input() userCode;
  loading;

  constructor(
    private dialogRef: NbDialogRef<ReportUserResultsViewComponent>,
  ) { }

  ngOnInit() {
   /*  this.getExam() */
    this.transformHtml_Response()
  }

  close() {
    this.dialogRef.close();
  }

  sortBy(arr, prop: string) {
    return arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  getExam(){
    for (var i = 0; i < this.exam.userAssignExam.length; i++){

      if (this.exam.userAssignExam[i].examQuestionType === 'oneAnswer' || this.exam.userAssignExam[i].examQuestionType === 'oneAnswerB'){
        let respuestas =this.exam.userAssignExam[i].examQuestionAnswer

       for (var j = 0; j < respuestas.length; j++) {
          if(respuestas[j].examQuestionAnswerResponseIsCorrect == true){
            var correctAnwserResponse = respuestas[j].examQuestionAnswerResponse
          }
       }
       this.exam.userAssignExam[i].examQuestionAnswerOne = correctAnwserResponse;

      }

    }

  }

  transformHtml_Response(){
    for (var i = 0; i < this.exam.userResponses.length; i++){
      if (this.exam.userResponses[i].examQuestionType === 'themeDevelopAnswer' || this.exam.userResponses[i].examQuestionType === 'themeCasesAnswer' ){
         let respuesta = this.exam.userResponses[i].examQuestionAnswerOne
          var new_resp = respuesta.replace(/(<([^>]+)>)/ig,'');
          this.exam.userResponses[i].examQuestionAnswerOne = new_resp;
      }
    }
  }



  getBackgroundColor(question) {
    if (question.examQuestionType !== 'themeDevelopAnswer') {
      if (question.isCorrect === true) {
        return '#0A7E12';
      } else {
        return '#C81016';
      }
    }
    return '#fff'
  }

}
