import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { User } from 'src/app/models/user';
import { NbToastrService, NbDialogService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { CompanyImageComponent } from './company-image/company-image.component';
import { CompanyGroupsComponent } from './company-groups/company-groups.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-company-fields',
  templateUrl: './company-fields.component.html',
  styleUrls: ['./company-fields.component.scss']
})
export class CompanyFieldsComponent implements OnInit {

  loading: boolean;
  company = {} as any;
  storage = 0;
  companyScormSelected;

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    this.getCompany(user.company.companyCode);
  }

  getCompany(companyCode) {
    this.loading = true;
    this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        this.company = result.body;
        this.storage = (this.company.companyMaxFileStorage / 1000000000);
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  addCompanyImage() {
    this.dialogService.open(CompanyImageComponent,
      {
        context: {
          company: this.company
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (company) => {
          this.company = company;
        });
  }

  addCompanyGroups() {
    this.dialogService.open(CompanyGroupsComponent,
      {
        context: {
          company: this.company
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (company) => {
          this.getCompany(this.company.companyCode);
          this.company = company;
        });
  }

  deleteCompanyGroups(event) {
    const index = event.index;
    this.company.companyGroups.splice(index, 1);
    this.authService.getUsers().then(
      (result: any) => {
        let canDelete;
        const userList = result.Users;
        userList.forEach(user => {
          const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');
          if (companyCode.Value === this.company.companyCode) {
            const group = user.Attributes.find((x) => x.Name === 'custom:group') ?
              user.Attributes.find((x) => x.Name === 'custom:group') : '';
            if (group.Value === event.data.group) {
              canDelete = true;
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                'Departamento asignado a Usuario',
                { position, status });
              this.loading = false;
              return false;
            }
          }
        });
        if (canDelete === undefined) {
          this.updateCompany();
        }

        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );

  }

  updateCompany() {
    this.loading = true;
    this.companyService.updateCompanyBackend(this.company)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.getCompany(this.company.companyCode);
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }

}
