import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterContentInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import userTableSettings from '../../../../assets/config/userTable/userTableSettings.json';
import userFilterSettings from '../../../../assets/config/filterTable/userMaintenanceTableFilters.json';


@Component({
  selector: 'app-user-maintenance-table',
  templateUrl: './user-maintenance-table.component.html',
  styleUrls: ['./user-maintenance-table.component.scss']
})
export class UserMaintenanceTableComponent implements OnInit, OnChanges {
  @Input() userList: any[];
  @Output() userEdit = new EventEmitter<any>();
  @Output() userDelete = new EventEmitter<any>();
  rolesList = new Array();
  uList = new LocalDataSource();

  userFSettings = userFilterSettings;

  settings = userTableSettings;
  loading;
  nameTabs = new Array();
  activeSource = 0;

  constructor() { }

  onTabChange(event) {
    this.activeSource = this.nameTabs.indexOf(event.tabTitle)
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.rolesList.length = 0;
    let active = true;
    if (this.userList !== undefined) {
      this.userList = this.userList.sort(this.getSortOrder("userStatusShow"));
      this.userList.forEach(user => {
        const findRole = this.rolesList.find(x => x.name === user.userRoleShow);
        if (findRole === undefined) {
          this.rolesList.push({ name: user.userRoleShow, value: new Array(), localDS: new LocalDataSource(), active });
        }
        const currentRoleList = this.rolesList.find(x => x.name === user.userRoleShow);
        const currentRoleListIndex = this.rolesList.indexOf(currentRoleList);
        this.rolesList[currentRoleListIndex].value.push(user);
        active = false;
      });
    }
    this.rolesList.forEach((list) => {
      this.nameTabs.push(list.name);
      list.localDS = new LocalDataSource(list.value);
    });
  }

  getSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  editUser(event) {
    this.userEdit.emit(event);
  }

  deleteUser(event) {
    this.userDelete.emit(event);
  }

  cleanData() {
    this.rolesList[this.activeSource].localDS.setFilter([]);
  }

  filterData(event) {
    let filters = []
    event.forEach(element => {
      filters.push({
        field: element.fieldname,
        search: element.value
      });
    });
    this.rolesList[this.activeSource].localDS.setFilter(filters)

  }

}
